import { Directive } from '@angular/core';
import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator } from '@angular/forms';
import { emailRegex } from "@classes/email";

@Directive({
	"selector": "[email]",
	"providers": [{"provide": NG_VALIDATORS, "useExisting": EmailValidatorDirective, "multi": true}]
})
export class EmailValidatorDirective implements Validator {

	private isValidEmail(value: string): boolean {
		return emailRegex.test(value);
	}

	validate(control: AbstractControl): {[key: string]: any} | null {
		const valid = this.isValidEmail(control.value);
		return valid ? null : {"invalidEmail": true};
	}
}
