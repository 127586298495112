import { hasValue } from "@utils";

export interface Address {
	address1: string|undefined;
	address2: string|undefined;
	suburb: string|undefined;
	state: string|undefined;
	postcode: string|undefined;
}

export namespace Address {

	const fields: (keyof Address)[] = ["address1", "address2", "suburb", "state", "postcode"];

	export type AUState = 'ACT' | 'QLD' | 'NSW' | 'NT' | 'SA' | 'TAS' | 'VIC' | 'WA';

	export const states: AUState[] = ['ACT', 'QLD', 'NSW', 'NT', 'SA', 'TAS', 'VIC', 'WA'];

	export enum AddressType {
		residential = 'residential',
		postal = 'postal'
	};

	export function blank(): Address {
		return {
			"address1": undefined,
			"address2": undefined,
			"suburb": undefined,
			"state": undefined,
			"postcode": undefined
		};
	}

	export function isEmpty(address: Address): boolean {
		return fields.map(field => address[field] === undefined || address[field]!.trim() === '').every( result => !!result);
	}

	export function parse(src: any): Address|undefined {
		if (!src) {
			return undefined;
		}

		return {
			"address1": src.address1,
			"address2": src.address2,
			"suburb": src.suburb,
			"state": src.state,
			"postcode": src.postcode
		};
	}

	export function clone(src: Address|undefined): Address|undefined {
		if (!src) {
			return undefined;
		}

		return <Address>{...src};
	}

	export function equals(a: Address, b: Address): boolean {

		const aIsEmpty = isEmpty(a);
		const bIsEmpty = isEmpty(b);

		if (aIsEmpty && bIsEmpty) {
			return true;
		}

		if (aIsEmpty !== bIsEmpty) {
			return false;
		}

		return fields.map( field => a[field] === b[field]).every( result => result === true );
	}

	export function toJSON(src: Address|undefined): any {
		if (!hasValue(src)) {
			return undefined;
		}

		return isEmpty(src) ? {} : {...src};
	}
}
