import { v4 as uuidv4 } from "uuid";

export class Entity {
	private readonly _id: string|null = null;
	private readonly _placeholderId: string;

	protected constructor(src: Entity|any, newId: boolean = false) {
		this._id = newId ? null : src.id ?? null;
		this._placeholderId = this._id ?? uuidv4();
	}

	public get isNew(): boolean {
		return this._id === null;
	}

	public static parse(src: Entity|any): Entity {
		return new Entity(src);
	}

	public get json(): {[key: string]: any} {
		return this._id !== null ? {"id": this._id} : {"placeholderId": this._placeholderId};
	}

	public clone(newId: boolean = false): Entity {
		return new Entity(this, newId);
	}

	public get id(): string {
		return this._id ?? this._placeholderId;
	}

	public static trackById(_index: number, item: Entity): string|null {
		return item.isNew ? null : item.id ?? null;
	}

	public static compareById(item1: Entity, item2: Entity): boolean {
		return (item1?.isNew ? null : item1?.id ?? null) === (item2?.isNew ? null : item2?.id ?? null);
	}
}

export class NamedEntity extends Entity {
	public name: string;

	protected constructor(src: NamedEntity|any, newId: boolean = false) {
		super(src, newId);
		this.name = src.name;
	}

	public static override parse(src: NamedEntity|any): NamedEntity {
		return new NamedEntity(src);
	}

	public override get json(): {[key: string]: any} {
		return Object.assign(super.json, {"name": this.name});
	}

	public override clone(newId: boolean = false): NamedEntity {
		return new NamedEntity(this, newId);
	}

	public static empty(): NamedEntity {
		return new NamedEntity({"name": ""});
	}
}
