<div *ngIf="numPages > 1">

	<nav class="pagination is-small" role="navigation" aria-label="pagination">
		<a [class.disabled]="disabled || currentPage === 1" class="pagination-previous" (click)="previous()">Previous</a>
		<a [class.disabled]="disabled || currentPage === numPages" class="pagination-next" (click)="next()">Next page</a>

		<ul class="pagination-list">
			<li><a class="pagination-link" [class.is-current]="currentPage == 1" [class.disabled]="disabled || currentPage === 1" (click)="first()">1</a></li>
			<li *ngIf="currentPage - paginationWidth > 2"><span class="pagination-ellipsis">&hellip;</span></li>
			<li *ngFor="let i of visiblePages"><a class="pagination-link" [class.is-current]="currentPage == i" [class.disabled]="disabled || currentPage === i" (click)="setPage(i)">{{ i }}</a></li>
			<li *ngIf="currentPage + paginationWidth < numPages"><span class="pagination-ellipsis">&hellip;</span></li>
			<li><a class="pagination-link" [class.is-current]="currentPage == numPages" [class.disabled]="disabled || currentPage === numPages" (click)="last()">{{ numPages }}</a></li>
			<li class="total" *ngIf="showItemCount"><ng-container *ngTemplateOutlet="totalItemsTemplate"></ng-container></li>
		</ul>
	</nav>

</div>

<div *ngIf="numPages === 1">
	<div class="summary" *ngIf="numPages === 1">Page 1 of 1<ng-container *ngIf="showItemCount"> (<ng-container *ngTemplateOutlet="totalItemsTemplate"></ng-container>)</ng-container></div>
</div>

<ng-template #totalItemsTemplate>{{ totalItems | number }} item<ng-container *ngIf="totalItems !== 1">s</ng-container></ng-template>
