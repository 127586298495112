import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { APIClass } from '@aws-amplify/api';
import { API } from '@classes/api';
export { API } from '@classes/api';
import { config } from '@root/config';
import { OfflineAuthService } from '@services/offlineAuth.service';

@Injectable({ "providedIn": "root" })
export class RestService {

	private api: APIClass;

	constructor(
		private amplifyService: AmplifyService,
		private offlineAuthService: OfflineAuthService) {
		this.api = amplifyService.api()
	}

	/**
	* Adds an 'x-identityId' value to the HTTP request headers if we're running in a serverless-offline
	* environment. This acts as a shim for AWS_IAM authentication which is not supported by serverless-offline.
	*
	* @param {any} requestOptions
	* @return {any} The original request options object, with an additional header object appended if required.
	*/
	private addOfflineAuthHeader(requestOptions: any): any {
		if (this.offlineAuthService.isOffline) {
			requestOptions = requestOptions || {}
			requestOptions.headers = requestOptions.headers || {};
			requestOptions.headers['x-identityId'] = this.offlineAuthService.getIdentity()
		}

		return requestOptions;
	}

	/**
	* Adds an 'x-identityId' value to the HTTP request headers if we're running in a serverless-offline
	* environment. This acts as a shim for AWS_IAM authentication which is not supported by serverless-offline.
	*
	* Adds an 'x-pm' header to indentify the plan manager that an admin is assuming the identity of.
	*
	* @param {any} requestOptions
	* @return {any} The original request options object, with an additional header object appended if required.
	*/
	private bundleHeaders(requestOptions: any): any {
		requestOptions = requestOptions || {}
		requestOptions.headers = requestOptions.headers || {};

		if (this.offlineAuthService.isOffline) {
			requestOptions.headers = requestOptions.headers || {};
			requestOptions.headers['x-identityId'] = this.offlineAuthService.getIdentity()
		}

		return requestOptions;
	}

	/**
	* Wrapper for the amplifyService.api().get method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#get)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	get(api: API, path: string, options?: any): Promise<any> {
		return this.api.get(API.toString(api), path, this.bundleHeaders(options));
	}

	/**
	* Wrapper for the amplifyService.api().post method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} body The data to be POSTed with the request
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	post(api: API, path: string, body: any = {}, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return this.api.post(API.toString(api), path, this.bundleHeaders(options));
	}


	/**
	* Wrapper for the amplifyService.api().put method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} body The data to be sent with the request
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	put(api: API, path: string, body: any, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return this.api.put(API.toString(api), path, this.bundleHeaders(options));
	}

	/**
	* Wrapper for the amplifyService.api().post method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	delete(api: API, path: string, options?: any): Promise<any> {
		options = options || {};
		return this.api.del(API.toString(api), path, this.bundleHeaders(options));
	}

	/**
	* Wrapper for the amplifyService.api().patch method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} body The data to be sent with the request
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	patch(api: API, path: string, body: any = {}, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return this.api.patch(API.toString(api), path, this.bundleHeaders(options));
	}

	/**
	* Wrapper for the amplifyService.api().head method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	head(api: API, path: string, options?: any): Promise<any> {
		options = options || {};
		return this.api.head(API.toString(api), path, this.bundleHeaders(options));
	}
}
