//import { Address } from "@classes/address";
import { DateUtils } from "@utils";
import { EnumMetadata } from "@classes/enumUtils";
import { NamedEntity } from "@classes/entities";

export interface ContractStatusCount {
	status: string;
	count: number;
}

export interface ContractSummary {
	summary: ContractStatusCount[];
	count: Number;
}

export enum ContractStatus {
	pending, active, disabled, expired
}

export namespace ContractStatus {
	const enumData: EnumMetadata<ContractStatus>[] = [
		[ContractStatus.pending, "Pending", "pending"],
		[ContractStatus.active, "Active", "active"],
		[ContractStatus.disabled, "Disabled", "disabled"],
		[ContractStatus.expired, "Expired", "expired"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): ContractStatus|undefined {
		return values.get(value);
	}

	export function toString(value: ContractStatus|undefined): string|undefined {
		return value !== undefined ? descriptions.get(value) : undefined;
	}

	export function toPostgresEnum(value: ContractStatus|undefined): string|undefined {
		return value !== undefined ? pgEnums.get(value) : undefined;
	}

	export function allValues(): ContractStatus[] {
		return enumData.map( item => item[0] );
	}
}

export enum ContractBillingCycle {
	weekly, monthly, quarterly, annually, one_off
}

export namespace ContractBillingCycle {
	const enumData: EnumMetadata<ContractBillingCycle>[] = [
		[ContractBillingCycle.weekly, "Weekly", "weekly"],
		[ContractBillingCycle.monthly, "Monthly", "monthly"],
		[ContractBillingCycle.quarterly, "Quarterly", "quarterly"],
		[ContractBillingCycle.annually, "Annually", "annually"],
		[ContractBillingCycle.one_off, "One Off", "one_off"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): ContractBillingCycle|undefined {
		return values.get(value);
	}

	export function toString(value: ContractBillingCycle|undefined): string|undefined {
		return value !== undefined ? descriptions.get(value) : undefined;
	}

	export function toPostgresEnum(value: ContractBillingCycle|undefined): string|undefined {
		return value !== undefined ? pgEnums.get(value) : undefined;
	}

	export function allValues(): ContractBillingCycle[] {
		return enumData.map( item => item[0] );
	}
}

export enum ContractPaymentType {
	arrears, advance, license
}

export namespace ContractPaymentType {
	const enumData: EnumMetadata<ContractPaymentType>[] = [
		[ContractPaymentType.arrears, "Arrears", "arrears"],
		[ContractPaymentType.advance, "Advance", "advance"],
		[ContractPaymentType.license, "License", "license"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): ContractPaymentType|undefined {
		return values.get(value);
	}

	export function toString(value: ContractPaymentType|undefined): string|undefined {
		return value !== undefined ? descriptions.get(value) : undefined;
	}

	export function toPostgresEnum(value: ContractPaymentType|undefined): string|undefined {
		return value !== undefined ? pgEnums.get(value) : undefined;
	}

	export function allValues(): ContractPaymentType[] {
		return enumData.map( item => item[0] );
	}
}

export class Contract extends NamedEntity {
	public startDate: Date|undefined;
	public endDate: Date|undefined;
	public nextBillingDate: Date|undefined;
	public customer: NamedEntity|undefined;
	public surgery: NamedEntity|undefined;
	public billingCycle: ContractBillingCycle|undefined;
	public maxUsers: number|undefined;
	public clientReference: string|undefined;
	public paymentType: ContractPaymentType|undefined;
	public notes: string|undefined;
	public trialStartDate: Date|undefined;
	public trialEndDate: Date|undefined;
	public gracePeriodEndDate: Date|undefined;
	public status: ContractStatus|undefined;

	protected constructor(src?: Contract|any) {
		super(src ?? {});
		if (src instanceof Contract) {
			this.startDate = src.startDate;
			this.endDate = src.endDate;
			this.nextBillingDate = src.nextBillingDate;
			this.customer = src.customer;
			this.surgery = src.surgery;
			this.billingCycle = src.billingCycle;
			this.paymentType = src.paymentType;
			this.status = src.status;
		}
		else {
			this.startDate = DateUtils.parse(src.startDate);
			this.endDate = DateUtils.parse(src.endDate);
			this.nextBillingDate = DateUtils.parse(src.nextBillingDate);
			this.customer = src.customer ? NamedEntity.parse(src.customer) : NamedEntity.empty();
			this.surgery = src.surgery ? NamedEntity.parse(src.surgery) : NamedEntity.empty();
			this.billingCycle = ContractBillingCycle.parse(src.billingCycle);
			this.paymentType = ContractPaymentType.parse(src.paymentType);
			this.trialStartDate = DateUtils.parse(src.trialStartDate);
			this.trialEndDate = DateUtils.parse(src.trialEndDate);
			this.gracePeriodEndDate = DateUtils.parse(src.gracePeriodEndDate);
			this.status = ContractStatus.parse(src?.status) ?? ContractStatus.pending;
		}
		this.notes = src.notes;
		this.maxUsers = src.maxUsers;
		this.clientReference = src.clientReference;

	}

	public static override parse(src: any): Contract {
		return new Contract(src);
	}

	public override get json(): {[key: string]: any} {
		return Object.assign(super.json, {
			"startDate": DateUtils.toString(this.startDate),
			"endDate": DateUtils.toString(this.endDate),
			"nextBillingDate": DateUtils.toString(this.nextBillingDate),
			"customer": this.customer?.json,
			"surgery": this.surgery?.json,
			"billingCycle": ContractBillingCycle.toPostgresEnum(this.billingCycle),
			"paymentType": ContractPaymentType.toPostgresEnum(this.paymentType),
			"notes": this.notes,
			"trialStartDate": DateUtils.toString(this.trialStartDate),
			"trialEndDate": DateUtils.toString(this.trialEndDate),
			"gracePeriodEndDate": DateUtils.toString(this.gracePeriodEndDate),
			"maxUsers": this.maxUsers,
			"clientReference": this.clientReference,
			"status": ContractStatus.toPostgresEnum(this.status)
		})
	}

	public override clone(): Contract {
		return new Contract(this);
	}

	public static override empty(): Contract {
		return new Contract({"name": ""});
	}
}
