import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '@services/auth.service';
import { User } from './user';
import { ServiceLocator } from '@classes/serviceLocator';
import { assert, EPermissionsNotLoaded } from "@classes/errors";
import { Subject, Observable, zip } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import { Permissions, Permission } from "@classes/permissions";
import { BusyStateService } from "@services/busyState.service";
import { ErrorUtils } from "@classes/errors";

@Component({
  "template": ""
})
export abstract class PrivateComponent implements OnDestroy, OnInit {

	private static instanceId: number = 0;
	public readonly instanceId: number = ++PrivateComponent.instanceId;

	private _busy: boolean = false;

	private setBusyState(value: boolean) {
		this._busy = value;
	}

	protected readonly authService: AuthService;
	protected readonly router: Router;
	protected readonly location: Location;
	protected readonly unsubscribe: Subject<void> = new Subject<void>();
	protected readonly busyStateService: BusyStateService;

	private readonly userLoadedSignal: Subject<void> = new Subject<void>();
	private readonly onInitSignal: Subject<void> = new Subject<void>();

	protected get isLoggedIn(): boolean {
		return this.authService.isLoggedIn;
	}

	private userLoaded(loaded: boolean|undefined) {
		assert(loaded !== undefined); // ⟵ Should never be undefined, as this is filtered out when we subscribe to the observer.
                                  //   Required here to keep the compiled happy

		if (loaded) {
			this.userLoadedSignal.next();
			this.userLoadedSignal.complete();
			// setTimeout( () => this.ready.call(this), 0); // ⟵ Signal to child classes that we're ready to go, and can load additional data as required.
		}
		else {
			this.router.navigate(["/login"]); // ⟵ User is not logged in, so navigate to the login page
		}
	}

	ngOnInit() {
		this.onInitSignal.next();
		this.onInitSignal.complete();
	}

	protected constructor() {
		this.authService = ServiceLocator.injector.get(AuthService);
		this.router = ServiceLocator.injector.get(Router);
		this.location = ServiceLocator.injector.get(Location);
		this.busyStateService = ServiceLocator.injector.get(BusyStateService);

		zip(this.userLoadedSignal, this.onInitSignal).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			setTimeout( () => this.ready.call(this), 0); // ⟵ Signal to child classes that we're ready to go, and can load additional data as required.
		});

		setTimeout( () => {
			this.authService.userLoaded$.pipe( filter(x => x !== undefined), first() ).subscribe( this.userLoaded.bind(this) );
			this.busyStateService.busySignal.pipe( takeUntil(this.unsubscribe) ).subscribe( this.setBusyState.bind(this) );
		});
	}

	public get currentUser(): User|undefined {
		return this.authService.currentUser;
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	/**
	* Returns a boolean indicating whether the currently logged in user has the requested permission.
	* If the Permissions class hasn't been initialised yet, exceptions are silently swallowed and the method
	* returns false as if the user does not have the permission.
	*/
	public hasPermission(groupName: string, permissionName: string): boolean {

		try {
			assert(this.currentUser);
			const permissionId = Permissions.getPermissionId( Permission(groupName, permissionName) );
			return this.currentUser.hasPermission(permissionId) ?? false;
		}
		catch (e) {

			if (e instanceof EPermissionsNotLoaded) {
				console.warn(e);
				return false;
			}

			throw e;
		}
	}

	/**
	* Called once when the logged in user has been loaded (either from remote server or local cache)
	* Descendant classes should override this method to implement loading of other data, ensuring that the
	* correct credentials and data are available
	*/
	protected abstract ready(): void

	public get busy(): boolean {
		return this._busy;
	}

	protected autoUnsubscribe<T>(obs: Observable<T>): Observable<T> {
		return obs.pipe(takeUntil(this.unsubscribe));
	}

	protected logError(e: Error) {
		ErrorUtils.logError(e, this.currentUser);
	}
}
