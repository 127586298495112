import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TableColumnHeading, Table } from "@classes/tables";
import { SortType } from "@classes/datasort";
import { assert } from "@classes/errors";
import { hasValue } from "@utils";
import { IconProp, IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
	"selector": "thead[table]",
	"styleUrls": ["./tableHeader.component.scss"],
	"templateUrl": "./tableHeader.component.html"
})
export class TableHeaderComponent {

	private _table!: Table<any>;

	@Input('table')
	public set table(value: Table<any>) {
		this._table = value;
	}

	public get table(): Table<any> {
		return this._table;
	}

	@Output()
	sortChanged: EventEmitter<number> = new EventEmitter<number>();

	changeSort(column: TableColumnHeading): void {
		if (!hasValue(this._table)) {
			return;
		}

		if (column.sortType !== SortType.none && column.idx !== undefined) {

			if (Math.abs(this._table.sort) === column.idx) {
				this._table.sort = this._table.sort > 0 ? -1 * this._table.sort : 0;
			}
			else {
				this._table.sort = column.idx;
			}

			// Allow columns that are externally sorted to be processed
			if (column.sortType === undefined || column.sortType === SortType.custom) {
				this.sortChanged.emit(this._table.sort);
			}
			else {
				this._table.refresh(true);
			}
		}
	}

	isSortedColumn(column: TableColumnHeading): boolean {
		return this._table?.columnIsSorted(column) || false;
	}

	sortIcons(column: TableColumnHeading): IconProp {
		assert(hasValue(this._table));

		let iconName: IconName = 'sort';

		if (this.isSortedColumn(column)) {
			iconName = this._table.sort < 0 ? 'sort-down' : 'sort-up';
		}

		return ['fas', iconName];
	}

	public isIcon(column: TableColumnHeading): boolean {
		return column.displayName.startsWith("icon:");
	}

	public icon(column: TableColumnHeading): IconProp {
		const iconName: string = column.displayName.split(':').pop()!;
		return ['fas', iconName as IconName];
	}

	public canSort(column: TableColumnHeading): boolean {
		return !this.isTemplateColumn(column) && column.sortType !== SortType.none;
	}

	public isTemplateColumn(column: TableColumnHeading): boolean {
		return hasValue(column.template);
	}

	public readonly sortTypeNone: SortType = SortType.none;
}
