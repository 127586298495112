import { Injectable } from '@angular/core';
import { RestService, API } from '@services/rest.service';
import { Subscription, timer, Subject, BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { WebsocketState, WebsocketStatus } from "@classes/websocketHelpers";
import { SimpleWaitQueue } from "@classes/waitQueue";
import { AuthService } from '@services/auth.service';
import { websocketEndpoint } from "@root/aws-settings";

interface PromiseHandlers {
	resolve: (value: any) => void,
	reject: (reason?: any) => any
};

export interface BroadcastMessage {
	channel: string;
	data: any;
}

@Injectable({"providedIn": "root"})
export class WebSocketService {

	private _channelSubscriptions: Set<string> = new Set<string>();

	private static readonly heartbeatConnectAfter: number = 15000; // Delay until first ping is sent, 15 seconds
	private static readonly heartbeatInterval: number = 55000; // Ping every 55 seconds
	private static readonly websocketApi = API.websocket;

	private static callbackMap: Map<string, PromiseHandlers> = new Map<string, PromiseHandlers>();

	private heartbeat = timer(WebSocketService.heartbeatConnectAfter, WebSocketService.heartbeatInterval);
	private heartbeatSubscription: Subscription|undefined;

	private _waitQueue = new SimpleWaitQueue<void>();

	public readonly connected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private restService: RestService, private authService: AuthService) {
		this.authService.userLoaded$.subscribe( this.userLoaded.bind(this) )
	}

	private userLoaded(isLoggedIn: boolean|undefined) {
		if (!!isLoggedIn) {
			this.heartbeatSubscription = this.heartbeat.subscribe( () => { this.send("ping"); });
			this.initSocket();
		}
		else {
			this.heartbeatSubscription?.unsubscribe();
			if (this._websocket && [WebsocketStatus.connecting, WebsocketStatus.open].includes(this._websocket.readyState)) {
				this._websocket.close();
			}
		}
	}

	private _websocket: WebSocket|undefined;

	private readonly _broadcastMessages = new Subject<BroadcastMessage>();
	public readonly broadcastMessages$ = this._broadcastMessages.asObservable();

	private socketInitFunc(): Promise<void> {
		return new Promise<void>( async (resolve, reject) => {

			try {

				const response = await this.restService.get(WebSocketService.websocketApi, 'ticket');
				const ticket = response.ticket;

				// this._websocket = new WebSocket("ws://marauders.dev:30194", ticket);
				this._websocket = new WebSocket(websocketEndpoint, ticket);
				this._websocket.addEventListener("close", e => this.socketClosed(e) );
				this._websocket.addEventListener("error", e => this.socketClosed(e) );
				this._websocket.addEventListener("message", e => this.messageReceived(e) );
				this._websocket.addEventListener("open", async () => {

					// Resolve after a slight delay to give the websocket service time to sort it's life out.
					// Not sure if issue is caused by bugs in serverless-offline implementation of websocket handler, or
					// my own mistakes, but the $connect function appears to be:
					// a) Ignoring any attempt to execute a custom authoriser
					// b) Allowing the websocket's "onOpen" event to fire before the $connect handler has completed
					setTimeout( async () => {
						WebsocketState.isOpen = true;
						this.connected$.next(true);
						resolve();
					}, 300 );

				} );
			}
			catch (e) {
				reject(e);
			}
		});
	}

	private initSocket(): Promise<void> {
		if (this.socketOpen) {
			return Promise.resolve();
		}

		return this._waitQueue.enqueue( this.socketInitFunc.bind(this) );
	}

	private get socketOpen(): boolean {
		return this._websocket?.readyState === WebsocketStatus.open;
	}

	private socketClosed(_event: CloseEvent|Event) {
		this.connected$.next(false);
		WebsocketState.isOpen = false;
		this._websocket = undefined;
	}

	private responseBatches: Map<string, any[]> = new Map<string, any[]>();

	private emptyArray(size: number): any[] {
		const result = Array(size);
		result.fill(undefined);
		return result;
	}

	private messageReceived(message: any) {
		const data = JSON.parse(message.data);

		if (data.requestId) {
			const promiseHandlers = WebSocketService.callbackMap.get(data.requestId);

			if (data.error) {
				WebSocketService.callbackMap.delete(data.requestId);
				promiseHandlers?.reject(data.error);
				return;
			}

			const batchSize = data.batch?.size ?? 1;

			if (batchSize === 1) {

				// Response has not been split into batches.
				// Remove the request ID from the callback queue, and send the response data
				WebSocketService.callbackMap.delete(data.requestId);
				promiseHandlers?.resolve(data.response);
			}
			else {

				// Message has been split into multiple chunks
				// (to get around the frame size limit of 32kb on AWS websockets)
				// NB: This method only works for array-based responses, where the array has been split into smaller units
				const messageSegments = this.responseBatches.get(data.requestId) ?? this.emptyArray(batchSize);
				if (!this.responseBatches.has(data.requestId)) {
					this.responseBatches.set(data.requestId, messageSegments);
				}

				messageSegments.splice(data.batch.idx, 1, data.response);

				if (messageSegments.every(item => item !== undefined)) {
					// If all chunks have been received, remove the request ID from the callback queue,
					// delete the map entry for the chunks, and send the response data.
					WebSocketService.callbackMap.delete(data.requestId);
					this.responseBatches.delete(data.requestId);
					promiseHandlers?.resolve(messageSegments.flat());
				}
			}
		}
		else {
			this._broadcastMessages.next({
				"channel": data.response.channel ?? "broadcast",
				"data": data.response.data
			});
		}
	}

	/**
	* Sends a message to the server over the websocket.
	* Does not expect to wait for a response.
	*/
	public async notify(method: string, payload?: any) {
		try {
			await this.initSocket();

			const data = {
				"method": method,
				"payload": payload
			};

			this._websocket?.send(JSON.stringify(data));
		}
		catch (e) {
			console.log(e);
		}
	}

	/**
	* Sends a message to the server over the websocket, expecting a response
	*/
	public send(method: string, payload?: any): Promise<any> {
		return new Promise( async (resolve, reject) => {

			try {
				await this.initSocket();

				// Generate a unique request identifier
				const requestId = uuidv4();

				WebSocketService.callbackMap.set(requestId, {"resolve": resolve, "reject": reject});
				const data = {
					"method": method,
					"payload": payload,
					"requestId": requestId
				};

				this._websocket?.send(JSON.stringify(data));
			}
			catch (e) {
				reject(e);
			}
		});
	}

	public close() {
		this._websocket?.close();
	}

	public async subscribe(channel: string): Promise<void> {
		if (this._channelSubscriptions.has(channel)) {
			return;
		}

		this._channelSubscriptions.add(channel);

		try {
			await this.initSocket();
			const requestId = uuidv4();

			const data = {
				"method": "subscribe",
				"requestId": requestId,
				"payload": {
					"channel": channel
				}
			};

			this._websocket?.send(JSON.stringify(data));
		}
		catch (e) {
			console.log(e);
		}
	}

	public async unsubscribe(channel: string): Promise<void> {
		if (!this._channelSubscriptions.has(channel)) {
			return;
		}

		this._channelSubscriptions.delete(channel);

		try {
			const requestId = uuidv4();

			await this.initSocket();

			const data = {
				"method": "unsubscribe",
				"requestId": requestId,
				"payload": {
					"channel": channel
				}
			};

			this._websocket?.send(JSON.stringify(data));
		}
		catch (e) {
			console.log(e);
		}
	}
}
