import { API } from '@classes/api';

export const urlBase = "https://api.dev.audit.unidap.biz";
const region = "ap-southeast-2";
export const websocketEndpoint = "wss://vjyjyq2sl6.execute-api.ap-southeast-2.amazonaws.com/dev";

const awsSettings = {
	"Auth": {
		"region": "ap-southeast-2",
		"identityPoolId": "ap-southeast-2:ec74788e-ba9d-4021-bb74-41eec7d76855",
		"userPoolId": "ap-southeast-2_o4whSY3qH",
		"userPoolWebClientId": "49pojbg0ri5ivguiohhdrmj9lq"
	},
	"API": {
		"endpoints": [{
			"name": API.toString(API.websocket),
			"endpoint": `${urlBase}/ws/`,
			"region": region
		}, {
			"name": API.toString(API.system),
			"endpoint": `${urlBase}/system/`,
			"region": region
		}, {
			"name": API.toString(API.files),
			"endpoint": `${urlBase}/files/`,
			"region": region
		}, {
			"name": API.toString(API.customers),
			"endpoint": `${urlBase}/customer/`,
			"region": region
		}, {
			"name": API.toString(API.visicode),
			"endpoint": `${urlBase}/visicode/`,
			"region": region
		}, {
			"name": API.toString(API.visicodeAdmin),
			"endpoint": `${urlBase}/visicodeadmin/`,
			"region": region
		}, {
			"name": API.toString(API.icd10),
			"endpoint": `${urlBase}/icd10/`,
			"region": region
		}, {
			"name": API.toString(API.products),
			"endpoint": `${urlBase}/product/`,
			"region": region
		}, {
			"name": API.toString(API.contracts),
			"endpoint": `${urlBase}/contract/`,
			"region": region
		}, {
			"name": API.toString(API.invoices),
			"endpoint": `${urlBase}/invoice/`,
			"region": region
		}, {
			"name": API.toString(API.fydo),
			"endpoint": `${urlBase}/fydo/`,
			"region": region
		}, {
			"name": API.toString(API.resicat),
			"endpoint": `${urlBase}/resicat/`,
			"region": region
		}]
	}
};


export default awsSettings;
