import { assert } from '@classes/errors';

export enum API {
	websocket,
	system,
	files,
	customers,
	visicode,
	visicodeAdmin,
	icd10,
	products,
	contracts,
	invoices,
	fydo,
	resicat
};

export namespace API {
	const apiNames = new Map<API, string>([
		[API.websocket, "websocket"],
		[API.system, "system"],
		[API.files, "files"],
		[API.customers, "customers"],
		[API.visicode, "visicode"],
		[API.visicodeAdmin, "visicodeAdmin"],
		[API.icd10, "icd10"],
		[API.products, "products"],
		[API.contracts, "contracts"],
		[API.invoices, "invoices"],
		[API.fydo, "fydo"],
		[API.resicat, "resicat"]
	]);

	export function toString(api: API): string {
		return assert(apiNames.has(api)), apiNames.get(api)!;
	}

	export function toJSON(api: API, endpoint: string) {
		return {"name": API.toString(api), "endpoint": endpoint};
	}
}

