import { Component, Input, Output, forwardRef, ViewChild, ElementRef, AfterViewInit, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { assert, ErrorUtils } from "@classes/errors";
import { DateTime } from "luxon";
import { Subject } from "rxjs";
import { IDateValue } from "@classes/dateValue";

enum SelectionType {
	selectAll, start, end
}

export enum DateFormat {
	uk, us, iso
}

export namespace DateFormat {
	export function parse(value: string): DateFormat {
		switch (value) {
			case "us": return DateFormat.us;
			case "iso": return DateFormat.iso;
			default: return DateFormat.uk;
		}
	}
}

enum Key {
	Backspace = 8,
	Tab = 9,
	Enter = 13,
	Shift = 16,
	Escape = 27,
	ArrowLeft = 37,
	ArrowRight = 39,
	ArrowUp = 38,
	ArrowDown = 40,
	Delete = 46,
	MacCommandLeft = 91,
	MacCommandRight = 93,
	MacCommandFirefox = 224
}

namespace Key {
	export function isNumeric(keyCode: number): boolean {
		return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
	}

	export function isNonCharKeyCode(keyCode: number): boolean {
		return [
			Key.Backspace,
			Key.Enter,
			Key.Tab,
			Key.Shift,
			Key.ArrowLeft,
			Key.ArrowUp,
			Key.ArrowRight,
			Key.ArrowDown,
			Key.Delete,
			Key.MacCommandLeft,
			Key.MacCommandRight,
			Key.MacCommandFirefox
		].includes(keyCode);
	}
}


@Component({
	"selector": "date",
	"styleUrls": ["./datecontrol.component.scss"],
	"templateUrl": "./datecontrol.component.html",
	"providers": [{
		"provide": NG_VALUE_ACCESSOR,
		"useExisting": forwardRef( () => DateInputComponent ),
		"multi": true
	}]
})
export class DateInputComponent implements ControlValueAccessor, AfterViewInit, IDateValue {

	public readonly [IDateValue] = true;

	private static InstanceCount: number = 0;

	public readonly instanceCount: number = ++DateInputComponent.InstanceCount;

	private _initialised: boolean = false;

	@ViewChild('nativeInput')
	private _nativeInput: ElementRef<HTMLInputElement>|undefined;

	@ViewChild('dayInput')
	private _dayInput: ElementRef<HTMLInputElement>|undefined;

	@ViewChild('monthInput')
	private _monthInput: ElementRef<HTMLInputElement>|undefined;

	@ViewChild('yearInput')
	private _yearInput: ElementRef<HTMLInputElement>|undefined;

	private _changeFn: any;
	private _touchFn: any;
	private _format: DateFormat = DateFormat.uk;
	private _native: boolean = true;

	/**
	* Model property for native date element
	*/
	private _modelValue: string|undefined;

	/**
	* Model property for custom date component
	*/
	private _dayString: string|undefined;
	private _monthString: string|undefined;
	private _yearString: string|undefined;

	private _prevField: Map<HTMLInputElement, HTMLInputElement> = new Map<HTMLInputElement, HTMLInputElement>();
	private _nextField: Map<HTMLInputElement, HTMLInputElement> = new Map<HTMLInputElement, HTMLInputElement>();

	private isoStringToDateTime(value: string|undefined): DateTime|undefined {
		if (value === undefined) {
			return undefined;
		}

		const zeroPadRegex = /^0+/;
		const [year, month, day] = (value || '').split('-').map( item => item.replace(zeroPadRegex, '') );

		if ((!year || year.length !== 4) || !month || !day) {
			return undefined;
		}

		const date = DateTime.fromObject({"year": parseInt(year, 10), "month": parseInt(month, 10), "day": parseInt(day, 10)});
		return date.isValid ? date : undefined;
	}

	private initFieldMaps() {
		// Don't generate these mappings if we're using native date control, or the view hasn't been initialised yet
		if (!this._initialised || this._native) {
			return;
		}

		this._prevField.clear();
		this._nextField.clear();

		switch (this._format) {
			case DateFormat.uk: {
				this._prevField.set(this._monthInput!.nativeElement, this._dayInput!.nativeElement);
				this._prevField.set(this._yearInput!.nativeElement, this._monthInput!.nativeElement);
				this._nextField.set(this._dayInput!.nativeElement, this._monthInput!.nativeElement);
				this._nextField.set(this._monthInput!.nativeElement, this._yearInput!.nativeElement);
				break;
			}
			case DateFormat.us: {
				this._prevField.set(this._dayInput!.nativeElement, this._monthInput!.nativeElement);
				this._prevField.set(this._yearInput!.nativeElement, this._dayInput!.nativeElement);
				this._nextField.set(this._monthInput!.nativeElement, this._dayInput!.nativeElement);
				this._nextField.set(this._dayInput!.nativeElement, this._yearInput!.nativeElement);
				break;
			}
			case DateFormat.iso: {
				this._prevField.set(this._dayInput!.nativeElement, this._monthInput!.nativeElement);
				this._prevField.set(this._monthInput!.nativeElement, this._yearInput!.nativeElement);
				this._nextField.set(this._yearInput!.nativeElement, this._monthInput!.nativeElement);
				this._nextField.set(this._monthInput!.nativeElement, this._dayInput!.nativeElement);
				break;
			}
		}
	}

	private calcDateFromComponents() {
		// Force a 4 digit year
		if ((this._yearString?.length || 0) !== 4) {
			this._changeFn(undefined);
			return;
		}

		const date = this.isoStringToDateTime(`${this._yearString}-${this._monthString}-${this._dayString}`);
		if (date === undefined || !date.isValid) {
			this._changeFn(undefined);
			return;
		}

		const maxDate = this.isoStringToDateTime(this.max);
		if (maxDate !== undefined && date > maxDate) {
			this._changeFn(undefined);
			return;
		}

		const minDate = this.isoStringToDateTime(this.min);
		if (minDate !== undefined && date < minDate) {
			this._changeFn(undefined);
			return;
		}

		this._modelValue = date.toFormat('yyyy-MM-dd');
		this._changeFn(this._modelValue);
	}

	private checkNativeMinMax(): boolean {
		const date = this.isoStringToDateTime(this._modelValue);
		if (date === undefined || !date.isValid) {
			return false;
		}

		const minDate = this.isoStringToDateTime(this.min);
		if (minDate !== undefined && date < minDate) {
			return false;
		}

		const maxDate = this.isoStringToDateTime(this.max);
		if (maxDate !== undefined && date > maxDate) {
			return false;
		}

		return true;
	}

	/**
	* Sets the individual dayString, monthString and yearString values based
	* on the input string on the native date control
	*/
	private setComponentValues(dateString: string) {
		[this._yearString, this._monthString, this._dayString] = (dateString || '').split('-');
	}

	ngAfterViewInit() {
		this._initialised = true;
		this.initFieldMaps();
	}

	public focusOut(event: FocusEvent) {
		const relatedElements: HTMLElement[] = [this._monthInput!, this._yearInput!, this._dayInput!].map( ref => ref.nativeElement );
		if (!relatedElements.includes(<HTMLElement> event.relatedTarget)) {
			this.blur.next(event);
		}
	}


	public readonly dateFormats: any = {
		"uk": DateFormat.uk,
		"us": DateFormat.us,
		"iso": DateFormat.iso
	};

	public get nativeModel(): string {
		return this._modelValue || '';
	}

	public set nativeModel(value: string) {
		if (this._modelValue !== value) {
			this._modelValue = value;
			this.setComponentValues(value);

			if (this.checkNativeMinMax()) {
				this._changeFn(this._modelValue);
			}
			else {
				this._changeFn(undefined);
			}
		}
	}

	public setTouched(event: FocusEvent) {
		if (typeof this._changeFn === 'function') {
			this._touchFn();
		}
		this.blur.next(event);
	}

	@Input()
	public set native(value: boolean) {
		if (this._native !== value) {
			this._native = value;
			this.initFieldMaps();
		}
	}

	public get native(): boolean {
		return this._native;
	}

	/**
	* Implementation of interface property IDateValue.date
	*/
	public get date(): Date|undefined {
		if (this._native && this._modelValue !== undefined && this._modelValue !== null) {
			const d = DateTime.fromString(this._modelValue, 'yyyy-MM-dd');
			return d.isValid ? d.toJSDate() : undefined;
		}

		throw new Error("Not implementated for non-native date control");
	}

	@Input()
	public readonly: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public id: string|undefined;

	@Input()
	public name: string|undefined;

	@Input()
	public classList: string|undefined;

	/**
	* Only works for native mode
	*/
	@Input()
	public max: string|undefined;

	/**
	* Only works for native mode
	*/
	@Input()
	public min: string|undefined;

	@Input('format')
	public set dateFormat(value: string) {
		this._format = DateFormat.parse(value);
		this.initFieldMaps();
	}

	@Output()
	public blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

	public get format(): DateFormat {
		return this._format;
	}

	public get dayString(): string|undefined {
		return this._dayString;
	}

	public get monthString(): string|undefined {
		return this._monthString;
	}

	public get yearString(): string|undefined {
		return this._yearString;
	}

	public set dayString(value: string|undefined) {
		if (value !== this._dayString) {
			this._dayString = value;
			this.calcDateFromComponents();
		}
	}

	public set monthString(value: string|undefined) {
		if (value !== this._monthString) {
			this._monthString = value;
			this.calcDateFromComponents();
		}
	}

	public set yearString(value: string|undefined) {
		if (value !== this._yearString) {
			this._yearString = value;
			this.calcDateFromComponents();
		}
	}

	/**
	* Handles moving to the next input element on arrow key press
	*/
	private handleFieldNavigation($event: KeyboardEvent): boolean {

		const control = <HTMLInputElement> $event.target;

		if (!control) {
			return false;
		}

		const controlValue = this.eventTargetValue(control) || "";
		const fieldLength = Math.max(0, controlValue.length);

		// Arrow left: Move to the end of the previous field if we're at the start of the current field
		if ($event.keyCode === Key.ArrowLeft && control.selectionStart === 0 && control.selectionEnd === 0) {
			this.moveToField( this.findPrevField(control), SelectionType.end );
			return true;
		}
		// Arrow right: Move to the start of the next field if we're at the end of the current field
		else if ($event.keyCode === Key.ArrowRight && control.selectionStart == fieldLength) {
			this.moveToField( this.findNextField(control), SelectionType.start );
			return true;
		}

		return false;
	}

	/**
	* Prevent non-digits from being entered into a text box.
	*/
	public onKeyDown($event: KeyboardEvent) {

		if ([Key.ArrowLeft, Key.ArrowRight].includes($event.keyCode) && this.handleFieldNavigation($event)) {
			$event.preventDefault();
			return;
		}

		// Allow keys that are not alphanumeric or symbols
		if (Key.isNonCharKeyCode($event.keyCode)) {
			return;
		}

		if (!Key.isNumeric($event.keyCode)) {
			$event.preventDefault();
		}
	}

	/**
	* Comment
	*/
	public setFocus() {
		if (this.native) {
			this._nativeInput?.nativeElement?.focus();
		}
		else {
			this.focusDay();
		}
	}

	/**
	* Set focus to the "day" input field when the control is clicked.
	* NB: Don't switch focus if the user clicks on the month or year elements.
	*/
	public focusDay() {

		// If the month or year element already has focus, don't steal focus from them.
		const domElementsToIgnore: any[] = [this._monthInput?.nativeElement, this._yearInput?.nativeElement].filter( x => !!x );
		if (domElementsToIgnore.includes(document.activeElement)) {
			return;
		}

		// Otherwise, focus the day element, and select all of the text inside.
		this._dayInput?.nativeElement?.focus();
		this._dayInput?.nativeElement?.select()
	}

	private eventTargetValue(element: any): string|undefined {
		switch (element) {
			case this._dayInput!.nativeElement: return this.dayString;
			case this._monthInput!.nativeElement: return this.monthString;
			case this._yearInput!.nativeElement: return this.yearString;
			default: return undefined;
		}
	}

	private findNextField(currentField: HTMLInputElement): HTMLInputElement {
		return this._nextField.get(currentField)!;
	}

	private findPrevField(currentField: HTMLInputElement): HTMLInputElement {
		return this._prevField.get(currentField)!;
	}

	private moveToField(element: HTMLInputElement, selectionType: SelectionType = SelectionType.selectAll) {
		if (!element) {
			return;
		}

		element.focus();

		switch (selectionType) {
			case SelectionType.selectAll: {
				element.select();
				break;
			}
			case SelectionType.start: {
				element.selectionStart = 0;
				element.selectionEnd = 0;
				break;
			}
			case SelectionType.end: {
				const value = this.eventTargetValue(element) || "";
				element.selectionStart = value.length;
				element.selectionEnd = value.length;
				break;
			}
		}
	}

	public onInput($event: Event) {

		const control = <HTMLInputElement> $event.target;

		// Move to next control if the data typed matches the max allowed length of the field
		if (control === this._dayInput!.nativeElement && this.dayString?.length === control.size) {
			this.moveToField( this.findNextField(control) );
		}
		else if (control === this._monthInput!.nativeElement && this.monthString?.length === control.size) {
			this.moveToField( this.findNextField(control) );
		}
		else if (control === this._yearInput!.nativeElement && this.yearString?.length === control.size) {
			this.moveToField( this.findNextField(control) );
		}
	}

	// <region> Control Value Accessor interface implementation -----------------
	writeValue(obj: any) {
		this._modelValue = obj;
		this.setComponentValues(obj);
	}

	registerOnChange(fn: any) {
		this._changeFn = fn;
	}

	registerOnTouched(fn: any) {
		this._touchFn = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}
	// </region>
}
