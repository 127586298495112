export enum WebsocketStatus {
	connecting = 0,
	open = 1,
	closing = 2,
	closed = 3
}

export class WebsocketState {
	public static isOpen: boolean = false;
}
