import { Component } from '@angular/core';
import { AuthService } from "@services/auth.service";
import { TitleService } from '@services/title.service';
import { User } from '@classes/user';
import { assert, EPermissionsNotLoaded } from "@classes/errors";
import { Permissions, Permission } from "@classes/permissions";

@Component({
  "templateUrl": "./home.component.html",
  "styleUrls": ["./home.component.scss"]
})
export class HomeComponent {

	constructor(private authService: AuthService) {
		TitleService.setMenuTitle(TitleService.defaultMenuSubject);
	}

	public get currentUser(): User|undefined {
		return this.authService.currentUser;
	}

	public get isLoggedIn(): boolean {
		return this.authService.isLoggedIn;
	}

	/**
	* Returns a boolean indicating whether the currently logged in user has the requested permission.
	* If the Permissions class hasn't been initialised yet, exceptions are silently swallowed and the method
	* returns false as if the user does not have the permission.
	*/
	public hasPermission(groupName: string, permissionName: string): boolean {

		try {
			assert(this.currentUser);
			const permissionId = Permissions.getPermissionId( Permission(groupName, permissionName) );
			return this.currentUser.hasPermission(permissionId) ?? false;
		}
		catch (e) {

			if (e instanceof EPermissionsNotLoaded) {
				console.warn(e);
				return false;
			}

			throw e;
		}
	}
}
