<div class="container">
	<breadcrumbs [path]="breadcrumbs"></breadcrumbs>

	<h1 class="title">My Account</h1>

	<div class="tile is-ancestor">
		<div class="tile is-parent" *ngIf="currentUser">
			<div class="tile box">
				<div>
					<h2 class="subtitle">Account Details for {{currentUser.firstName}} {{currentUser.lastName}}</h2>

					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label for="67d04089" class="label is-normal">Email</label>
						</div>
						<div class="field-body">
							<div class="field">

								<div class="control is-expanded">
									<input [disabled]="true" [readonly]="true" id="67d04089" name="67d04089" class="input is-normal" type="text" placeholder="No email" [(ngModel)]="currentUser.email"/>
								</div>

							</div>
						</div>
					</div>

					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label for="c03bcf79" class="label is-normal">Mobile</label>
						</div>
						<div class="field-body">
							<div class="field">

								<div class="control is-expanded">
									<input [disabled]="true" [readonly]="true" id="c03bcf79" name="c03bcf79" class="input is-normal" type="text" placeholder="No mobile" [(ngModel)]="currentUser.mobile"/>
								</div>

							</div>
						</div>
					</div>

					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label for="8c40b968" class="label is-normal">Phone</label>
						</div>
						<div class="field-body">
							<div class="field">

								<div class="control is-expanded">
									<input [disabled]="true" [readonly]="true" id="8c40b968" name="8c40b968" class="input is-normal" type="text" placeholder="No phone" [(ngModel)]="currentUser.phone"/>
								</div>

							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
	<div class="tile is-ancestor">
		<div class="tile is-parent">
			<div class="tile box">
				<div>
					<h2 class="subtitle">Change Password</h2>
					<p>To change your password, <a [link]="'/password/change'">click here</a>.</p>
				</div>
			</div>
		</div>
		<div class="tile is-parent">
			<div class="tile box">
				<div>
					<h2 class="subtitle">Multi Factor Authentication</h2>
					<loading *ngIf="!loaded" [large]="false"></loading>
					<ng-container *ngIf="loaded && mfaType === 'NOMFA'">
						<p><fa-icon class="has-text-danger" [icon]="['fas', 'exclamation-triangle']"></fa-icon> Multi-factor authentication is not currently enabled for your account.</p>
						<p><a routerLink="/password/totp-setup">Click here</a> to set up MFA.</p>
					</ng-container>

					<ng-container *ngIf="loaded && mfaType === 'SOFTWARE_TOKEN_MFA'">
						<p><fa-icon class="has-text-success" [icon]="['fas', 'check']"></fa-icon> Multi-factor authentication is currently enabled for your account.</p>
						<ng-container *ngIf="mfaRequired">
							<br />
							<p class="help">Multi-factor authentication is required by your organisation and cannot be disabled</p>
						</ng-container>
						<ng-container *ngIf="!mfaRequired">
							<br />
							<p><a (click)="confirmRemoveMFA()">Click here</a> to disable multi-factor authentication</p>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
