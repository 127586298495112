import { Injectable, Output, EventEmitter } from '@angular/core';
import { Component, Input } from '@angular/core';
import { PaginationMeta } from "@classes/pagination";
import { hasValue } from "@utils";
import { assert } from "@classes/errors";
// import { SessionStorageService } from "@services/storage.service";

@Component({
	"selector": "pagination",
	"styleUrls": ["pagination.component.scss"],
	"templateUrl": "./pagination.component.html"
})
export class PaginationComponent {

	private static paginationWidth = 3; // Number of items either side of the current page
	private static defaultPageSize = 25;

	private _disabled: boolean = false;
	private _data: PaginationMeta|undefined;

	private get storageKey(): string|undefined {
		// return !!this._name ? `${this._name}.page` : undefined;
		return !!this._data?.id ? `${this._data.id}.page` : undefined;
	}

	public get numPages(): number {
		return hasValue(this._data) ? Math.max(1, Math.ceil(this._data.totalItems / this._data.pageSize)) : 0;
	}

	@Output()
	pageChanged: EventEmitter<number> = new EventEmitter<number>();

	private initCurrentPage() {
		if (hasValue(this._data)) {
			if ([this._data.id, this._data.pageSize, this._data.totalItems].some( item => item === undefined )) {
				return;
			}

			this._data.page = Math.min(Math.max(1, this._data.page || 0), this.numPages);
		}
	}

	@Input()
	set data(value: PaginationMeta) {
		this._data = value;
		this.initCurrentPage();
	}

	@Input()
	set disabled(value: boolean) {
		this._disabled = value;
	}

	@Input('itemCount')
	public showItemCount: boolean = true;

	get name(): string|undefined {
		return this._data?.id;
	}

	get paginationWidth(): number {
		return PaginationComponent.paginationWidth;
	}

	get disabled(): boolean {
		return this._disabled;
	}

	get currentPage(): number {
		// return this._currentPage;
		return this._data?.page || 1;
	}

	get pageSize(): number {
		// return this._pageSize;
		return this._data?.pageSize || PaginationComponent.defaultPageSize;
	}

	get totalItems(): number {
		return this._data?.totalItems || 0;
	}

	get visiblePages(): number[] {
		const width = Math.min(this.numPages, 1 + 2 * PaginationComponent.paginationWidth);

		if (width < 3) {
			// Only have first and last page, which are hard-coded to always render, so no need to do anything
			return [];
		}

		const minStart = 2;
		const startPage = Math.max(minStart, this.currentPage - PaginationComponent.paginationWidth);

		let result = Array.from( Array(width).keys() ).map( i => i + startPage  );

		// If the array of pages exceeds the number of available pages, shuffle the items backwards
		const lastValue = result[result.length - 1];
		if (lastValue > this.numPages - 1) {
			result = result.map( i => i - (lastValue - this.numPages + 1) );
		}

		// After that backwards shuffling, if the list starts before our minimum start value (page 2), chop off the items
		// at the start of the array.
		const firstValue = result[0];
		if (minStart - firstValue > 0) {
			result.splice(0, minStart - firstValue);
		}

		return result;
	}

	first(): void {
		this.setPage(1);
	}

	previous(): void {
		assert(hasValue(this._data));
		assert(hasValue(this._data.page));
		this.setPage( this._data.page - 1 );
	}

	next(): void {
		assert(hasValue(this._data));
		assert(hasValue(this._data.page));
		this.setPage(this._data.page + 1);
	}

	last(): void {
		this.setPage(this.numPages);
	}

	setPage(value: number): void {
		assert(hasValue(this._data));

		if (this._disabled) {
			return;
		}

		const oldValue = this._data.page;
		const newValue = Math.min(Math.max(1, value), this.numPages);

		if (oldValue !== newValue) {
			this._data.page = newValue;
			// if (this.storageKey) {
			// 	SessionStorageService.set(this.storageKey, newValue);
			// }
			this.pageChanged.emit(newValue);
		}
	}

	public static storedPageNumber(name: string): number {
		return 0;
		// return SessionStorageService.get(`${name}.page`);
	}

	public static clear(name: string): void {
		// SessionStorageService.remove(`${name}.page`);
	}
}
