export enum Stage {
	local = 'local',
	dev = 'dev',
	prod = 'prod'
};

interface Config {
	readonly stage: Stage;
	readonly version: string;
	readonly minPasswordLength: number;
	readonly authIssuer: string;
}

export const config: Config = {
	"stage": Stage.dev,
	"version": "2.0.7",
	"minPasswordLength": 8,
	"authIssuer": "Elegant"
};
