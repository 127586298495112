import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | filesize:precision
 * Example:
 *   {{ 1024 |  filesize}}
 *   formats to: 1 KB
 * @attribution https://gist.github.com/JonCatmull/ecdf9441aaa37336d9ae2c7f9cb7289a
*/
@Pipe({"name": "filesize"})
export class FileSizePipe implements PipeTransform {

	private units = [
		'bytes',
		'KB',
		'MB',
		'GB',
		'TB',
		'PB'
	];

	transform(bytes: number = 0, precision: number = 2 ) : string {
		if (bytes === null || bytes === undefined) return "";
		let value = typeof bytes === "number" ? bytes : parseFloat(String(bytes));

		if ( isNaN(value) || ! isFinite(value) ) return '?';
		if ( value == 0 ) return '0 bytes';

		let unit = 0;

		while ( value >= 1024 ) {
			value /= 1024;
			unit ++;
		}

		if (unit === 0) {
			precision = 0;
		}

		return value.toFixed( + precision ) + ' ' + this.units[ unit ];
	}
}
