import { Injectable } from "@angular/core";
import { Router, NavigationStart, RouterStateSnapshot } from "@angular/router";
import { filter } from "rxjs/operators";

/**
* Union type of router snapshot and navigation event. Both have a "url" string property
* we can use later.
**/
type SnapshotOrEvent = NavigationStart | RouterStateSnapshot;

/**
* Maintains a short history of the navigation state, and provides a method for
* determining if the last navigation event was generated by the "Back" button.
*/
@Injectable({ "providedIn": "root" })
export class HistoryService {

	private _events: SnapshotOrEvent[] = [];

	constructor(private router: Router) {

		// Add the initial router state for the first page that was loaded
		this._events.unshift( this.router.routerState.snapshot );

		// Subscribe to navigation events. Store no more than the current event, and the previous event.
		this.router.events.pipe(
			filter( event => event instanceof NavigationStart)
		).subscribe( event => {
			this._events.unshift(<NavigationStart>event);
			if (this._events.length > 2) {
				this._events.pop();
			}
		});
	}

	public get lastEventUrl(): string|undefined {
		return this._events.length > 0 ? this._events[0].url : undefined;
	}

	public get previousEventUrl(): string|undefined {
		return this._events.length > 0 ? this._events[this._events.length - 1].url : undefined;
	}

	private isNavEvent(item: SnapshotOrEvent): item is NavigationStart {
		return item instanceof NavigationStart;
	}

	public get lastEventWasBackButton(): boolean {
		const lastItem = this._events[0];
		if (this.isNavEvent(lastItem)) {
			return lastItem.navigationTrigger === "popstate";
		}
		return false;
	}
}
