import { EInvalidArgument } from "@classes/errors";
import { EPermissionsNotLoaded } from "@classes/errors";

export interface PermissionGroup {
	id: string;
	name: string;
	permissions: Map<string, string>;
}

function normaliseString(value: string): string {
	return (value ?? '').trim().toLowerCase().replace(/\s/g, '');
}

/**
* Used to reference a permission by group and name.
* The Permissions class (defined below) can determine a unique ID based on these values.
*/
export interface Permission {
	group: string;
	name: string;
}

/**
* Helper function to generate a Permission instance
*/
export function Permission(group: string, name: string): Permission {
	return {
		"group": normaliseString(group),
		"name": normaliseString(name)
	};
}

const permissionNames: Map<string, string> = new Map<string, string>();

export namespace Permission {

	export const superuserGroup: string = 'ac2eac00-4b07-41d8-a65c-33b24f285bb4';

	export function getPermissionName(id: string): string {
		if (permissionNames.has(id)) {
			return permissionNames.get(id)!;
		}

		throw new Error("Unknown permission specified");
	}
}

/**
* Methods for parsing the permissions data from the server into usable structures.
* Not exported, used only by the "Permissions" class below when data is first loaded.
*/
namespace PermissionGroup {


	export function parse(src: any): Map<string, PermissionGroup> {

		let result = new Map<string, PermissionGroup>();

		src.forEach( (row: any) => {

			const permissionGroup = {
				"id": row.groupId,
				"name": row.groupName,
				"permissions": new Map<string, string>()
			};

			Object.keys(row.permissions).forEach( key => {
				permissionNames.set(row.permissions[key], key);
				permissionGroup.permissions.set(normaliseString(key), normaliseString(row.permissions[key]));
			});

			result.set(normaliseString(row.groupName), permissionGroup);
		});

		return result;
	}

	export function reverseMap(src: any): Map<string, Map<string, string>> {
		const result = new Map<string, Map<string, string>>();
		src.forEach( (group: any) => {

			const groupName = normaliseString(group.groupName);
			result.set( groupName, new Map<string, string>());

			Object.keys(group.permissions).forEach( permissionName => {
				result.get(groupName)!.set( normaliseString(permissionName), group.permissions[permissionName] );
			});

		});

		return result;
	}
}

/**
* Wrapper class around structures which provide easy access to permissions data
* Singleton, initialised when the user logs in from the auth service.
*/
export class Permissions {
	private static singletonInstance: Permissions;

	/**
	* Map of permission groups. Key is the group ID, value is a permission group
	*/
	private _groupMap: Map<string, PermissionGroup> = new Map<string, PermissionGroup>();

	/**
	* Map of maps... Used to quickly locate a permission ID from a group name and permission name
	* Key for top level map is the group name, value is a map of permission names and IDs
	* Key for nested map is the permission name, value is the permission ID
	*/
	private _reverseMap: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();

	private constructor(src: any) {
		this._groupMap = PermissionGroup.parse(src);
		this._reverseMap = PermissionGroup.reverseMap(src);
	}

	public static init(src: any): Permissions {
		Permissions.singletonInstance = new Permissions(src);
		return Permissions.singletonInstance;
	}

	public static getPermissionId(permission: Permission): string {

		if (!Permissions.singletonInstance) {
			throw new EPermissionsNotLoaded();
		}

		const result  = Permissions.singletonInstance._reverseMap.get(permission.group)?.get(permission.name);
		if (!result) {
			throw new EInvalidArgument(`Invalid permission "${permission.group}", "${permission.name}"`);
		}

		return result;
	}

	/**
	* Returns an array of all permission groups
	*/
	public static allPermissions(): PermissionGroup[] {

		if (!Permissions.singletonInstance) {
			throw new EPermissionsNotLoaded();
		}

		return Array.from(Permissions.singletonInstance._groupMap.values());
	}
}
