import { assert, EAssertionFailed } from "@classes/errors";
export { assert } from "@classes/errors";
import { DateTime } from "luxon";

export function hasValue<T>(value: T | null | undefined): value is NonNullable<T> {
	return <T>value !== undefined && <T>value !== null;
}

export function assertHasValue<T>(value: T | null | undefined, msg?: string): asserts value is NonNullable<T> {
	if (<T>value === null || <T>value === undefined) {
		throw new EAssertionFailed(msg);
	}
}

export namespace NumberUtils {

	export function parse(src: string|number): number|undefined {
		const n = Number(src);
		return Number.isNaN(n) ? undefined : n;
	}

	/**
	* Rounds a number to 2dp, suitable for comparing currency values and avoiding rounding errors.
	*/
	export function currency(src: string|number): number|undefined {
		const n = NumberUtils.parse(src);
		if (n === undefined) {
			return undefined;
		}

		return Math.round(n * 100) / 100;
	}
}

export class StringUtils {

	public static empty(src: string|null|undefined, trim: boolean = true): boolean {
		return trim ? StringUtils.trimmedLength(src) === 0 : (src ?? '').length === 0;
	}

	public static sameString(a: string, b: string, allowNullAndUndefined: boolean = true): boolean {
		return !a && !b && allowNullAndUndefined ? true : a === b;
	}

	public static trim(value: string|null|undefined): string|undefined {
		return value?.trim() ?? undefined;
	}

	public static trimmedLength(value: string|null|undefined): number {
		return (value ?? '').trim().length
	}

	public static truncateString(text: string, maxLength: number = 100): string {
		const content = text || '';
		return content.length > maxLength ? content.slice(0, maxLength).split(' ').slice(0, -1).join(' ') + StringUtils.ellipsis : content;
	}

	public static readonly ellipsis = "…";

	public static optionalString(value: string|undefined|null, defaultValue: string = ''): string {
		return value ?? defaultValue;
	}

	/**
	* Returns a string formatted as a 2dp number with commas and a leading $ sign. Suitable for error messages, etc
	*/
	public static currency(value: string|number|undefined|null): string|undefined {
		if (!hasValue(value)) {
			return undefined;
		}

		var formatter = new Intl.NumberFormat('en-AU', { "style": 'currency', "currency": 'AUD' });
		const result = NumberUtils.parse(value);
		return result !== undefined ? formatter.format(result) : undefined
	}

	public static removeEmptyStringsFromArray(src: (string|null|undefined)[]): string[] {
		return src.filter(item => !StringUtils.empty(item)) as string[];
	}
}

export class DateUtils {

	/**
	* Creates a copy of a date object
	*/
	public static clone(src?: Date): Date|undefined {
		if (!src) {
			return undefined;
		}

		return new Date(src.valueOf());
	}

	public static equals(a: Date, b: Date): boolean {
		if ((!a && !!b) || (!!a && !b)) {
			return false;
		}

		return a.valueOf() === b.valueOf();
	}

	public static fromFormat(src: string, format: string): Date|undefined {
		try {
			return DateTime.fromFormat(src, format, {"locale": "en-AU"}).toJSDate();
		}
		catch (e) {
			return undefined;
		}
	}

	/**
	* Parses a string, and returns a data object truncated to the start of the day.
	* Returns undefined if the date string is not valid.
	*/
	public static parse(src: string, includeTime?: boolean): Date|undefined {
		try {
			const d = DateTime.fromISO(src);
			if (d.isValid) {
				return includeTime ? d.toJSDate() : d.startOf('day').toJSDate();
			}
			return undefined;
		}
		catch (e) {
			return undefined;
		}
	}

	/**
	* Converts a Date object to a string.
	* If no format argument is provided, the result is an ISO date string (yyyy-mm-dd)
	*/
	public static toString(src: Date|null|undefined, format?: string): string|undefined {
		if (!src) {
			return undefined;
		}

		const dateTime = DateTime.fromJSDate(src);
		return format ? dateTime.toFormat(format) : dateTime.toISODate();
	}

	public static toLocaleString(src: Date, formatOpts?: any): string|undefined {
		if (!src) {
			return undefined;
		}

		const dateTime = DateTime.fromJSDate(src);
		return formatOpts ? dateTime.toLocaleString(formatOpts) : dateTime.toLocaleString(DateTime.DATE_SHORT);
	}

	/**
	* Converts a Date object to a string, including the time component.
	*/
	public static toISOString(src: Date|undefined): string|undefined {
		if (!src) {
			return undefined;
		}

		const dateTime = DateTime.fromJSDate(src);
		return dateTime.toISO();
	}

	/**
	* Compare dates.
	* https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-diff
	*/
	public static diff(d1: Date, d2: Date): number|undefined {
		if (!d1 || !d2) {
			return undefined;
		}

		const i1 = DateTime.fromJSDate(d1);
		const i2 = DateTime.fromJSDate(d2);
		return i2.diff(i1).toObject().milliseconds; //=> { milliseconds: 43807500000 }
	}

}

export namespace UUIDUtils {
	export function isUUID(value: string|undefined|null): boolean {
		const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
		return re.test(value ?? "");
	}
}

export enum Key {
	Backspace = 8,
	Tab = 9,
	Enter = 13,
	Shift = 16,
	Escape = 27,
	ArrowLeft = 37,
	ArrowRight = 39,
	ArrowUp = 38,
	ArrowDown = 40,
	Comma = 188
}

export class FloatingPointUtils {

	private static validatePrecision(precision: number) {
		assert( Math.log10(precision) === Math.round(Math.log10(precision)),  "Precision must be a power of 10" );
	}

	public static readonly defaultPrecision = 0.01;

	public static equals(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		FloatingPointUtils.validatePrecision(precision);
		return Math.round(a / precision) === Math.round(b / precision);
	}

	public static lessThan(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		FloatingPointUtils.validatePrecision(precision);
		return Math.round(a / precision) < Math.round(b / precision);
	}

	public static lessThanOrEqualTo(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		FloatingPointUtils.validatePrecision(precision);
		return Math.round(a / precision) <= Math.round(b / precision);
	}

	public static greaterThan(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		return FloatingPointUtils.lessThan(b, a, precision);
	}

	public static greaterThanOrEqualTo(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		return FloatingPointUtils.lessThanOrEqualTo(b, a, precision);
	}
}

export namespace SetUtils {
	export function sameSet<T>(a: Set<T>, b: Set<T>): boolean {
		return a.size === b.size && [...a].every(item => b.has(item));
	}
}
