import { Component, AfterViewInit } from "@angular/core";
import { DBVersionService } from "@services/dbVersion.service";
import { SessionStorageService } from "@services/sessionStorage.service";
import { gte as semverGTE } from "semver";
import { config } from "@root/config";

@Component({
  selector: 'version-check',
  templateUrl: './versionCheck.component.html',
  styleUrls: ['./versionCheck.component.scss']
})
export class VersionCheckComponent implements AfterViewInit {

	private static readonly reloadKey = 'd8c25f36-0b95-466d-ad87-6724f00360d5';

	private _newVersionAvailable: boolean = false;

  constructor(private readonly versionService: DBVersionService) {}

	private async checkFrontEndVersion(): Promise<void> {
		try {
			const reloadAlreadyAttempted = SessionStorageService.get(VersionCheckComponent.reloadKey) ?? false;
			const serverVersion = await this.versionService.checkFrontendSoftwareVersion();

			const isLatestVersion = semverGTE(config.version, serverVersion);

			if (!isLatestVersion) {
				console.log(`Current version is ${config.version}, latest version is ${serverVersion}`);
				if (!reloadAlreadyAttempted) {
					this._newVersionAvailable = true;
				}
				else {
					console.log("Version update failed");
				}
			}
			else {
				SessionStorageService.remove(VersionCheckComponent.reloadKey)
			}
		}
		catch (e) {
			// Ignore
		}
	}

	public reload() {
		SessionStorageService.set(VersionCheckComponent.reloadKey, true);
		window.location.reload();
	}

	public get newVersionAvailable(): boolean {
		return this._newVersionAvailable;
	}

	ngAfterViewInit() {
		this.checkFrontEndVersion();
	}
}
