import { Component } from "@angular/core";
import { PrivateComponent } from "@classes/private.component";
import { Breadcrumbs } from "@classes/breadcrumbs";
import { NotificationService } from "@services/notification.service";
import { ErrorUtils } from "@classes/errors";
import { MFAType } from "@services/auth.service";
import { DialogService } from "@services/dialog.service";
import { CustomerService } from "@services/customer.service";
import { hasValue } from "@utils";
import { Customer } from "@classes/customer";

@Component({
	"templateUrl": "./myaccount.component.html",
	"styleUrls": ["./myaccount.component.scss"]
})
export class MyAccountComponent extends PrivateComponent {

	private _loaded: boolean = false;
	private _mfaType: MFAType = 'NOMFA';
	private _customer?: Customer;
	private _mfaRequired: boolean = false;

	private initBreadcrumbs() {
		this.breadcrumbs.reset();
		this.breadcrumbs.addPath("My Account", `/myaccount`);
	}

	private async loadCustomer() {
		if (hasValue(this.currentUser) && hasValue(this.currentUser.customer)) {
			// this._customer = await this.customerService.getCustomer(this.currentUser.customer.id);
			this._mfaRequired = await this.customerService.getCustomerMFAStatus();
		}
		else {
			this._customer = undefined;
		}
	}

	private async loadMFAType() {
		this._mfaType = await this.authService.getPreferredMFA(false);
	}

	private async loadData() {
		try {
			await this.loadCustomer();
			await this.loadMFAType();
			this._loaded = true;
		}
		catch (e) {
			NotificationService.error("Error", "Unable to determine MFA preferences for this account");
			ErrorUtils.logError(e, this.currentUser);
		}
	}

	constructor(private customerService: CustomerService) {
		super();
	}

	protected ready() {
		this.initBreadcrumbs();
		this.loadData();
	}

	public get loaded(): boolean {
		return this._loaded;
	}

	public readonly breadcrumbs: Breadcrumbs = new Breadcrumbs();

	public get mfaType(): MFAType {
		return this._mfaType;
	}

	public get customer(): Customer | undefined {
		return this._customer || undefined;
	}

	public confirmRemoveMFA() {
		DialogService.showDialog(
			"Disable Multi-Factor Authentication",
			"Please confirm that you want to remove MFA from your account",
			[{
				"text": "Cancel",
				"handler": DialogService.hide
			}, {
				"text": "Confirm",
				"handler": this.removeMFA.bind(this)
			}]
		);
	}

	public async removeMFA() {
		DialogService.hide(); // ⟵ Remove old dialog

		DialogService.show(); // ⟵ Show "Loading..." dialog
		try {
			const result = await this.authService.removeMFA();
			if (result) {
				this._mfaType = 'NOMFA';
			}
		}
		finally {
			DialogService.hide();
		}
	}

	public get mfaRequired(): boolean {
		// return this._customer?.mfaRequired ?? false;
		return this._mfaRequired;
	}
}
