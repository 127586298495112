import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '@services/auth.service';
import { hasValue } from "@utils";

@Component({
  "templateUrl": "./totp.component.html",
  "styleUrls": ["./totp.component.scss"]
})
export class TOTPComponent implements AfterViewInit {

	@ViewChild('totp')
	private _totpInputField!: ElementRef<HTMLInputElement>;

	private _loading: boolean = false;
	private _busy: boolean = false;
	private _errorMessageVisible: boolean = false;

	constructor(private authService: AuthService, private router: Router) {

		// Do not allow access if we don't know who is trying to verify their account
		if (!this.authService.hasAWSAuthUser) {
			this.router.navigate(["/login"]);
		}

		// Also do not allow access if the user is already logged in
		else if (hasValue(this.authService.currentUser)) {
			this.router.navigate([this.authService.usersHomePage]);
		}
	}

	ngAfterViewInit() {
		if (hasValue(this._totpInputField)) {
			this._totpInputField.nativeElement.focus();
		}
	}

	public totpCode: string = "";

	public async confirmLogin(): Promise<void> {
		this._busy = true;
		try {
			this._loading = true;
			const result = await this.authService.confirmTOTP(this.totpCode);
			if (result) {
				this.router.navigate([this.authService.usersHomePage]);
			}
			else {
				this._errorMessageVisible = true;
			}
		}
		catch (err) {
			this._loading = false;
			throw err;
		}
		finally {
			this._busy = false;
		}
	}

	public get loading(): boolean {
		return this._loading;
	}

	public get busy(): boolean {
		return this._busy;
	}

	public get errorMessageVisible(): boolean {
		return this._errorMessageVisible;
	}
}
