import { ServiceLocator } from '@classes/serviceLocator';
import { AuthService } from "@services/auth.service";
import { Link } from "@classes/link";
export { Link } from "@classes/link";

export class Breadcrumbs {
	private _links: Link[] = [];
	private authService: AuthService;

	constructor(addHome: boolean = true) {
		this.authService = ServiceLocator.injector.get(AuthService);
		this.reset(addHome);
	}

	public addLink(link: Link) {
		this._links.push(link);
	}

	public addPath(name: string, path: string|any[]) {
		this._links.push( Link.create(name, path) );
	}

	public get path(): Link[] {
		return this._links;
	}

	public reset(addHome: boolean = true) {
		this._links = [];
		if (addHome) {
			this.addPath('Home', this.authService.usersHomePage);
		}
	}
}
