import { Address } from "@classes/address";
import { EnumMetadata } from "@classes/enumUtils";
import { Entity, NamedEntity } from "@classes/entities";

export enum CustomerStatus {
	active, disabled
}

export namespace CustomerStatus {
	const enumData: EnumMetadata<CustomerStatus>[] = [
		[CustomerStatus.active, "Active", "active"],
		[CustomerStatus.disabled, "Disabled", "disabled"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): CustomerStatus|undefined {
		return values.get(value);
	}

	export function toString(value: CustomerStatus|undefined): string|undefined {
		return value !== undefined ? descriptions.get(value) : undefined;
	}

	export function toPostgresEnum(value: CustomerStatus|undefined): string|undefined {
		return value !== undefined ? pgEnums.get(value) : undefined;
	}

	export function allValues(): CustomerStatus[] {
		return enumData.map( item => item[0] );
	}
}

export class Customer extends NamedEntity {
	private static readonly customersThatDontWantPatientInfo: string[] = [
		'ff277d62-197f-4165-a29c-d7b3369eb4e1', // ⟵ CALHN dev
		'b4c4cb4b-744a-4457-89dd-0f52993310e5' // ⟵ CALHN prod
	];

	public address: Address|undefined;
	public postalAddress: Address|undefined;
	public contact: string|undefined;
	public email: string|undefined;
	public phone: string|undefined;
	public status: CustomerStatus|undefined;
	public mfaRequired: boolean;
	public surgeries: NamedEntity[] = [];

	public static visicodeHidePatientInfo(customer?: Entity|Customer): boolean {
		if (!customer) {
			return false;
		}
		return Customer.customersThatDontWantPatientInfo.includes(customer.id);
	}

	protected constructor(src: Customer|any, newId: boolean = false) {
		super(src, newId);
		this.address = Address.parse(src.address);
		this.postalAddress = Address.parse(src.postalAddress);
		this.contact = src.contact;
		this.email = src.email;
		this.phone = src.phone;
		this.status = CustomerStatus.parse(src.status);
		this.mfaRequired = !!src.mfaRequired;
		this.surgeries = src instanceof Customer ? src.surgeries.map(item => item.clone) : (src.surgeries || []).map(NamedEntity.parse);
	}

	public override get json(): {[key: string]: any} {
		return Object.assign(super.json, {
			"address": Address.toJSON(this.address),
			"postalAddress": Address.toJSON(this.postalAddress),
			"contact": this.contact,
			"email": this.email,
			"phone": this.phone,
			"status": CustomerStatus.toPostgresEnum(this.status),
			"mfaRequired": this.mfaRequired,
			"surgeries": this.surgeries.map(item => item.json)
		});
	}

	public static override parse(src: any): Customer {
		return new Customer(src);
	}

	public override clone(newId: boolean = false): Customer {
		return new Customer(this, newId);
	}

	public static override empty(): Customer {
		return new Customer({"name": ""});
	}
}
