import { BrowserStorageCache } from "@aws-amplify/cache";

/**
* Simple wrapper around the AWS Amplify Cache class.
* Provides client-side session storage using key-based access to stored values.
* Whilst labelled as a service, interaction is purely through static class methods, so
* there is no need to construct an instance of this class.
*/
export class SessionStorageService {

	private static _storage: any = undefined;

	private constructor() {};

	private static initCache(): void {
		if (SessionStorageService._storage === undefined) {
			const config = {
				"keyPrefix": "dx",
				"storage": window.sessionStorage
			};
			SessionStorageService._storage = BrowserStorageCache.createInstance(config);
		}

		return SessionStorageService._storage;
	}

	private static get storage(): any {
		return SessionStorageService._storage ?? SessionStorageService.initCache();
	}

	/**
	* Stores a value against a key in the cache
	* @param {string} key The name used to reference the stored item
	* @param {any} value The value to store
	*/
	public static set(key: string, value: any): void {
		SessionStorageService.storage.setItem(key, value);
	}

	/**
	* Removes a value from the cache
	* @param {string} key The name used to reference the stored item
	*/
	public static remove(key: string): void {
		SessionStorageService.storage.removeItem(key);
	}

	/**
	* Retrieves a value from the cache
	* @param {string} key The name of the item to retrieve
	* @param {boolean} clear (Optional) Specifies whether the value should be removed from the cache
	* @return {any} The cached value
	*/
	public static get(key: string, clear?: boolean): any {
		const result = SessionStorageService.storage.getItem(key);
		if (!!clear) {
			SessionStorageService.storage.removeItem(key);
		}
		return result;
	}

	/**
	* Removes all items stored in the cache
	*/
	public static clear() {
		SessionStorageService.storage.clear();
	}
}
