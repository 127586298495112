import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

/**
* Directive to support ctrl+click or middle click to open in a new window.
* Replacement for the [routerLink] attribute.
* Adapted from: https://stackoverflow.com/a/44782188/4634642
*/
@Directive({
	"selector": "[link]"
})
export class LinkDirective {

	private readonly middleMouseButton = 2;

	private get linkToArray(): any[] {
		return Array.isArray(this.link) ? this.link : [this.link];
	}

	private openInNewWindow(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
		const urlTree = this.router.createUrlTree( this.linkToArray );
		window.open(urlTree.toString(), "_blank");
	}

	@Input('link')
	public link!: string|any[];

	constructor(private router: Router) {}

	/**
	* Middle click
	*/
	@HostListener('mouseup', ["$event"])
	public onMouseUp(event: MouseEvent) {
		if (event.which === this.middleMouseButton) {
			this.openInNewWindow(event);
		}
	}

	/**
	* Ctrl+Click or Cmd+Click
	*/
	@HostListener('click', ["$event"])
	public onClick(event: MouseEvent) {

		const isTableCell = event.target instanceof HTMLTableCellElement;
		const hasSelection = !!window.getSelection()?.toString();

		if (event.ctrlKey || event.metaKey) {

			// Browsers automatically select table cells when ctrl+clicking. Ignore selection if the event target was a table cell.
			// For other elements (buttons, links), do not open the link if the user has selected text
			if (isTableCell) {
				this.openInNewWindow(event);
			}
			else if (!hasSelection) { // ⟵ Open the link only if the user hasn't selected text
				this.openInNewWindow(event);
			}

		}
		else if (!hasSelection) { // ⟵ Open the link only if the user hasn't selected text
			this.router.navigate( this.linkToArray );
		}
	}
}
