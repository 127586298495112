import { Injectable } from "@angular/core";
import { StorageAPI, KeyStorageAPI, AbstractStorage } from "@classes/abstractStorage";
import { assertHasValue } from "@utils";

class DefaultStorage extends AbstractStorage {
	public tableNames: string[] = ["general", "nonvolatile", "procedures"];
	public readonly dbName: string = "elegant";

	protected createObjectStores(db: IDBDatabase): void {
		db.createObjectStore("general");
		db.createObjectStore("nonvolatile");
	}

	constructor() {
		super();
	}
}

@Injectable({"providedIn": "root"})
export class StorageService {
	private storage: DefaultStorage;

	constructor() {
		this.storage = new DefaultStorage();
	}

	public get general(): KeyStorageAPI {
		return this.storage.bindObjectStoreToKeyStorageAPI("general");
	}

	public get nonVolatile(): KeyStorageAPI {
		return this.storage.bindObjectStoreToKeyStorageAPI("nonvolatile");
	}
}
