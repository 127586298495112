import { NamedEntity } from "@classes/entities";
import { Address } from "@classes/address";

export class Surgery extends NamedEntity {
	public customerId: string;
	public address: Address;
	public phone?: string;
	public email?: string;
	public disciplines: string[];

	protected constructor(src: Surgery|any, newId: boolean = false) {
		super(src, newId);
		this.customerId = src.customerId;
		this.address = Address.parse(src.address) ?? Address.blank();
		this.phone = src.phone;
		this.email = src.email;
		this.disciplines = [...(src.disciplines ?? [])];
	}

	public static override parse(src: any): Surgery {
		return new Surgery(src);
	}

	public override get json(): {[key: string]: any} {
		return Object.assign(super.json, {
			"customerId": this.customerId,
			"address": Address.toJSON(this.address),
			"phone": this.phone,
			"email": this.email,
			"disciplines": [...(this.disciplines ?? [])]
		});
	}

	public override clone(newId: boolean = false): Surgery {
		return new Surgery(this, newId);
	}

	public static override empty(): Surgery {
		return new Surgery({"name": ""});
	}
}
