import { FontAwesomeModule }                       from "@fortawesome/angular-fontawesome";

import { NgModule, LOCALE_ID }                     from "@angular/core";
import { FormsModule, ReactiveFormsModule }        from "@angular/forms";

import { FileDropDirective }                       from "@directives/filedrop/filedrop.directive";
import { LinkDirective }                           from "@directives/link/link";
import { EmailValidatorDirective }                 from "@directives/emailValidator/email.validator";
import { AuMobileValidatorDirective }              from '@directives/phoneValidator/auphone.validator';
import { AuPhoneValidatorDirective }               from '@directives/phoneValidator/auphone.validator';
import { IntegerInputDirective }                   from "@directives/integerInput/integerInput.directive";
import { TextareaAutoresizeDirective }             from "@root/app/modules/shared/directives/textarea/textareaAutoresize.directive";

import { LoadingComponent }                        from "@components/loading/loading.component";
import { PaginationComponent }                     from "@components/pagination/pagination.component";
import { TableHeaderComponent }                    from "@components/table/tableHeader.component";
import { SpinnerComponent }                        from "@components/spinner/spinner.component";
import { BreadcrumbsComponent }                    from "@components/breadcrumbs/breadcrumbs.component";
import { ToggleComponent }                         from "@components/toggle/toggle.component";
import { TabsComponent }                           from "@components/tabs/tabs.component";
import { DateInputComponent }                      from '@components/dateControl/datecontrol.component';
import { TypeaheadComponent }                      from '@components/typeahead/typeahead.component';
import { GenericListFilterComponent }              from '@components/genericListFilter/genericListFilter.component';
import { AddressComponent }                        from "@components/address/address.component";
import { FormFieldComponent }                      from "@components/formField/formField.component";

import { FileSizePipe }                            from "@pipes/filesize.pipe";
import { TruncatePipe }                            from "@pipes/truncate.pipe";

import { CommonModule }                            from "@angular/common";
import { RouterModule }                            from "@angular/router";

const componentList = [
	TableHeaderComponent,
	PaginationComponent,
	LoadingComponent,
	SpinnerComponent,
	BreadcrumbsComponent,
	ToggleComponent,
	DateInputComponent,
	TabsComponent,
	TypeaheadComponent,
	GenericListFilterComponent,
	AddressComponent,
	FormFieldComponent
];

const directiveList = [
	FileDropDirective,
	LinkDirective,
	AuMobileValidatorDirective,
	AuPhoneValidatorDirective,
	EmailValidatorDirective,
	IntegerInputDirective,
	TextareaAutoresizeDirective
];

const pipeList = [
	FileSizePipe,
	TruncatePipe
];

@NgModule({
	"imports": [
		CommonModule,
		FormsModule,
		FontAwesomeModule,
		ReactiveFormsModule,
		RouterModule
	],
	"declarations": [...componentList, ...directiveList, ...pipeList],
	"exports": [...componentList, ...directiveList, ...pipeList],
	"providers": [
		{ "provide": LOCALE_ID, "useValue": "en-AU" }
	]
})
export class SharedModule { }
