<tr *ngIf="table.moreColumns && table.moreColumns.length">
	<th *ngFor="let column of table.moreColumns" [attr.colspan]="column.colspan || 1" [ngClass]="{'has-text-centered': column.alignment === table.alignment.center, 'has-text-right': column.alignment === table.alignment.right}">{{ column.displayName }}</th>
</tr>
<tr>
	<ng-container *ngFor="let column of table.columns">
		<th
			*ngIf="!isTemplateColumn(column)"
			[attr.colspan]="column.colspan || 1"
			[class.sortable]="column.idx !== undefined && canSort(column)"
			[class.sorted]="isSortedColumn(column)"
			[class.has-text-centered]="column.alignment === table.alignment.center"
			[class.has-text-right]="column.alignment === table.alignment.right"
			(click)="canSort(column) && changeSort(column)"><fa-icon *ngIf="column.idx !== undefined && canSort(column)" [icon]="sortIcons(column)"></fa-icon>
			<ng-container *ngIf="isIcon(column)"><fa-icon [icon]="icon(column)"></fa-icon></ng-container>
			<ng-container *ngIf="!isIcon(column)"> {{ column.displayName }}</ng-container>
		</th>
		<th *ngIf="isTemplateColumn(column)" [attr.colspan]="column.colspan || 1">
			<ng-container *ngIf="isTemplateColumn(column)" [ngTemplateOutlet]="column.template!"></ng-container>
		</th>
	</ng-container>
</tr>
