import { NgModule, Injector, LOCALE_ID }           from '@angular/core';
import { BrowserModule }                           from '@angular/platform-browser';
import { FormsModule }                             from '@angular/forms';

import { AppRoutingModule }                        from '@root/app/app-routing.module';
import { SharedModule }                            from "@modules/shared/shared.module";
import { AmplifyService }                          from 'aws-amplify-angular';
import { BrowserAnimationsModule }                 from '@angular/platform-browser/animations';

import { FontAwesomeModule, FaIconLibrary }        from '@fortawesome/angular-fontawesome';
import { initIconLibrary }                         from './icons';

import { AppComponent }                            from '@components/root/app.component';
import { AppHeaderComponent }                      from '@components/header/app-header.component';
import { NotificationComponent }                   from '@components/notifications/notifications.component';
import { LoginComponent }                          from '@components/login/login.component';
import { TOTPComponent }                           from '@components/totp/totp.component';
import { ModalComponent }                          from '@components/modal/modal.component';
import { HomeComponent }                           from "@components/home/home.component";
import { MyAccountComponent }                      from "@components/myAccount/myaccount.component";
import { ForbiddenComponent }                      from "@components/forbidden/forbidden.component";
import { MaintenanceComponent }                    from "@components/maintenance/maintenance.component";
import { MaintenanceWidgetComponent }              from "@components/maintenance/widget/maintenanceWidget.component";
import { VersionCheckComponent }                   from "@components/versionCheck/versionCheck.component";

import { Settings }                                from "luxon";
import { ServiceLocator }                          from '@classes/serviceLocator';
import { SessionStorageService }                   from "@services/sessionStorage.service";

import { QRCodeModule }                            from "angularx-qrcode";

@NgModule({
	"declarations": [
		AppComponent,
		AppHeaderComponent,
		NotificationComponent,
		LoginComponent,
		TOTPComponent,
		ModalComponent,
		HomeComponent,
		MyAccountComponent,
		ForbiddenComponent,
		MaintenanceComponent,
		MaintenanceWidgetComponent,
		VersionCheckComponent
	],
	"imports": [
		BrowserModule,
		SharedModule,
		FormsModule,
		FontAwesomeModule,
		AppRoutingModule,
		QRCodeModule,
		BrowserAnimationsModule
	],
	"providers": [
		AmplifyService,
		{"provide": LOCALE_ID, "useValue": "en-AU"},
	],
	"bootstrap": [AppComponent]
})
export class AppModule {

	constructor(private injector: Injector, library: FaIconLibrary) {

		ServiceLocator.injector = this.injector;
		initIconLibrary(library);

		// Set default locale on Luxon objects
		Settings.defaultLocale = "en-AU";

		// Save the requested URL in temporary storage
		SessionStorageService.set('path', window.location.pathname);
	}

}
