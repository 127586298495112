import { Directive, Input } from '@angular/core';
import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';

@Directive({
	"selector": "[aumobile]",
	"providers": [{"provide": NG_VALIDATORS, "useExisting": AuMobileValidatorDirective, "multi": true}]
})
export class AuMobileValidatorDirective implements Validator {

	private Australia: string = "AU";

	private isValidMobileNumber(value: string): boolean {
		const input = `${value}`.trim();
		if (input !== '') {
			try {

				const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
				const phoneNumber = phoneUtil.parseAndKeepRawInput(input, "AU");

				if ( phoneUtil.isValidNumber(phoneNumber) && phoneUtil.getNumberType(phoneNumber) === libphonenumber.PhoneNumberType.MOBILE) {
					return true
				}
			}
			catch (e) {
				//  Don't care
			}
		}

		return false;
	}

	validate(control: AbstractControl): {[key: string]: any} | null {
		const valid = this.isValidMobileNumber(control.value);
		return valid ? null : {"invalidMobile": true};
	}
}

@Directive({
	"selector": "[auphone]",
	"providers": [{"provide": NG_VALIDATORS, "useExisting": AuPhoneValidatorDirective, "multi": true}]
})
export class AuPhoneValidatorDirective implements Validator {

	private Australia: string = "AU";

	private isValidPhoneNumber(value: string): boolean {
		const input = `${value}`.trim();
		if (input !== '') {
			try {

				const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
				const phoneNumber = phoneUtil.parseAndKeepRawInput(input, "AU");

				if ( phoneUtil.isValidNumber(phoneNumber)) {
					return true
				}
			}
			catch (e) {
				//  Don't care
			}
		}

		return false;
	}

	validate(control: AbstractControl): {[key: string]: any} | null {
		const valid = this.isValidPhoneNumber(control.value);
		return valid ? null : {"invalidPhone": true};
	}
}
