<ng-template #suggestionsTemplate>
	<section #resultsSection class="ta-results list-group" *ngIf="showSuggestions && results.length > 0">
		<div class="ta-backdrop" (click)="hideSuggestions()"></div>

		<ng-container *ngFor="let result of results; let i = index;">
			<!--ng-container *ngIf="markIsActive(i, result)">
				<button #activeResult type="button" class="ta-item list-group-item active" (click)="handleSelectionClick(result, i)">
					<span *ngIf="!taItemTpl"><i class="fa fa-search"></i> {{ result }}</span>
					<ng-template #typeaheadResult [ngTemplateOutlet]="taItemTpl" [ngTemplateOutletContext]="{ $implicit: {result: result, index: i} }" ></ng-template>
				</button>
			</ng-container-->

			<!--ng-container *ngIf="!markIsActive(i, result)"-->
				<button type="button" class="ta-item list-group-item" [class.active]="markIsActive(i, result)" (click)="handleSelectionClick(result, i)" [disabled]="isUnselectable(result)">
					<span *ngIf="!taItemTpl"><i class="fa fa-search"></i> {{ result }}</span>
					<ng-template #typeaheadResult [ngTemplateOutlet]="taItemTpl" [ngTemplateOutletContext]="{ $implicit: {result: result, index: i} }" ></ng-template>
				</button>
			<!--/ng-container-->
		</ng-container>

	</section>
	<section class="ta-results list-group" *ngIf="showSuggestions && results.length === 0">
		<button type="button" class="ta-item list-group-item" disabled>{{ taNoResultsMessage }}</button>
	</section>
</ng-template>
