import { hasValue } from "@utils";
import { Entity, NamedEntity } from "@classes/entities";


export class User extends Entity {
	public firstName?: string;
	public lastName?: string;
	public email?: string;
	public phone?: string;
	public mobile?: string;
	public customer?: NamedEntity;
	public surgery?: NamedEntity;
	public hasLogin: boolean = false;
	public defaultSurgery?: NamedEntity;

	public readonly permissions: string[] = [];
	public readonly surgeries: NamedEntity[] = [];

	protected constructor(src: User|any, newId: boolean = false) {
		super(src, newId);
	}

	public get name(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	public static override parse(src: any): User {
		const result = new User(src);
		result.firstName = src.firstName;
		result.firstName = src.firstName;
		result.lastName = src.lastName;
		result.email = src.email;
		result.phone = src.phone;
		result.mobile = src.mobile;
		result.customer = hasValue(src.customer) ? NamedEntity.parse(src.customer) : undefined;
		result.defaultSurgery = hasValue(src.defaultSurgery) ? NamedEntity.parse(src.defaultSurgery) : undefined;
		result.surgery = hasValue(src.surgery) ? NamedEntity.parse(src.surgery) : undefined;
		result.hasLogin = !!src.hasLogin;
		result.permissions.push(...(src.permissions || []));
		result.surgeries.push(...(src.surgeries || []).map(NamedEntity.parse));
		return result;
	}

	public override clone(newId: boolean = false) {
		return new User(this, newId);
	}

	public override get json(): {[key: string]: any} {
		return Object.assign(super.json, {
			"firstName": this.firstName,
			"lastName": this.lastName,
			"email": this.email,
			"phone": this.phone,
			"mobile": this.mobile,
			"customer": this.customer?.json,
			"surgery": this.surgery?.json,
			"hasLogin": this.hasLogin,
			"defaultSurgery": this.defaultSurgery?.json,
			"permissions": [...this.permissions],
			"surgeries": [...this.surgeries.map(item => item.json)]
		});
	}

	public hasPermission(permissionId: string): boolean {
		return this.permissions?.includes(permissionId);
	}

	public static empty(): User {
		return new User({});
	}
}
