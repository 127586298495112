export interface Link {
	url: string;
	text: string;
}

export namespace Link {

	export function create(text: string, url: string|any[]): Link {
		return <Link>{
			"url": url,
			"text": text
		};
	}
}
