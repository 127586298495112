import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";
import { map, distinctUntilChanged } from "rxjs/operators";

@Injectable({"providedIn": "root"})
export class BusyStateService {

	private _busyCount: number = 0;
	private _busySignalDriver: Subject<number> = new Subject<number>();

	private set busyCount(value: number) {
		this._busyCount = Math.max(0, value);
		this._busySignalDriver.next(this._busyCount);
	}

	private get busyCount(): number {
		return this._busyCount;
	}

	constructor() {
		this._busySignalDriver.pipe( map( v => v > 0), distinctUntilChanged() ).subscribe( busy => this.busySignal.next(busy) );
	}

	/**
	* Signal used to notify components when a remote action is happening, and they need to
	* temporarily disable user interaction.
	*/
	public readonly busySignal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public reset() {
		this.busyCount = 0;
	}

	public busy() {
		this.busyCount++;
	}

	public done() {
		this.busyCount--;
	}
}
