import { DateTime } from "luxon";
import { assertHasValue, hasValue } from "@utils";

/**
* UI Helper class to enable seamless data entry of dates. Provides automatic conversion
* between string values and JS Date objects.
* Designed to be used as the value updated by <input type="date"> or <input type="text"> controls.
* Can be used to automatically update a Date property on an object, or as a standalone intermediary.
*/
export class DateProxy {


	/**
	* Default format to use, if none is specified in the constructor
	*/
	private static readonly defaultFormat: string = 'yyyy-MM-dd';

	private _strValue: string = '';
	private _parsedDate: DateTime|undefined;
	private format: string;

	private _srcObject: any;
	private _fieldName?: string;

	/**
	* @param {string} format [Optional] Date format to use in string to date conversion. Defaults to 'yyyy-MM-dd'
	*/
	private constructor(format?: string) {
		this.format = format || DateProxy.defaultFormat;
	}

	/**
	* Creates a standalone instance of DateProxy
	*/
	public static Standalone<T extends object>(format: string = DateProxy.defaultFormat): DateProxy {
		return new DateProxy(format);
	}

	/**
	* Creates an instance of DateProxy attached to the specified property of an object
	*/
	public static Attached<T extends object>(srcObject: any, fieldName: string, format: string = DateProxy.defaultFormat): DateProxy {
		const result = new DateProxy(format);

		assertHasValue(srcObject, "Invalid object source specification");
		assertHasValue(fieldName, "Invalid object source specification");

		result._srcObject = srcObject;
		result._fieldName = fieldName;
		result._parsedDate = DateTime.fromJSDate(result._srcObject[result._fieldName]);
		result._strValue = result._parsedDate?.isValid ? result._parsedDate.toFormat(result.format) : '';
		return result;
	}

	/**
	* The current date value as a Javascript date object, or undefined if the UI input
	* string cannot be converted to a valid date
	*/
	public get date(): Date|undefined {
		return this.isValid ? this._parsedDate?.toJSDate() : undefined;
	}

	/**
	* The string representation of the current date value
	*/
	public get value(): string {
		return this._strValue;
	}

	/**
	* Indicates if the current string value represents a valid date value
	*/
	public get isValid(): boolean {
		return this._parsedDate === undefined ? false : this._parsedDate.isValid;
	}

	private updateSrcObject(value: Date|undefined) {
		if (hasValue(this._srcObject) && hasValue(this._fieldName)) {
			this._srcObject[this._fieldName] = value;
		}
	}

	/**
	* Setting allowing the current string value to be updated. Automatically converts
	* the value to a DateTime object when valid, and
	*/
	public set value(_value: string) {
		try {
			this._parsedDate = DateTime.fromFormat(_value, this.format);
		}
		catch (e) {
			this._parsedDate = undefined;
		}

		this.updateSrcObject( this.isValid ? this._parsedDate?.toJSDate() : undefined );
	}
}
