<form class="form mb-3">
	<div class="level">
		<div class="level-left">
			<div class="level-item" *ngIf="showQuery">

				<div class="field">
					<div class="control has-icons-right">
						<input [class.notempty]="!!searchModel.query" emptyToUndefined type="text" name="filter" class="input" placeholder="Search" [(ngModel)]="searchModel.query" (ngModelChange)="queryChanged()" />
						<a *ngIf="searchModel.query === undefined" class="icon is-small is-right"><fa-icon [icon]="['fas', 'search']"></fa-icon></a>
						<a id="clearQuery" *ngIf="!!searchModel.query" class="icon is-small is-right is-clickable" (click)="clearQuery()"><fa-icon [icon]="['fas', 'times-circle']"></fa-icon></a>
					</div>
				</div>

			</div>
			<div class="level-item" *ngIf="showDateFrom">
				<div class="control">
					<input #dateFrom (focus)="dateFrom.type='date'" (blur)="dateFrom.type=!!searchModel.dateFrom ? 'date' : 'text'" [type]="!!searchModel.dateFrom ? 'date' : 'text'" name="dateFrom" class="input" placeholder="Date from" [(ngModel)]="dateFromProxy!.value" (ngModelChange)="notify()"/>
				</div>
			</div>

			<div class="level-item" *ngIf="showDateTo">
				<div class="control">
					<input #dateTo (focus)="dateTo.type='date'" (blur)="dateTo.type=!!searchModel.dateTo ? 'date' : 'text'" [type]="!!searchModel.dateTo ? 'date' : 'text'" name="dateTo" class="input" placeholder="Date to" [(ngModel)]="dateToProxy!.value" (ngModelChange)="notify()" />
				</div>
			</div>

			<div class="level-item" *ngIf="showContractStatus">
				<div class="dropdown" [class.is-active]="contractStatusDropdownActive" #dropdown>
					<div class="dropdown-trigger">
						<button class="button" aria-haspopup="true" aria-controls="dropdown-menu" (click)="contractStatusDropdownActive = !contractStatusDropdownActive">
							<span>Status <span *ngIf="(searchModel.contractStatus ? searchModel.contractStatus.size : 0) > 0" class="tag">{{ searchModel.contractStatus?.size || 0 }}</span></span>
							<span class="icon is-small">
								<fa-icon [icon]="['fas', 'angle-down']"></fa-icon>
							</span>
						</button>
					</div>
					<div class="dropdown-menu" id="dropdown-menu" role="menu">
						<div class="dropdown-content">
							<ng-container *ngFor="let contractStatus of allContractStatuses()">
								<div class="dropdown-item control">
									<label class="checkbox" for="s{{contractStatus}}">
										<input [checked]="searchModel.contractStatus ? searchModel.contractStatus.has(contractStatus) : false" type="checkbox" id="s{{contractStatus}}" (change)="toggleContractStatus(contractStatus)"/> {{ contractStatusName(contractStatus) }}
									</label>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>

			<div class="level-item" *ngIf="showCustomerStatus">
				<div class="dropdown" [class.is-active]="customerStatusDropdownActive" #dropdown>
					<div class="dropdown-trigger">
						<button class="button" aria-haspopup="true" aria-controls="dropdown-menu" (click)="customerStatusDropdownActive = !customerStatusDropdownActive">
							<span>Status <span *ngIf="(searchModel.customerStatus ? searchModel.customerStatus.size : 0) > 0" class="tag">{{ searchModel.customerStatus?.size || 0 }}</span></span>
							<span class="icon is-small">
								<fa-icon [icon]="['fas', 'angle-down']"></fa-icon>
							</span>
						</button>
					</div>
					<div class="dropdown-menu" id="dropdown-menu" role="menu">
						<div class="dropdown-content">
							<ng-container *ngFor="let customerStatus of allCustomerStatuses()">
								<div class="dropdown-item control">
									<label class="checkbox" for="s{{customerStatus}}">
										<input [checked]="searchModel.customerStatus ? searchModel.customerStatus.has(customerStatus) : false" type="checkbox" id="s{{customerStatus}}" (change)="toggleCustomerStatus(customerStatus)"/> {{ customerStatusName(customerStatus) }}
									</label>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>

			<div class="level-item" *ngIf="!filterIsEmpty">
				<a class="is-clickable" (click)="clearFilter()">Clear Filters</a>
			</div>

		</div>
	</div>
</form>
