import { Component } from '@angular/core';
import { AuthService } from "@services/auth.service";
import { first, filter } from "rxjs/operators";
import { Permissions, Permission } from "@classes/permissions";

@Component({
	"selector": "maintenance-mode-widget",
	"templateUrl": "./maintenanceWidget.component.html",
	"styleUrls": ["./maintenanceWidget.component.scss"]
})
export class MaintenanceWidgetComponent {

	private _visible: boolean = false; // ⟵ By default, don't display this component. It's only meant to be visible to users that have the "Superuser/ignore maintenance mode" permission

  constructor(private readonly authService: AuthService) {
		setTimeout( () => {
			this.authService.userLoaded$.pipe( filter(x => x !== undefined), first() ).subscribe( this.userLoaded.bind(this) );
		});
  }

  private userLoaded() {
		const permissionId = Permissions.getPermissionId( Permission("Superuser", "Ignore maintenance mode") );
		this._visible = this.authService.currentUser?.hasPermission(permissionId) ?? false;
  }

  public get visible(): boolean {
  	return this._visible;
  }
}
