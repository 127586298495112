import { Component, Input } from '@angular/core';
import { Address } from "@classes/address";
import { hasValue } from "@utils";

@Component({
	"selector": "au-address[address]",
	"styleUrls": [],
	"templateUrl": "./address.component.html"
})
export class AddressComponent {

	private static _instanceCount: number = 0;

	public readonly instanceCount: number = ++AddressComponent._instanceCount;

	constructor() {}

	@Input()
	public legend: string|null = null;

	@Input()
	public address!: Address;

	@Input('copyFrom')
	public copyFromAddress: Address|undefined;

	@Input()
	public copyFromLabel: string|undefined;

	public readonly auStates: string[] = Address.states;

	public get busy(): boolean {
		return false;
	}

	public copyAddress() {
		if (hasValue(this.copyFromAddress)) {
			this.address.address1 = this.copyFromAddress.address1;
			this.address.address2 = this.copyFromAddress.address2;
			this.address.suburb = this.copyFromAddress.suburb;
			this.address.state = this.copyFromAddress.state;
			this.address.postcode = this.copyFromAddress.postcode;
		}
	}

	public isEmpty: (arg: Address) => boolean = Address.isEmpty;
}
