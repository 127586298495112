/**
* Simple, un-keyed wait queue
*/
export class SimpleWaitQueue<T> {
	private _queue: any[] = [];

	private add(resolve: any, reject: any): void {
		this._queue.push({"resolve": resolve, "reject": reject});
	}

	private resolve(result: T): void {
		while (this._queue.length) {
			const queuedRequest = this._queue.pop();
			queuedRequest.resolve.call(null, result);
		}
	}

	private reject(err: any): void {
		while (this._queue.length) {
			const queuedRequest = this._queue.pop();
			queuedRequest.reject.call(null, err);
		}
	}

	public enqueue(sourceFunc: () => Promise<T>): Promise<T> {

		return new Promise<T>( (resolve, reject) => {

			this.add(resolve, reject);

			if (this._queue.length === 1) {
				sourceFunc().then( result => {

					this.resolve(result)

				}).catch( err => {

					this.reject(err);

				});
			}
		});
	}
}

/**
* Keyed wait queue
*/
export default class WaitQueue<T> {
	private _queue = new Map<string, any[]>();

	private has(key: string): boolean {
		return this._queue.has(key);
	}

	private add(key: string, resolve: any, reject: any): void {
		if (this.has(key)) {
			this._queue.get(key)!.push({"resolve": resolve, "reject": reject});
		}
		else {
			this._queue.set(key, [{"resolve": resolve, "reject": reject}]);
		}
	}

	private resolve(key: string, result: T): void {
		if (this.has(key)) {
			while (this._queue.get(key)!.length) {
				const queuedRequest = this._queue.get(key)!.pop();
				queuedRequest.resolve.call(null, result);
			}
			this._queue.delete(key);
		}
	}

	private reject(key: string, result: T): void {
		if (this.has(key)) {
			while (this._queue.get(key)!.length) {
				const queuedRequest = this._queue.get(key)!.pop();
				queuedRequest.reject.call(null, result);
			}
			this._queue.delete(key);
		}
	}

	private queueLength(key: string): number {
		const queue = this._queue.get(key);
		return queue?.length || 0;
	}

	public enqueue<U extends T>(key: string, sourceFunc: () => Promise<U>): Promise<U> {
		return new Promise((resolve, reject) => {
			this.add(key, resolve, reject);
			if (this.queueLength(key) === 1) {
				sourceFunc().then( result => {

					this.resolve(key, result);

				}).catch( err => {

					this.reject(key, err);

				});
			}
		});
	}
}
