export enum SortType {
	none, text, date, numeric, boolean, custom
};

export type SortFunc = (a: any, b: any) => number;

export enum SortDirection {
	ascending, descending
};

export namespace SortType {

	export const sortMap = new Map<SortType, SortFunc>([
		[SortType.none, (a, b) => 0],
		[SortType.text, (a, b) => `${a}`.localeCompare(`${b}`)],
		[SortType.numeric, (a, b) => (a || 0) - (b || 0)],
		[SortType.date, (a, b) => a?.valueOf() - b?.valueOf()],
		[SortType.boolean, (a, b) => (a || 0) - (b || 0)]
	]);

}
