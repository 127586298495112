import { Component, Input } from '@angular/core';
import { hasValue } from "@utils";

@Component({
	"selector": "loading",
	"styleUrls": ["./loading.component.scss"],
	"templateUrl": "./loading.component.html"
})
export class LoadingComponent {
	@Input('overlay')
	public isOverlay: boolean = false;

	@Input()
	public message: string|undefined = undefined;

	@Input()
	public large: boolean = true;

	public get hasMessage(): boolean {
		return hasValue(this.message);
	}
}

