import { NgModule }               from '@angular/core';
import { RouterModule, Routes }   from '@angular/router';
import { LoginComponent }         from '@components/login/login.component';
import { TOTPComponent }          from '@components/totp/totp.component';
import { NotLoggedInCanActivate } from "@classes/guards/notLoggedIn.canActivate";
import { MaintenanceModeCanActivate } from "@classes/guards/maintenanceMode.canActivate";
import { LoggedInCanActivate }    from "@classes/guards/loggedIn.canActivate";
import { HomeComponent }          from "@components/home/home.component";
import { MustBeLoggedInToLoad }   from "@classes/guards/loggedIn.canLoad";
import { Permission }             from '@classes/permissions';
import { MyAccountComponent }     from "@components/myAccount/myaccount.component";
import { ForbiddenComponent }     from "@components/forbidden/forbidden.component";
import { MaintenanceComponent }   from "@components/maintenance/maintenance.component";

const globalRoutes: Routes = [
	{ "path": "", "component": HomeComponent },
	{ "path": "user/dashboard", "component": HomeComponent, "pathMatch": "full", "canActivate": [MaintenanceModeCanActivate] },
	{ "path": "login", "component": LoginComponent },
	{ "path": "totp", "component": TOTPComponent },
	{ "path": "forbidden", "component": ForbiddenComponent },
	{ "path": "maintenance", "component": MaintenanceComponent },
	{ "path": "myaccount", "component": MyAccountComponent, "canActivate": [LoggedInCanActivate, MaintenanceModeCanActivate] }
];

const customerRoutes: Routes = [{
	"path": "customers",
	"loadChildren": () => {
		return import("./modules/customers/customers.module").then( m => m.CustomersModule )
	}
}];

const userRoutes: Routes = [{
	"path": "users",
	"loadChildren": () => {
		return import("./modules/users/users.module").then( m => m.UsersModule )
	}
}];

const auditRoutes: Routes = [{
	"path": "audit",
	"loadChildren": () => {
		return import("./modules/audit/audit.module").then( m => m.AuditModule )
	}
}];

const billingRoutes: Routes = [{
	"path": "billing",
	"loadChildren": () => {
		return import("./modules/billing/billing.module").then( m => m.BillingModule )
	}
}];

const passwordRoutes: Routes = [{
	"path": "password",
	"loadChildren": () => {
		return import("./modules/password/password.module").then( m => m.PasswordModule )
	}
}];

const visicodeRoutes: Routes = [{
	"path": "visicode",
	"loadChildren": () => {
		return import("./modules/visicode/visicode.module").then( m => m.VisicodeModule )
	},
	"canLoad": [MustBeLoggedInToLoad],
	"canActivate": [LoggedInCanActivate],
	"data": {
		"loadPermissions": [Permission("Visicode", "Access")]
	}
}];

const icd10Routes: Routes = [{
	"path": "icd10",
	"loadChildren": () => {
		return import("./modules/icd10/icd10.module").then( m => m.ICD10Module )
	},
	"canLoad": [MustBeLoggedInToLoad],
	"canActivate": [LoggedInCanActivate],
	"data": {
		"loadPermissions": [Permission("ICD10", "Admin")]
	}
}];

const productRoutes: Routes = [{
	"path": "products",
	"loadChildren": () => {
		return import("./modules/products/products.module").then( m => m.ProductsModule )
	}
}];

const contractRoutes: Routes = [{
	"path": "contracts",
	"loadChildren": () => {
		return import("./modules/contracts/contracts.module").then( m => m.ContractsModule )
	}
}];

const invoiceRoutes: Routes = [{
	"path": "invoices",
	"loadChildren": () => {
		return import("./modules/invoices/invoices.module").then( m => m.InvoicesModule )
	}
}];

const superuserRoutes: Routes = [{
	"path": "su",
	"loadChildren": () => {
		return import("./modules/superuser/superuser.module").then( m => m.SuperuserModule )
	},
	"canLoad": [MustBeLoggedInToLoad],
	"data": {
		"loadPermissions": [Permission("Superuser", "View Superuser Permissions")]
	}
}];

const resicatRoutes: Routes = [{
	"path": "resicat",
	"canLoad": [MustBeLoggedInToLoad],
	"loadChildren": () => {
		return import("./modules/resicat/resicat.module").then( m => m.ResicatModule )
	}
}];

const apiRoutes: Routes = [{
	"path": "visicode-api",
	"canLoad": [MustBeLoggedInToLoad],
	"loadChildren": () => {
		return import("./modules/api/api.module").then( m => m.VisicodeAPIModule )
	}
}];

const emptyRoutes: Routes = [];
const allRoutes = emptyRoutes.concat(
	globalRoutes,
	customerRoutes,
	userRoutes,
	auditRoutes,
	billingRoutes,
	passwordRoutes,
	visicodeRoutes,
	icd10Routes,
	productRoutes,
	contractRoutes,
	invoiceRoutes,
	superuserRoutes,
	resicatRoutes,
	apiRoutes
);

@NgModule({
	"imports": [
		RouterModule.forRoot(allRoutes)
	],
	"exports": [
		RouterModule
	],
	"providers": [
		LoggedInCanActivate,
		MaintenanceModeCanActivate,
		NotLoggedInCanActivate,
		MustBeLoggedInToLoad
	]
})
export class AppRoutingModule { }
