import { HostListener } from '@angular/core';
import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { take, filter, takeUntil } from "rxjs/operators";
import { DialogMode, DialogService, ErrorMessage, Template, Dialog } from '@services/dialog.service';
import { StringUtils, Key } from "@utils";

@Component({
	"selector": "modal",
	"templateUrl": "./modal.component.html",
	"providers": [ DialogService ],
	"styleUrls": ["./modal.component.scss"]
})
export class ModalComponent implements OnDestroy {

	private _subscriptions: Subscription[] = [];
	private _template?: Template;
	private _templateReady: boolean = false;
	private _unsubscribeSignal: Subject<void> = new Subject<void>();
	private _isClosing: boolean = false;

	public get isClosing(): boolean {
		return this._isClosing;
	}

	public readonly dialogMode = {
		"none": DialogMode.none,
		"wait": DialogMode.wait,
		"error": DialogMode.error,
		"success": DialogMode.success,
		"dialog": DialogMode.dialog,
		"template": DialogMode.template,
		"panel": DialogMode.panel
	};

	public mode: DialogMode = DialogMode.none;

	public loadingMessage: string = `Loading${StringUtils.ellipsis}`;

	public errorMessage: ErrorMessage = {
		"title": "",
		"message": "",
		"buttonText": "Close"
	};

	public dialog?: Dialog;

	public get template(): Template|undefined {
		return this._template;
	}

	public set template(value: Template|undefined) {
		this._templateReady = false;
		this._template = value;
		if (!this._template !== undefined) {
			if (!this._template?.readySignal) {
				this._templateReady = true;
			}
			else {
				this._template.readySignal.pipe(filter(value => value === true), take(1)).subscribe(_ => this._templateReady = true);
			}
		}
		else {
			this._templateReady = false;
		}
	}

	public get templateReady(): boolean {
		return this._templateReady;
	}

	public get isActive(): boolean {
		return this.mode !== DialogMode.none;
	}

	@HostListener('window:keyup', ['$event'])
	private onkeyup(event: KeyboardEvent) {

		const conditions = [
			event.keyCode === Key.Escape,
			this.mode !== DialogMode.wait,
			this.mode === DialogMode.template && !this.template?.hideCloseButton
		];

		if (conditions.every(x => !!x)) {
			event.preventDefault();
			this.closeDialog();
		}
	}

	public constructor() {
		DialogService.loadingText$.pipe(takeUntil(this._unsubscribeSignal)).subscribe( loadingText => {
			this.loadingMessage = loadingText;
		});

		DialogService.errorMessageS.pipe(takeUntil(this._unsubscribeSignal)).subscribe( errorMessage => {
			this.errorMessage = errorMessage;
		});

		DialogService.dialogMode$.pipe(takeUntil(this._unsubscribeSignal)).subscribe( mode => {
			this.mode = mode;
		});

		DialogService.dialog$.pipe(takeUntil(this._unsubscribeSignal)).subscribe( dialog => {
			this.dialog = dialog;
		});

		DialogService.templateMode$.pipe(takeUntil(this._unsubscribeSignal)).subscribe( template => {
			this.template = template;
		});

		DialogService.panel$.pipe(takeUntil(this._unsubscribeSignal)).subscribe( template => {
			this.template = template;
		});

		DialogService.closeSignal$.pipe(takeUntil(this._unsubscribeSignal)).subscribe(this.closeDialog.bind(this));
	}

	public ngOnDestroy(): void {
		this._unsubscribeSignal.next();
		this._unsubscribeSignal.complete();
		this._templateReady = false;
	}

	public triggerClose() {
		DialogService.hide();
	}

	private closeDialog(): void {
		this._templateReady = false;
		if (this.mode === DialogMode.panel) {

			this._templateReady = false;

			setTimeout(_ => {
				this._isClosing = true;
			}, 200);

			setTimeout(_ => {
				this.mode = DialogMode.none;
				this._isClosing = false;
			}, 400);
		}
		else {
			this.mode = DialogMode.none;
		}
	}

}
