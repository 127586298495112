import { Injectable } from "@angular/core";
import { API, RestService } from "@services/rest.service";
import { Customer } from "@classes/customer";
import AppConstants from "@classes/appConstants";
import { PaginatedData } from "@classes/pagination";
import { User } from "@classes/user";
import { GenericListFilter } from "@classes/genericListFilter";
import { Address } from "@classes/address";
import { StorageService } from "@services/storage.service";
import { Surgery } from "@classes/surgery";
import { hasValue, assertHasValue,  } from "@utils";
import WaitQueue from "@classes/waitQueue";
import { Entity, NamedEntity } from "@classes/entities";
import { Contract } from "@classes/contract";
import { ContractProduct } from "@classes/contractProduct";
import { FydoAPI } from "@classes/fydoAPI";

@Injectable({ "providedIn": "root" })
export class CustomerService {

	private _entityQueue = new WaitQueue<Entity>();
	private _waitQueue = new WaitQueue<Entity[]>();

	constructor(private restService: RestService, private storage: StorageService) {}

	public async getCustomers(filter: GenericListFilter, sort: number = 0, page: number = 1, resultsPerPage: number = AppConstants.defaultResultsPerPage): Promise<PaginatedData<Customer>> {
		const postData = {
			"filter": GenericListFilter.toJSON(filter),
			"pagination": {
				"page": page,
				"resultsPerPage": resultsPerPage,
				"sort": sort
			}
		};

		const response = await this.restService.post(API.customers, "", postData);

		const customers: Customer[] = response.data.map(Customer.parse);
		await this.storage.general.set(customers.map(customer => customer.id), customers.map(customer => customer.json));

		return {
			"data": customers,
			"totalItems": response.count,
			"summary": response.summary,
			"pageSize": response.pageSize
		};
	}

	private async fetchCustomer(customerId: string): Promise<Customer> {
		const response = await this.restService.get(API.customers, `customer/${customerId}`);
		const result: Customer = Customer.parse(response);
		await this.storage.general.set(customerId, result.json);
		return result;
	}

	public async getCustomer(customerId: string): Promise<Customer> {
		const cached = await this.storage.general.get(customerId);
		if (hasValue(cached)) {
			return Customer.parse(cached);
		}

		return await this._entityQueue.enqueue(customerId, this.fetchCustomer.bind(this, customerId));
	}

	public async getCustomerMFAStatus(): Promise<boolean> {
		const data = await this.restService.get(API.customers, `mfa-status`);
		return data.mfaRequired;
	}

	public async saveCustomer(customer: Customer): Promise<Customer> {
		const response = await this.restService.post(API.customers, 'customer', {"customer": customer.json});
		const result = Customer.parse(response);
		assertHasValue(result, "Customer not found");

		result.address = result.address || Address.blank();
		result.postalAddress = result.postalAddress || Address.blank();

		await this.storage.general.set(customer.id, customer.json);
		return result;
	}

	public async loadContracts(customer: Customer, page: number = 1, resultsPerPage: number = AppConstants.defaultResultsPerPage): Promise<PaginatedData<Contract>> {
		const postData = {
			"pagination": {
				"page": page,
				"resultsPerPage": resultsPerPage
			}
		};

		const response = await this.restService.post(API.customers, `customer/${customer.id}/contracts`, postData);
		return {
			"data": response.data.map(Contract.parse),
			"totalItems": response.count,
			"pageSize": response.pageSize
		};
	}

	public async loadProducts(customer: Customer, page: number = 1, resultsPerPage: number = AppConstants.defaultResultsPerPage): Promise<PaginatedData<ContractProduct>> {
		const postData = {
			"pagination": {
				"page": page,
				"resultsPerPage": resultsPerPage
			}
		};

		const response = await this.restService.post(API.customers, `customer/${customer.id}/products`, postData);
		return {
			"data": response.data.map(ContractProduct.parse),
			"totalItems": response.count,
			"pageSize": response.pageSize
		};
	}

	public async loadAccounts(customer: Customer, page: number = 1, resultsPerPage: number = AppConstants.defaultResultsPerPage): Promise<PaginatedData<User>> {
		const postData = {
			"pagination": {
				"page": page,
				"resultsPerPage": resultsPerPage
			}
		};

		const response = await this.restService.post(API.customers, `customer/${customer.id}/accounts`, postData);
		return {
			"data": response.data.map(User.parse),
			"totalItems": response.count,
			"pageSize": response.pageSize
		};
	}

	/**
	* Copied implementation from VisicodeService.getSurgeries.
	* There's a reasonable argument for moving the lambda endpoint to the customers service,
	* but will leave things as they are for the moment.
	*/
	public async getSurgeries(customerId: string): Promise<Surgery[]> {
		const key = `surgeries_${customerId}`;
		const cached = await this.storage.general.get(key);
		if (Array.isArray(cached) && cached.length > 0) {
			return cached.map( Surgery.parse ) as Surgery[];
		}

		const response = await this.restService.get(API.customers, `surgeries/${customerId}`);
		const surgeries: Surgery[] = response.map( Surgery.parse );
		await this.storage.general.set( key, surgeries.map(item => item.json) );
		return surgeries;
	}

	private async fetchSurgery(surgeryId: string): Promise<Surgery> {
		const response = await this.restService.get(API.customers, `surgery/${surgeryId}`);
		const surgery: Surgery = Surgery.parse(response);
		await this.storage.general.set(surgeryId, surgery.json);
		return surgery;
	}

	public async getSurgery(surgeryId: string): Promise<Surgery> {
		const cached = await this.storage.general.get(surgeryId);
		if (hasValue(cached) && cached._id === surgeryId) {
			return Surgery.parse(cached);
		}

		const result = await this._entityQueue.enqueue(surgeryId, this.fetchSurgery.bind(this, surgeryId));
		return result;
	}

	private async fetchDisciplines(cacheKey: string) {
		const response = await this.restService.get(API.visicode, 'disciplines');
		const disciplines: NamedEntity[] = response.map(NamedEntity.parse);
		await this.storage.nonVolatile.set(cacheKey, disciplines.map(item => item.json));
		return disciplines;
	}

	public async getDisciplines(): Promise<NamedEntity[]> {
		const cacheKey = "disciplines";
		const cached = await this.storage.nonVolatile.get(cacheKey);
		if (Array.isArray(cached) && cached.length > 0) {
			return cached.map( NamedEntity.parse ) as NamedEntity[];
		}

		return this._waitQueue.enqueue(cacheKey, this.fetchDisciplines.bind(this, cacheKey) );
	}

	public async saveSurgery(surgery: Surgery, customer: Customer, fydoApi?: FydoAPI): Promise<Surgery> {
		const promises = [this.restService.post(API.customers, 'surgery', {"surgery": surgery.json})];
		if (!surgery.isNew && hasValue(fydoApi)) {
			promises.push(this.restService.post(API.fydo, 'surgery', {"fydo": fydoApi.json}));
		}

		const [response,]: any[] = await Promise.all(promises);
		const result = Surgery.parse(response);
		assertHasValue(result, "Surgery not found");

		// Save surgery to cache
		result.address = result.address || Address.blank();
		await this.storage.general.set(result.id, result.json);

		// Add (or replace) the surgery on the customer's list of surgeries
		if (surgery.isNew) {
			customer.surgeries.push(NamedEntity.parse(response));
		}
		else {
			const oldSurgery = customer.surgeries.find(item => item.id === result.id);
			if (hasValue(oldSurgery)) {
				oldSurgery.name = result.name;
			}
		}

		// Save the list of surgeries to indexedDB (this is used by UserAdminService when
		// selecting access for users)
		const key = `surgeries_${customer.id}`;
		await this.storage.general.set(key, customer.surgeries.map(item => item.json));

		// Save the customer back to cache
		await this.storage.general.set(customer.id, customer.json);

		return result;
	}

	// public async getFYDOSettings(surgeryId: string): Promise<FydoAPI|undefined> {
	// 	const data = await this.restService.get(API.fydo, `settings/${surgeryId}`);
	// 	return hasValue(data) ? FydoAPI.parse(data) : undefined;
	// }
}
