<ng-container *ngIf="native">
	<input
		#nativeDate
		[attr.disabled]="disabled || undefined"
		[attr.readonly]="readonly || undefined"
		[attr.class]="!!classList ? classList : undefined"
		[attr.id]="!!id ? id : undefined"
		type="date"
		[attr.name]="!!name ? name : undefined"
		[attr.min]="!!min ? min : undefined"
		[attr.max]="!!max ? max : undefined"
		[(ngModel)]="nativeModel"
		(blur)="setTouched($event)" />
</ng-container>

<ng-container *ngIf="!native">
	<ng-container *ngIf="format === dateFormats.uk">
		<div [attr.class]="!!classList ? classList : undefined" [attr.disabled]="disabled || undefined" (click)="focusDay()">
			<ng-container *ngTemplateOutlet="day"></ng-container>
			<span>/</span>
			<ng-container *ngTemplateOutlet="month"></ng-container>
			<span>/</span>
			<ng-container *ngTemplateOutlet="year"></ng-container>
		</div>
	</ng-container>

	<ng-container *ngIf="format === dateFormats.us">
		<div [attr.class]="!!classList ? classList : undefined">
			<ng-container *ngTemplateOutlet="month"></ng-container>
			<span>/</span>
			<ng-container *ngTemplateOutlet="day"></ng-container>
			<span>/</span>
			<ng-container *ngTemplateOutlet="year"></ng-container>
		</div>
	</ng-container>

	<ng-container *ngIf="format === dateFormats.iso">
		<div [attr.class]="!!classList ? classList : undefined">
			<ng-container *ngTemplateOutlet="year"></ng-container>
			<span>/</span>
			<ng-container *ngTemplateOutlet="month"></ng-container>
			<span>/</span>
			<ng-container *ngTemplateOutlet="day"></ng-container>
		</div>
	</ng-container>
</ng-container>

<ng-template #day>
	<input type="text" (focusout)="focusOut($event)" [disabled]="disabled" [readonly]="readonly" autocomplete="off" maxlength="2" size="2" name="d{{instanceCount}}" [(ngModel)]="dayString" placeholder="DD" (input)="onInput($event)" (keydown)="onKeyDown($event)" #dayInput />
</ng-template>

<ng-template #month>
	<input type="text" (focusout)="focusOut($event)" [disabled]="disabled" [readonly]="readonly" autocomplete="off" maxlength="2" size="2" name="m{{instanceCount}}" [(ngModel)]="monthString" placeholder="MM" (input)="onInput($event)" (keydown)="onKeyDown($event)" #monthInput />
</ng-template>

<ng-template #year>
	<input type="text" (focusout)="focusOut($event)" [disabled]="disabled" [readonly]="readonly" autocomplete="off" maxlength="4" size="4" name="y{{instanceCount}}" [(ngModel)]="yearString" placeholder="YYYY" (input)="onInput($event)" (keydown)="onKeyDown($event)" #yearInput />
</ng-template>
