import { AmplifyService } from "aws-amplify-angular";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { OfflineAuthService } from "@services/offlineAuth.service";
import { User } from "@classes/user";
import { SessionStorageService } from "@services/sessionStorage.service";
import { StringUtils, hasValue, assertHasValue } from "@utils";
import { DialogService } from "@services/dialog.service";
import { NotificationService } from "@services/notification.service";
import { ENotAuthorised } from "@classes/errors";
import { BehaviorSubject } from "rxjs";
import { APIClass } from '@aws-amplify/api';
import { API } from '@classes/api';
import { Permissions } from "@classes/permissions";
import { config } from "@root/config";
import { Auth } from "@aws-amplify/auth";

/**
* Copied definition from node_modules/aws-amplify-angular/dist/src/providers/auth.state.d.ts
* since it's not easily accessible without including pre-packaged UI components
*/
interface AuthState {
	state: string;
	user: any;
}

export type MFAType = "NOMFA" | "SOFTWARE_TOKEN_MFA" | "SMS";

@Injectable({ "providedIn": "root" })
export class AuthService {

	private static defaultLoginFailureMessage = "Incorrect username or password.";

	private _currentAuthState: string = "";
	private _signedIn = false;
	private _user: User|undefined = undefined;
	private _api: APIClass;
	private _awsAuthUser: any;
	private _maintenanceMode: boolean = false;

	public get isLoggedIn(): boolean {
		return this._signedIn;
		// return this._signedIn && this._user !== undefined;
	}

	public get currentUser(): User|undefined {
		return this._user;
	}

	private get passwordChangeRequired(): boolean {
		return this._currentAuthState === "requireNewPassword";
	}

	public readonly userLoaded$: BehaviorSubject<boolean|undefined> = new BehaviorSubject<boolean|undefined>(undefined);

	public async logout() {

		// SessionStorageService.clear();
		// await Promise.all([StorageService.entities.clear(), StorageService.general.clear()]);

		await this.amplifyService.auth().signOut();
		this._user = undefined;
		this._awsAuthUser = undefined;
		this._signedIn = false;
		this.router.navigate(["/"]);
		this.userLoaded$.next(false);

		// Reload the window to prevent weird behaviour when logging into a different account
		setTimeout( () => { window.location.reload(); }, 200);
	}

	private async currentAuthUser() {
		try {
			const auth = this.amplifyService.auth();
			await auth.currentAuthenticatedUser();
		}
		catch (err) {
			console.log(err);
		};
	}

	constructor(
		private amplifyService: AmplifyService,
		private offlineAuthService: OfflineAuthService,
		private router: Router) {

		this._api = amplifyService.api();
		this.amplifyService.authStateChange$.subscribe(this.authSubscription.bind(this));

		this.currentAuthUser();
	}

	public get usersHomePage(): string {
		if (!this._user) {
			return "/";
		}

		return "/user/dashboard";
	}

	private async loadUser(_cognitoUser: any, redirectPath?: string | string[]): Promise<void> {

		DialogService.show(`Loading your account${StringUtils.ellipsis}`);

		if (this.offlineAuthService.isOffline) {
			const auth = this.amplifyService.auth();
			const authInfo = await auth.currentUserInfo();
			this.offlineAuthService.setIdentity(authInfo.id);
		}

		try {
			this._user = await this.fetchUser();

			const defaultPath = [ this.usersHomePage ];
			redirectPath = redirectPath ?? defaultPath;
			redirectPath = Array.isArray(redirectPath) ? redirectPath : [ redirectPath ];

			DialogService.hide();
			this._signedIn = true;

			if (this.router.url !== redirectPath.join("/")) {
				this.router.navigate(redirectPath);
			}
			this.userLoaded$.next(true);

		}
		catch (err) {
			DialogService.hide();
			this._signedIn = false;
			this._user = undefined;
			console.log(err);
		}
	}

	/**
	* Fetch the user from the remote server.
	* Implemented here instead of using "user.service.ts" to avoid a circular dependancy problem.
	* When fetching the currently logged in user, the server response also contains the permissions list
	* which we use to initialise the "Permissions" helper class.
	*
	* @return {Promise} A promise that resolves to the currently logged in user.
	*/
	private async fetchUser(): Promise<User|undefined> {
		try {

			let requestOptions: any = {};
			if (this.offlineAuthService.isOffline) {
				requestOptions.headers = {};
				requestOptions.headers['x-identityId'] = this.offlineAuthService.getIdentity();
			}

			const response = await this._api.get(API.toString(API.system), "user", requestOptions);
			Permissions.init(response.permissions);
			this._maintenanceMode = !!response.maintenanceMode;
			return User.parse(response.user);
		}
		catch (e) {
			console.log(e);
			return Promise.reject(e);
		}
	}

	/**
	* Auth state subscription handler.
	* - Forces users to the "change password" page if Cognito requires it.
	* - Loads the user's data if they are logged in.
	*/
	private async authSubscription(authState: AuthState) {

		this._currentAuthState = authState.state;

		switch (this._currentAuthState) {
			case "requireNewPassword":

				DialogService.hide();
				this.router.navigate(['/password/change']);
				return;

			case "confirmSignIn":
				this._awsAuthUser = authState.user;
				this.router.navigate(['/totp']);
				return;

			case "signedIn":

				this._awsAuthUser = authState.user;
				await this.loadUser(authState.user, SessionStorageService.get("path", true));
				return;

			default:

				this.userLoaded$.next(false);
				this._signedIn = false;
				this._user = undefined;
		}
	}

	public async login(username: string, password: string): Promise<void> {

		username = StringUtils.trim(username?.toLowerCase()) || "";
		password = StringUtils.trim(password) || "";

		DialogService.show(`Logging in${StringUtils.ellipsis}`);
		try {
			await this.amplifyService.auth().signIn(username, password);
		}
		catch (err: any) {
			switch (err.code) {

				case "PasswordResetRequiredException":
					// If a user's password has been reset by an administrator, when they next try to log in
					// they should be redirected to the "Password Reset" page.
					this.router.navigate(['/password/reset']);
					break;

				default:
					// Hide other error messages from the end user, and present a generic "Unable to log in"
					// const msg = err.message || AuthService.defaultLoginFailureMessage;
					const msg = AuthService.defaultLoginFailureMessage;
					NotificationService.error("Unable to log in", msg);
			}

			return Promise.reject();
		}
		finally {
			DialogService.hide();
		}
		// return this.amplifyService.auth().signIn(username, password).catch( (err: any) => {

		// 	switch (err.code) {

		// 		case "PasswordResetRequiredException":
		// 			// If a user's password has been reset by an administrator, when they next try to log in
		// 			// they should be redirected to the "Password Reset" page.
		// 			this.router.navigate(['/password/reset']);
		// 			break;

		// 		default:
		// 			// Hide other error messages from the end user, and present a generic "Unable to log in"
		// 			// const msg = err.message || AuthService.defaultLoginFailureMessage;
		// 			const msg = AuthService.defaultLoginFailureMessage;
		// 			NotificationService.error("Unable to log in", msg);
		// 	}

		// 	return Promise.reject();

		// }).finally( () => {
		// 	DialogService.hide();
		// });
	}

	public forgotPassword(username: string): Promise<void> {
		return this.amplifyService.auth().forgotPassword(username).then();
	}

	public forgotPasswordSubmit(username: string, resetCode: string, newPassword: string): Promise<void> {
		return this.amplifyService.auth().forgotPasswordSubmit(username, resetCode, newPassword).then();
	}

	public async changePassword(oldPwd: string, newPwd: string) {
		const auth = this.amplifyService.auth();

		// Handle the (rare) cases when a user has been created in the console.
		// These do not require an "old" password to be sent
		if (this.passwordChangeRequired) {
			await auth.completeNewPassword(this._awsAuthUser, newPwd, null);
			return;
		}

		try {
			await auth.changePassword(this._awsAuthUser, oldPwd, newPwd);
		}
		catch (e) {

			if (e.name === "NotAuthorizedException") {
				throw new ENotAuthorised();
			}

			throw e;
		}
	}

	public async setupTOTP(): Promise<string> {
		return this.amplifyService.auth().setupTOTP(this._awsAuthUser);
	}

	public async verifyTotpToken(challengeResponse: string): Promise<boolean> {
		const auth = this.amplifyService.auth();

		try {
			await auth.verifyTotpToken(this._awsAuthUser, challengeResponse);
			await auth.setPreferredMFA(this._awsAuthUser, 'TOTP');
			return true;
		}
		catch (e) {
			return false;
		}
	}

	public async confirmTOTP(totp: string): Promise<boolean> {
		const auth = this.amplifyService.auth();
		try {
			assertHasValue(this._awsAuthUser);
			await auth.confirmSignIn(this._awsAuthUser, totp, "SOFTWARE_TOKEN_MFA");
			await this.loadUser(this._awsAuthUser, SessionStorageService.get("path", true));
			return true;
		}
		catch (err) {
			console.log(err);
			return false;
		}
	}

	public async getPreferredMFA(bypassCache: boolean = true): Promise<MFAType> {
		const session = await Auth.currentSession();
		const user = await Auth.currentAuthenticatedUser();
		if (!user || !session) {
			throw new Error("Session not valid");
		}
		return this.amplifyService.auth().getPreferredMFA(user, {"bypassCache": bypassCache});
	}

	public async removeMFA(): Promise<boolean> {
		const auth = this.amplifyService.auth();

		try {
			assertHasValue(this._awsAuthUser);
			await auth.setPreferredMFA(this._awsAuthUser, 'NOMFA');
			return true;
		}
		catch (e) {
			return false;
		}
	}

	public readonly issuer = config.authIssuer;

	public get hasAWSAuthUser(): boolean {
		return hasValue(this._awsAuthUser);
	}

	public get maintenanceMode(): boolean {
		return this._maintenanceMode;
	}
}
