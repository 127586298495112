import { Component, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { ContractStatus } from "@classes/contract";
import { CustomerStatus } from "@classes/customer";
import { GenericListFilter, GenericListStatusCount } from "@classes/genericListFilter";
import { DateProxy } from "@classes/dateProxy";

@Component({
	"selector": 'generic-list-filter',
	"templateUrl": './genericListFilter.component.html',
	"styleUrls": ['./genericListFilter.component.scss']
})
export class GenericListFilterComponent extends PrivateComponent {

	// <region> Private ----------------------------------------------------------
	@ViewChild('dropdown')
	private dropdown!: ElementRef<any>;

	private _showDateFrom: boolean = true;
	private _showDateTo: boolean = true;
	private _showQuery: boolean = true;
	private _showContractStatus: boolean = false;
	private _showCustomerStatus: boolean = false;
	//private _showIncludeDeleted: boolean = true;
	private _contractStatuses: Set<GenericListStatusCount> = new Set<GenericListStatusCount>;
	private _customerStatuses: Set<GenericListStatusCount> = new Set<GenericListStatusCount>;

	private _searchModel: GenericListFilter = GenericListFilter.blank();
	private _contractStatusDropdownActive: boolean = false;
	private _customerStatusDropdownActive: boolean = false;
	// </region>

	// <region> Initialisation
	constructor() {
		super();
	}

	protected ready() {}
	// </region>


	// <region> Getters and setters ----------------------------------------------
	public get searchModel(): GenericListFilter {
		return this._searchModel;
	}

	public get contractStatusDropdownActive(): boolean {
		return this._contractStatusDropdownActive;
	}

	public set contractStatusDropdownActive(value: boolean) {
		this._contractStatusDropdownActive = value;
	}

	public get customerStatusDropdownActive(): boolean {
		return this._customerStatusDropdownActive;
	}

	public set customerStatusDropdownActive(value: boolean) {
		this._customerStatusDropdownActive = value;
	}

	public get filterIsEmpty(): boolean {
		return GenericListFilter.isEmpty(this._searchModel, !this.showDateFrom || !this.showDateTo, !this.showContractStatus, !this.showCustomerStatus);
	}
	// </region>

	// <region> Public -----------------------------------------------------------
	@Input()
	public set filter(value: GenericListFilter) {
		this._searchModel = value;
		this.dateFromProxy = DateProxy.Attached(this._searchModel, 'dateFrom');
		this.dateToProxy = DateProxy.Attached(this._searchModel, 'dateTo');
	}

	@Input()
	public set showDateFrom(value: boolean) {
		this._showDateFrom = value;
	}

	public get showDateFrom(): boolean {
		return this._showDateFrom;
	}

	@Input()
	public set showDateTo(value: boolean) {
		this._showDateTo = value;
	}

	public get showDateTo(): boolean {
		return this._showDateTo;
	}

	@Input()
	public set showQuery(value: boolean) {
		this._showQuery = value;
	}

	public get showQuery(): boolean {
		return this._showQuery;
	}

	@Input()
	public set showContractStatus(value: boolean) {
		this._showContractStatus = value;
	}

	public get showContractStatus(): boolean {
		return this._showContractStatus;
	}

	@Input()
	public set contractStatuses(value: Set<GenericListStatusCount>) {
		this._contractStatuses = value;
	}

	
	@Input()
	public set showCustomerStatus(value: boolean) {
		this._showCustomerStatus = value;
	}

	public get showCustomerStatus(): boolean {
		return this._showCustomerStatus;
	}

	@Input()
	public set customerStatuses(value: Set<GenericListStatusCount>) {
		this._customerStatuses = value;
	}

	@Output('onFilterChange')
	public filterChanged = new EventEmitter<GenericListFilter>();

	public allContractStatuses: () => ContractStatus[] = ContractStatus.allValues;
	public contractStatusName (contractStatus: ContractStatus): string {
		let statusCount = 0;
		if (this._contractStatuses) {
			this._contractStatuses.forEach(contractStatusCount => {
				if (ContractStatus.toPostgresEnum(contractStatus) === contractStatusCount.status) {
					statusCount = contractStatusCount.count;
				}
			});
		}
		return `(${statusCount}) ${ContractStatus.toString(contractStatus)}`;
	}
	
	public allCustomerStatuses: () => CustomerStatus[] = CustomerStatus.allValues;
	public customerStatusName (customerStatus: CustomerStatus): string {
		let statusCount = 0;
		if (this._customerStatuses) {
			this._customerStatuses.forEach(customerStatusCount => {
				if (CustomerStatus.toPostgresEnum(customerStatus) === customerStatusCount.status) {
					statusCount = customerStatusCount.count;
				}
			});
		}
		return `(${statusCount}) ${CustomerStatus.toString(customerStatus)}`;
	}


	public queryChanged(): void {
		// Only trigger a search if this field has been cleared or is at least 3 characters
		if (!this._searchModel.query || this._searchModel.query.length === 0 || this._searchModel.query.length >= 3) {
			this.notify();
		}
	}

	public clearFilter(): void {

		if (this._showQuery) {
			this._searchModel.query = undefined;
		}

		if (this._showDateFrom) {
			this._searchModel.dateFrom = undefined;
			//this.dateFromProxy = undefined;
			//this.dateFromProxy.value = undefined;
		}

		if (this._showDateTo) {
			//this.dateToProxy = undefined;
			//this.dateToProxy.value = undefined;
			this._searchModel.dateTo = undefined;
		}

		if (this.showContractStatus) {
			if (this._searchModel.contractStatus) {
				this._searchModel.contractStatus.clear();
			}
		}

		if (this.showCustomerStatus) {
			if (this._searchModel.customerStatus) {
				this._searchModel.customerStatus.clear();
			}
		}
		this._searchModel = GenericListFilter.blank();

		this.notify();
	}

	public toggleContractStatus(status: ContractStatus): void {
		
		if (!this._searchModel.contractStatus) {
			// cater for undefined
			this._searchModel.contractStatus = new Set<ContractStatus>();
		}
		
		if (this._searchModel.contractStatus && this._searchModel.contractStatus.has(status)) {
			this._searchModel.contractStatus.delete(status);
		}
		else {
			if (this._searchModel.contractStatus) {
				this._searchModel.contractStatus.add(status);
			}
		}
		
		if (this._searchModel.contractStatus.size === 0) {
			// If it's now empty, set it to undefined as per the original state.
			this._searchModel.contractStatus = undefined;
		}
		this.notify();
	}

	public toggleCustomerStatus(status: CustomerStatus): void {
		
		if (!this._searchModel.customerStatus) {
			// cater for undefined
			this._searchModel.customerStatus = new Set<CustomerStatus>();
		}
		
		if (this._searchModel.customerStatus && this._searchModel.customerStatus.has(status)) {
			this._searchModel.customerStatus.delete(status);
		}
		else {
			if (this._searchModel.customerStatus) {
				this._searchModel.customerStatus.add(status);
			}
		}
		
		if (this._searchModel.customerStatus.size === 0) {
			// If it's now empty, set it to undefined as per the original state.
			this._searchModel.customerStatus = undefined;
		}
		this.notify();
	}

	@HostListener('window:click', ['$event'])
	windowClick(event: MouseEvent): void {

		if (this.dropdown?.nativeElement?.contains(event.target)) {
			return;
		}

		this._contractStatusDropdownActive = false;
		this._customerStatusDropdownActive = false;
	}

	public notify(): void {
		this.filterChanged.next(this._searchModel);
	}

	public clearQuery(): void {
		this._searchModel.query = undefined;
		this.notify();
	}

	public dateFromProxy: DateProxy | undefined;
	public dateToProxy: DateProxy | undefined;
	// </region>
}
