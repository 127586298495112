import { hasValue } from "@utils";
import { ServiceLocator } from '@classes/serviceLocator';
import { DBVersionService } from "@services/dbVersion.service";

interface PromiseResult {
	"resolve": (arg0: any) => any;
	"reject": () => any;
}

export interface StorageAPI {
	set: (values: any | any[]) => Promise<any>;
	get: (key: string|string[]) => Promise<any>;
	remove: (keyOrRange: string|IDBKeyRange) => Promise<void>;
	clear: () => Promise<void>;
	query: (indexName: string, keyRange: IDBKeyRange) => Promise<any[]|undefined>;
	all: () => Promise<any[]>;
}

export interface KeyStorageAPI {
	set: (keys: string | string[], values: any | any[]) => Promise<any>;
	get: (key: string) => Promise<any>;
	remove: (keyOrRange: string|IDBKeyRange) => Promise<void>;
	clear: () => Promise<void>;
	all: () => Promise<any[]>;
}

/**
* IndexDB Storage wrapper
* Extend this class to create indexedDB storage containers
* eg @see storage.service.ts
*/
export abstract class AbstractStorage {

	private connectionRequest: IDBOpenDBRequest|undefined;
	private versionService: DBVersionService;

	protected db: IDBDatabase|undefined;

	public abstract tableNames: string[];
	public abstract dbName: string;

	private getVersionNumber(): Promise<number> {
		return this.versionService.getDBVersion(this.dbName);
	}

	protected constructor() {
		if (!('indexedDB' in window)) {
			throw new Error("Your browser doesn't support indexedDB");
		}

		this.versionService = ServiceLocator.injector.get(DBVersionService);
	};

	private connectQueue: PromiseResult[] = [];

	protected onUpgradeNeeded(event: any) {
		const db = event.target.result;

		this.tableNames.forEach( objectStoreName => {
			try {
				db.deleteObjectStore(objectStoreName);
			}
			catch (e) {
				// Ignore - probably didn't exist in the first place
			}
		});

		this.createObjectStores(db);
	}

	protected abstract createObjectStores(db: IDBDatabase): void;

	private init(): Promise<IDBDatabase> {

		if (this.db) {
			return Promise.resolve(this.db);
		}

		return new Promise<IDBDatabase>(async (resolve, reject) => {

			this.connectQueue.push({"resolve": resolve, "reject": reject});

			if (this.connectQueue.length > 1) {
				return;
			}

			const dbVersion = await this.getVersionNumber();
			console.info(`${this.dbName}: DB Version = ${dbVersion}`);
			this.connectionRequest = indexedDB.open(this.dbName, dbVersion);
			this.connectionRequest.onupgradeneeded = this.onUpgradeNeeded.bind(this);

			this.connectionRequest.onsuccess = () => {
				if (hasValue(this.connectionRequest)) {
					this.db = this.connectionRequest.result;
					this.connectionRequest = undefined;
					while (this.connectQueue.length) {
						const promise = this.connectQueue.shift();
						if (promise !== undefined) {
							promise.resolve(this.db);
						}
					}
				}
			}
		});
	}

	public clear(objectStore: string): Promise<void> {
		return new Promise(async resolve => {
			try {
				const db = await this.init();
				const transaction = db.transaction(objectStore, "readwrite");
				const store = transaction.objectStore(objectStore);
				transaction.oncomplete = () => { resolve(); };
				store.clear();
			}
			catch (e) {
				console.log(e);
				resolve();
			}
		});
	}

	public keyset(objectStore: string, keys: string | string[], values: any | any[]): Promise<any> {

		const _keys = Array.isArray(keys) ? [...keys] : [keys];
		const _values = Array.isArray(keys) ? [...values] : [values];
		const singleItem = _keys.length === 1;

		if (_keys.length !== _values.length) {
			return Promise.reject("Number of keys does not match number of values");
		}

		return new Promise(async resolve => {
			const completed = () => { resolve(values); };

			try {
				const db = await this.init();
				const transaction = db.transaction(objectStore, "readwrite");
				const store = transaction.objectStore(objectStore);

				if (singleItem) {
					store.put( _values.pop(), _keys.pop()).onsuccess = completed;
				}
				else {
					transaction.oncomplete = completed
					while (_values.length > 0) {
						store.put( _values.pop(), _keys.pop());
					}
				}
			}
			catch (e) {
				console.log(`Error writing to object store ${objectStore}`);
				console.error(e);
				resolve(undefined);
			}
		});
	}

	public set(objectStore: string, values: any | any[]): Promise<any> {

		const _values = Array.isArray(values) ? [...values] : [values];
		const numItems = _values.length;
		const singleItem = numItems === 1;

		return new Promise(async resolve => {
			const completed = () => { resolve(values); };

			try {
				const db = await this.init();
				const transaction = db.transaction(objectStore, "readwrite");
				const store = transaction.objectStore(objectStore);

				if (singleItem) {
					store.put( _values.pop() ).onsuccess = completed;
				}
				else {
					transaction.oncomplete = completed;

					while (_values.length > 0) {
						store.put( _values.pop() );
					}
				}
			}
			catch (e) {
				console.error(`Error writing to object store ${objectStore}`);
				console.error(values);
				console.error(e);
				resolve(values);
			}
		});
	}

	public query(objectStore: string, indexName: string, keyRange: IDBKeyRange): Promise<any[]|undefined> {

		return new Promise(async resolve => {
			try {
				const db = await this.init();
				const transaction = db.transaction(objectStore, "readonly");
				const store = transaction.objectStore(objectStore);
				const request = store.index(indexName).getAll(keyRange);
				request.onsuccess = () => { resolve(request.result); };
			}
			catch (e) {
				console.log(e);
				resolve(undefined);
			}
		});
	}

	public remove(objectStore: string, keyOrRange: string|IDBKeyRange): Promise<void> {

		return new Promise(async (resolve, reject) => {
			try {
				const db = await this.init();
				const transaction = db.transaction(objectStore, "readwrite");
				const entitiesStore = transaction.objectStore(objectStore);
				const request = entitiesStore.delete(keyOrRange);
				request.onsuccess = () => { resolve(); };
			}
			catch (e) {
				reject(e); // Ignores any errors. Not sure this
			}
		});
	}

	public async all(objectStore: string): Promise<any[]> {

		return new Promise(async (resolve, reject) => {
			try {
				const db = await this.init();
				const transaction = db.transaction(objectStore, "readonly");
				const entitiesStore = transaction.objectStore(objectStore);
				const request = entitiesStore.getAll();
				request.onsuccess = () => { resolve(request.result); };
			}
			catch (e) {
				reject(e); // Ignores any errors. Not sure this
			}
		});
	}

	public get(objectStore: string, key: string|string[]): Promise<any> {

		// Make sure input is an array, and filter out any falsey values
		const keyValues = Array.isArray(key) ? key : [key].filter(item => !!item);

		if (keyValues.length < 1) {
			// handed an empty key or set of keys, so don't try to return anything
			return Promise.reject();
		}

		return new Promise(async resolve => {
			try {
				const db = await this.init();
				const transaction = db.transaction(objectStore, "readonly");
				const entitiesStore = transaction.objectStore(objectStore);

				const keyValues = Array.isArray(key) ? key : [key];

				const promises = keyValues.map(keyValue => {
					return new Promise(async resolve => {
						const request = entitiesStore.get(keyValue);
						request.onsuccess = () => { resolve(request.result); };
					});
				});

				const result = await Promise.all(promises);
				resolve(Array.isArray(key) ? result : result.shift());

				// const request = entitiesStore.get(key);
				// request.onsuccess = () => { resolve(request.result); };
			}
			catch (e) {
				resolve(undefined);
			}
		});
	}

	public bindObjectStoreToStorageAPI(objectStoreName: string): StorageAPI {
		return {
			"get": this.get.bind(this, objectStoreName),
			"set": this.set.bind(this, objectStoreName),
			"remove": this.remove.bind(this, objectStoreName),
			"clear": this.clear.bind(this, objectStoreName),
			"query": this.query.bind(this, objectStoreName),
			"all": this.all.bind(this, objectStoreName)
		};
	}

	public bindObjectStoreToKeyStorageAPI(objectStoreName: string): KeyStorageAPI {
		return {
			"get": this.get.bind(this, objectStoreName),
			"set": this.keyset.bind(this, objectStoreName),
			"remove": this.remove.bind(this, objectStoreName),
			"clear": this.clear.bind(this, objectStoreName),
			"all": this.all.bind(this, objectStoreName)
		};
	}

}

