import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({ "providedIn": "root" })
export class TitleService {

	// public static readonly defaultMenuSubject = "🚀 Elegant Audit";
	public static readonly defaultMenuSubject = "Resolutions Software Suite";

	constructor() {}

	public static readonly menuTitle: Subject<string>  = new BehaviorSubject<string>(TitleService.defaultMenuSubject);

	public static setMenuTitle(value: string) {
		TitleService.menuTitle.next(value);
	}
}
