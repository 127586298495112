import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";
import { User } from "@classes/user";

/**
* Angular route guard to prevent activating a route if the user is logged in. Basically used to stop
* the user seeing the "login" page if they are already logged in (and in which case, they will be redirected to their homepage)
*/
@Injectable()
export class NotLoggedInCanActivate implements CanActivate {

	constructor(private authService: AuthService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

		return new Promise<boolean>((resolve, reject) => {

			// Subscribe to the "userLoaded" observable on the authService.
			// Filter out the initial value (undefined)
			// Use the "first()" operator to automatically unsubsribe once a value has been received.
			this.authService.userLoaded$.pipe(filter(x => x !== undefined), first()).subscribe( userLoaded => {
				resolve(!userLoaded);
			});

		});
  }
}
