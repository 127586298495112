<nav class="breadcrumb is-small" aria-label="breadcrumbs" *ngIf="(this.breadcrumbs.length || 0) > 0">
	<ul>
		<ng-container *ngFor="let breadcrumb of breadcrumbs; index as i">
			<li [class.is-active]="i === breadcrumbs.length - 1">
			<ng-container *ngIf="i < breadcrumbs.length - 1">
				<a (click)="navigateTo(breadcrumb, $event)" [title]="url(breadcrumb)" [title]="breadcrumb.text">{{ breadcrumb.text }}</a>
			</ng-container>
			<ng-container *ngIf="i === breadcrumbs.length - 1">
				<a aria-current="page" [title]="breadcrumb.text">{{ breadcrumb.text }}</a>
			</ng-container>
		</ng-container>
	</ul>
</nav>
