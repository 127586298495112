import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Link } from "@classes/link";
import { AuthService } from "@services/auth.service";
import { HistoryService } from "@services/history.service";
import { Breadcrumbs } from "@classes/breadcrumbs";
import { Stage, config } from "@root/config";

@Component({
	"selector": "breadcrumbs",
	"styleUrls": ["./breadcrumbs.component.scss"],
	"templateUrl": "./breadcrumbs.component.html"
})
export class BreadcrumbsComponent {

	private _path?: Breadcrumbs;

	constructor(
		private historyService: HistoryService,
		private authService: AuthService,
		private location: Location,
		private router: Router) {}

	@Input()
	public noHome: boolean = false;

	@Input()
	public homeLinkText: string = 'Home';

	@Input()
	public set path(value: Breadcrumbs) {
		this._path = value;
	}

	public get breadcrumbs(): Link[] {
		return this._path?.path || [];
	}

	public navigateTo(link: Link, event: MouseEvent) {
		const pathSeparator = '/';
		const url = Array.isArray(link.url) ? link.url.join(pathSeparator) : link.url;

		const isBack = url === this.historyService.previousEventUrl;

		if (isBack) {
			this.location.back();
		}
		else {
			this.router.navigate(Array.isArray(link.url) ? link.url : [link.url]);
		}
	}

	/**
	* Useful for dev/debugging. Should be turned off for Production
	*/
	public url(link: Link): string {
		if (config.stage === Stage.prod) {
			return "";
		}

		const pathSeparator = '/';
		return Array.isArray(link.url) ? link.url.join(pathSeparator) : link.url;
	}
}
