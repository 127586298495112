<div class="container" *ngIf="!isLoggedIn">
	<login></login>
</div>

<div class="container" *ngIf="isLoggedIn">
	<div class="columns">
		<div class="column" *ngIf="hasPermission('audit', 'access') || hasPermission('resicat', 'access')">
			<div><ng-container *ngTemplateOutlet="audit"></ng-container></div>
			<div *ngIf="hasPermission('resicat', 'access')"><ng-container *ngTemplateOutlet="resicat"></ng-container></div>
			<div *ngIf="!hasPermission('resicat', 'access')"><ng-container *ngTemplateOutlet="resicatComingSoon"></ng-container></div>
			<div><ng-container *ngTemplateOutlet="icdx"></ng-container></div>
		</div>

		<div class="column" *ngIf="hasPermission('visicode', 'access')">
			<div><ng-container *ngTemplateOutlet="visicode"></ng-container></div>
			<div><ng-container *ngTemplateOutlet="visicodeApi"></ng-container></div>
			<div><ng-container *ngTemplateOutlet="visicodeEd"></ng-container></div>
		</div>
	</div>
</div>


<ng-template #audit>
	<a [link]="['audit', 'dashboard']">
		<div class="box">
			<article class="media">
				<figure class="media-left has-text-centered">
					<p class="image is-128x128 is-size-1 has-text-centered">✅</p>
				</figure>
				<div class="media-content">
					<div class="content">
						<p><strong>Audit Now</strong></p>
						<p>Audit any CSV dataset</p>
					</div>
				</div>
			</article>
		</div>
	</a>
</ng-template>

<ng-template #visicode>
	<a [link]="['visicode']">
		<div class="box">
			<article class="media">
				<figure class="media-left">
					<p class="image is-128x128">
						<img src="assets/visicodelogo.png" alt="Visicode Logo" />
					</p>
				</figure>
				<div class="media-content">
					<div class="content">
						<p><strong>VisiCODE</strong></p>
						<p>On-demand clinical coding application for day hospitals, small private hospitals and day units.</p>
					</div>
				</div>
			</article>
		</div>
	</a>
</ng-template>

<ng-template #visicodeApi>
	<a [link]="['visicode-api']" *ngIf="hasPermission('VisicodeAPI', 'Access')">
		<div class="box">
			<article class="media">
				<figure class="media-left">
					<p class="image is-128x128">
						<img src="assets/visicodeapi.png" alt="Visicode API Logo" />
					</p>
				</figure>
				<div class="media-content">
					<div class="content">
						<p><strong>VisiCODE API</strong></p>
						<p>Programmatic access to the VisiCODE coding engine.</p>
					</div>
				</div>
			</article>
		</div>
	</a>
	<div class="box disabled" *ngIf="!hasPermission('VisicodeAPI', 'Access')">
		<article class="media">
			<figure class="media-left">
				<p class="image is-128x128">
					<img src="assets/visicodeapi.png" alt="Visicode API Logo" />
				</p>
			</figure>
			<div class="media-content">
				<div class="content">
					<p><strong>VisiCODE API</strong></p>
					<p>Programmatic access to the VisiCODE coding engine.</p>
					<p class="help">Upgrade your account to access the Visicode API</p>
				</div>
			</div>
		</article>
	</div>
</ng-template>

<ng-template #visicodeEd>
	<div class="box disabled">
		<article class="media">
			<figure class="media-left">
				<p class="image is-128x128">
					<img src="assets/visicodeEd.png" alt="Visicode Ed Logo" />
				</p>
			</figure>
			<div class="media-content">
				<div class="content">
					<p><strong>VisiCODE Ed</strong></p>
					<p>Medical coding learning and education centre</p>
					<p class="help">Coming soon</p>
				</div>
			</div>
		</article>
	</div>
</ng-template>

<ng-template #resicat>
	<a [link]="['resicat']">
		<div class="box">
			<article class="media">
				<figure class="media-left">
					<p class="image is-128x128">
						<img src="assets/resicat_logo.png" alt="ResiCAT Logo" />
					</p>
				</figure>
				<div class="media-content">
					<div class="content">
						<p><strong>ResiCAT</strong></p>
						<p>Clinical coding audit tool</p>
					</div>
				</div>
			</article>
		</div>
	</a>
</ng-template>

<ng-template #resicatComingSoon>
	<div class="box">
		<article class="media">
			<figure class="media-left">
				<p class="image is-128x128">
					<img src="assets/resicat_logo.png" alt="ResiCAT Logo" />
				</p>
			</figure>
			<div class="media-content">
				<div class="content">
					<p><strong>ResiCAT</strong></p>
					<p>Clinical coding audit tool</p>
					<p class="help">Coming soon</p>
				</div>
			</div>
		</article>
	</div>
</ng-template>

<ng-template #icdx>
	<a [link]="['icd10', 'diseases']">
		<div class="box">
			<article class="media">
				<figure class="media-left">
					<p class="image is-128x128">
						<img src="assets/icd10.png" alt="ICD-X Logo" />
					</p>
				</figure>
				<div class="media-content">
					<div class="content">
						<p><strong>ICD-X</strong></p>
						<p>ICD10 Explorer</p>
					</div>
				</div>
			</article>
		</div>
	</a>
</ng-template>
