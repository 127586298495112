import { Component } from "@angular/core";
import { DialogService } from "@services/dialog.service";
import { WebSocketService } from "@services/websocket.service";
import { AuthService } from "@services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public readonly title: string = 'Auditor';
  public readonly date: Date = new Date();

  /**
  * Inject the websocket service so that we create an instance at startup.
  * Websocket won't connect until the user has logged in.
  */
  constructor(
  	private readonly authService: AuthService,
  	private readonly websocketService: WebSocketService) {}

	public get dialogVisible(): boolean {
		// return false;
		return DialogService.visible;
	}

	public get isMaintenanceMode(): boolean {
		return this.authService.maintenanceMode;
	}
}
