import { assertHasValue } from "@utils";

export class DBVersion {
	protected constructor(public readonly dbName: string, public readonly version: number) {}

	public static parse(src: any): DBVersion {
		assertHasValue(src.dbName);
		assertHasValue(src.dbVersion);
		return new DBVersion(src.dbName, src.dbVersion);
	}
}
