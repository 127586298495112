<div class="field is-horizontal">
	<div class="field-label is-normal" *ngIf="fieldLabel !== undefined">
		<label class="label is-normal" for="field{{ instanceId }}">{{ fieldLabel }}</label>
	</div>
	<div class="field-body">
		<ng-container [ngSwitch]="fieldType">
			<ng-container *ngSwitchCase="'checkbox'" [ngTemplateOutlet]="checkboxTemplate"></ng-container>
			<ng-container *ngSwitchCase="'select'" [ngTemplateOutlet]="selectboxTemplate"></ng-container>
			<ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplate"></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #checkboxTemplate>
	<div class="field is-narrow">
		<div class="control mt-1">
			<input type="checkbox" id="field{{ instanceId }}" [formControl]="control" [value]="true" />
		</div>
	</div>
</ng-template>

<ng-template #selectboxTemplate>
	<div class="field">
		<div class="control is-expanded has-icons-right">
			<div class="select is-fullwidth">
				<select id="field{{ instanceId }}" name="field{{ instanceId }}" [formControl]="control" [attr.compareWith]="!!compareByFunc ? compareByFunc : null">
					<option *ngFor="let option of optionList" [ngValue]="option.value" [attr.disabled]="option.disabled || undefined">{{ option.label }}</option>
				</select>
			</div>
			<span class="icon is-right" *ngIf="showInvalid === dirty && !control.pristine && control.invalid"><fa-icon class="has-text-danger" [icon]="['fas', 'exclamation-triangle']"></fa-icon></span>
			<span class="icon is-right" *ngIf="showInvalid === always"><fa-icon class="has-text-danger" [icon]="['fas', 'exclamation-triangle']"></fa-icon></span>
		</div>
	</div>
</ng-template>

<ng-template #defaultTemplate>
	<div class="field">
		<div class="control is-expanded has-icons-right">
			<ng-container [ngSwitch]="fieldType">
				<textarea [attr.readonly]="readonly || null" [placeholder]="placeholder" *ngSwitchCase="'textarea'" [formControl]="control" id="field{{ instanceId }}" class="textarea"></textarea>
				<input [attr.readonly]="readonly || null" [placeholder]="placeholder" *ngSwitchCase="'phone'" type="phone" [formControl]="control" id="field{{ instanceId }}" class="input is-normal" auphone />
				<input [attr.readonly]="readonly || null" [placeholder]="placeholder" *ngSwitchCase="'mobile'" type="mobile" [formControl]="control" id="field{{ instanceId }}" class="input is-normal" aumobile />
				<input [attr.readonly]="readonly || null" [placeholder]="placeholder" *ngSwitchCase="'email'" type="email" [formControl]="control" id="field{{ instanceId }}" class="input is-normal" />
				<input [attr.readonly]="readonly || null" [placeholder]="placeholder" *ngSwitchCase="'date'" type="date" [formControl]="control" id="field{{ instanceId }}" class="input is-normal" />
				<input [attr.readonly]="readonly || null" [placeholder]="placeholder" *ngSwitchCase="'text'" type="text" [formControl]="control" id="field{{ instanceId }}" class="input is-normal" />
			</ng-container>
			<ng-container *ngIf="fieldType === 'typeahead' && !!template && !!typeaheadTextChanged">
				<input
					#typeaheadField
					type="text"
					id="field{{ instanceId }}"
					class="input is-normal"
					typeahead
					[taItemTpl]="template"
					[taDisplayOnFocus]="displayOnFocus"
					(taSelected)="typeaheadValueSelected($event)"
					(input)="typeaheadTextChanged($event, typeaheadField.nativeElement.value)"
					[value]="typeaheadSelectedText ?? typeaheadField.nativeElement.value"
					[taList]="typeaheadListItems" />
			</ng-container>
			<span class="icon is-right" *ngIf="showInvalid === dirty && !control.pristine && control.invalid"><fa-icon class="has-text-danger" [icon]="['fas', 'exclamation-triangle']"></fa-icon></span>
			<span class="icon is-right" *ngIf="showInvalid === always && control.invalid"><fa-icon class="has-text-danger" [icon]="['fas', 'exclamation-triangle']"></fa-icon></span>
		</div>
	</div>
</ng-template>
