export type EnumMetadata<T> = [T, string, string] | [T, string, string, boolean]; // Enum, description, pgEnum, hidden

export namespace EnumMetadata {
	export function valueMap<T>(meta: EnumMetadata<T>[]): Map<string, T> {
		return meta.reduce( (acc, cur) => {
			acc.set(cur[2], cur[0]);
			return acc;
		}, new Map<string, T>());
	}

	export function pgEnumMap<T>(meta: EnumMetadata<T>[]): Map<T, string> {
		return meta.reduce( (acc, cur) => {
			acc.set(cur[0], cur[2]);
			return acc;
		}, new Map<T, string>());
	}

	export function descriptionMap<T>(meta: EnumMetadata<T>[]): Map<T, string> {
		return meta.reduce( (acc, cur) => {
			acc.set(cur[0], cur[1]);
			return acc;
		}, new Map<T, string>());
	}

	export function allValues<T>(meta: EnumMetadata<T>[]): T[] {
		return meta.filter(item => item[3] !== true).map( item => item[0] );
	}
}
