<div class="tabs" [class.horizontal]="horizontal">
	<ul>
		<ng-container *ngFor="let tab of allTabs">
			<li *ngIf="displayTab(tab)" [class.is-active]="currentTab.equals(tab)" [class.disabled]="!tab.enabled">
				<ng-container *ngIf="!currentTab.equals(tab)">
					<a (click)="currentTab = tab">{{ tab.displayName }}</a>
				</ng-container>
				<ng-container *ngIf="currentTab.equals(tab)">
					<a>{{ tab.displayName }}</a>
				</ng-container>
			</li>
		</ng-container>
	</ul>
</div>
