import { Component, Input, TemplateRef } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ListItem } from "@classes/listItem";
import { ShowInvalid } from "@classes/fieldInvalidStatus";

type FormFieldType = "text" | "email" | "phone" | "mobile" | "textarea" | "typeahead" | "date" | "checkbox" | "select";

@Component({
	"selector": "form-field[control][fieldType]",
	"styleUrls": ["./formField.component.scss"],
	"templateUrl": "./formField.component.html"
})
export class FormFieldComponent {
	private static _instanceId: number = 0;

	public readonly instanceId: number = ++FormFieldComponent._instanceId;

	constructor() {}

	@Input()
	public fieldLabel: string|undefined = undefined;

	@Input()
	public placeholder: string = "";

	@Input()
	public fieldType: FormFieldType = "text";

	@Input()
	public control!: FormControl;

	/**
	* Required if fieldType = 'typeahead'
	*/
	@Input()
	public typeaheadListItems: any[] = [];

	/**
	* Required if fieldType = 'typeahead'
	*/
	@Input()
	public template?: TemplateRef<any>;

	@Input()
	public displayOnFocus: boolean = true;

	@Input()
	public showInvalid: ShowInvalid = ShowInvalid.dirty;

	@Input()
	public readonly: boolean = false;

	/**
	* Used to retrieve the text value of whatever object is set on the form control.
	* This could be an arbitrary object, so we need the parent component to provide
	* this method.
	* Required if fieldType = 'typeahead'
	*/
	@Input()
	public controlTextAccessor?: () => string|undefined;

	public get typeaheadSelectedText(): string|undefined {
		if (this.controlTextAccessor === undefined) {
			return undefined;
		}

		return this.controlTextAccessor();
	}

	public typeaheadValueSelected($event: any) {
		console.log($event);
		this.control.setValue($event)
	}

	public typeaheadTextChanged(event: Event, inputText: string) {
		if (this.controlTextAccessor === undefined) {
			return;
		}

		const selectedTypeaheadValueText = this.controlTextAccessor();
		if (selectedTypeaheadValueText !== inputText) {
			this.control.setValue(null);
		}
	}

	/**
	* List of available options for fieldType="select"
	*/
	@Input()
	public optionList: ListItem[] = [];

	@Input()
	public compareByFunc?: (a: any, b: any) => boolean;

	public readonly always = ShowInvalid.always;
	public readonly dirty = ShowInvalid.dirty;
}
