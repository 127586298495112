import { Injectable } from "@angular/core";
import { API, RestService } from "@services/rest.service";
import WaitQueue from "@classes/waitQueue";
import { DBVersion } from "@classes/dbVersion";
export { DBVersion } from "@classes/dbVersion";

/**
* Simple service to return db version for specified database.
* Used to determine if local indexedDB databases are out of date and need rebuilding.
*/
@Injectable({ "providedIn": "root" })
export class DBVersionService {

	private _waitQueue = new WaitQueue<any>();

	constructor(private restService: RestService) {}

	private async fetchDBVersion(dbName: string): Promise<number> {
		const response = await this.restService.get(API.system, `dbversion/${dbName}`);
		return Number(response.version);
	}

	private async fetchAllDBVersions(): Promise<DBVersion[]> {
		const response = await this.restService.get(API.system, `dbversion`);
		return response.map(DBVersion.parse);
	}

	public async getDBVersion(dbName: string): Promise<number> {
		return await this._waitQueue.enqueue(dbName, this.fetchDBVersion.bind(this, dbName));
	}

	public async getAllDBVersions(): Promise<DBVersion[]> {
		return await this._waitQueue.enqueue("all", this.fetchAllDBVersions.bind(this));
	}

	public async incrementVersion(dbName: string): Promise<DBVersion[]> {
		const response = await this.restService.patch(API.system, `dbversion/${dbName}`);
		return response.map(DBVersion.parse);
	}

	public async checkFrontendSoftwareVersion(): Promise<string> {
		const serverVersion = await this.restService.get(API.system, `dbversion/frontend`);
		return serverVersion.version;
	}
}
