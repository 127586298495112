import { Entity } from "@classes/entities";
import { EnumMetadata } from "@classes/enumUtils";
import { NamedEntity } from "@classes/entities";
import { Invoice } from "@classes/invoice";

export namespace Contracts {
}

export enum ContractProductStatus {
	enabled, disabled
}

export namespace ContractProductStatus {
	const enumData: EnumMetadata<ContractProductStatus>[] = [
		[ContractProductStatus.enabled, "Enabled", "enabled"],
		[ContractProductStatus.disabled, "Disabled", "disabled"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): ContractProductStatus|undefined {
		return values.get(value);
	}

	export function toString(value: ContractProductStatus|undefined): string|undefined {
		return value !== undefined ? descriptions.get(value) : undefined;
	}

	export function toPostgresEnum(value: ContractProductStatus|undefined): string|undefined {
		return value !== undefined ? pgEnums.get(value) : undefined;
	}

	export function allValues(): ContractProductStatus[] {
		return enumData.map( item => item[0] );
	}
}

export class ContractProduct extends Entity {
	public contract: NamedEntity;
	public product: NamedEntity;
	public lineItemDescription: string;
	public quantity: number|undefined;
	public unitPrice: number|undefined;
	public totalPrice: number|undefined;
	public status: ContractProductStatus|undefined;
	public invoices: Invoice[]|undefined;

	protected constructor(src: ContractProduct|any) {
		super(src);
		if (src instanceof ContractProduct) {
			this.contract = src.contract?.clone();
			this.product = src.product?.clone();
			this.status = src.status;
		}
		else {
			this.contract = NamedEntity.parse(src.contract);
			this.product = NamedEntity.parse(src.product);
			this.status = ContractProductStatus.parse(src.status);
		}
		this.lineItemDescription = src.lineItemDescription;
		this.quantity = src.quantity;
		this.unitPrice = src.unitPrice;
		this.totalPrice = src.totalPrice;
		this.invoices = (src.invoices || []).map(Invoice.parse)
	}

	public static override parse(src: any): ContractProduct {
		return new ContractProduct(src);
	}

	public override get json(): {[key: string]: any} {
		return Object.assign(super.json, {
			"contract": this.contract?.json,
			"product": this.product?.json,
			"lineItemDescription": this.lineItemDescription,
			"quantity": this.quantity,
			"unitPrice": this.unitPrice,
			"totalPrice": this.totalPrice,
			"status": ContractProductStatus.toPostgresEnum(this.status),
			"invoices": (this.invoices || []).map(Invoice.toJSON)
		});
	}

	public override clone(): ContractProduct {
		return new ContractProduct(this);
	}

	public static empty(): ContractProduct {
		return new ContractProduct({
			"contract": NamedEntity.empty().json,
			"product": NamedEntity.empty().json
		});
	}
}
