import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

/**
* Adatped from https://gist.github.com/darrenmothersele/7afda13d858a156ce571510dd78b7624
*/
@Directive({
	"selector": "[filedrop]"
})
export class FileDropDirective {

	@Output('onFilesDropped')
	filesDroppedEmitter = new EventEmitter<File[]>();

	@Input()
	preventBodyDrop = true;

	@Input()
	enabled = true;

	@HostBinding('class.file-drag-over')
	active = false;

	@HostListener('drop', ['$event'])
	onDrop(event: DragEvent) {

		event.preventDefault();
		this.active = false;

		if (!this.enabled) {
			event.stopPropagation();
			return;
		}

		const itemList = event.dataTransfer;

		if (!itemList) {
			return;
		}

		const isFile = (item: File | null): item is File => item !== null;

		if (itemList.items) {
			const files = Array.from(itemList.items).filter( item => item.kind === 'file' ).map( item => item.getAsFile() ).filter( isFile );
			itemList.items.clear();
			this.filesDroppedEmitter.emit(files);
		}
		else {
			const files = Array.from(itemList.files);
			itemList.clearData();
			this.filesDroppedEmitter.emit(files);
		}
	}

	@HostListener('dragover', ['$event'])
	onDragOver(event: DragEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.active = this.enabled;
	}

	@HostListener('dragleave', ['$event'])
	onDragLeave(event: DragEvent) {
		this.active = false;
	}

	/**
	* Disable default drop behaviour (open the file) if the user misses the dropzone element
	*/
	@HostListener('body:dragover', ['$event'])
	onBodyDragOver(event: DragEvent) {
		if (this.preventBodyDrop) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	/**
	* Disable default drop behaviour (open the file) if the user misses the dropzone element
	*/
	@HostListener('body:drop', ['$event'])
	onBodyDrop(event: DragEvent) {
		if (this.preventBodyDrop) {
			event.preventDefault();
		}
	}
}
