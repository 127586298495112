<fieldset>

	<legend *ngIf="legend !== null">{{ legend }}</legend>

	<ng-container *ngIf="!!copyFromAddress && !!copyFromLabel && !isEmpty(copyFromAddress)">
		<div class="field is-horizontal">
			<div class="field-label is-normal"></div>
			<div class="field-body">
				<div class="field">
					<a (click)="copyAddress()">Copy from {{ copyFromLabel }}</a>
				</div>
			</div>
		</div>
	</ng-container>

	<div class="field is-horizontal">
		<div class="field-label is-normal">
			<label for="c6144656_{{ instanceCount }}" class="label is-normal">Address 1</label>
		</div>
		<div class="field-body">
			<div class="field">
				<div class="control is-expanded">
					<input [disabled]="busy" [readonly]="busy" id="c6144656_{{ instanceCount }}" name="c6144656_{{ instanceCount }}" class="input is-normal" type="text" placeholder="Address 1" [(ngModel)]="address.address1" />
				</div>
			</div>
		</div>
	</div>

	<div class="field is-horizontal">
		<div class="field-label is-normal">
			<label for="deb3960d_{{ instanceCount }}" class="label is-normal">Address 2</label>
		</div>
		<div class="field-body">
			<div class="field">
				<div class="control is-expanded">
					<input [disabled]="busy" [readonly]="busy" id="deb3960d_{{ instanceCount }}" name="deb3960d_{{ instanceCount }}" class="input is-normal" type="text" placeholder="Address 2" [(ngModel)]="address.address2" />
				</div>
			</div>
		</div>
	</div>

	<div class="field is-horizontal">
		<div class="field-label is-normal">
			<label for="e7fbb8c8_{{ instanceCount }}" class="label is-normal">Suburb</label>
		</div>
		<div class="field-body">
			<div class="field">
				<div class="control is-expanded">
					<input [disabled]="busy" [readonly]="busy" id="e7fbb8c8_{{ instanceCount }}" name="e7fbb8c8_{{ instanceCount }}" class="input is-normal" type="text" placeholder="City / Suburb" [(ngModel)]="address.suburb" />
				</div>
			</div>
		</div>
	</div>

	<div class="field is-horizontal">
		<div class="field-label is-normal">
			<label for="fd653d98_{{ instanceCount }}" class="label is-normal">State</label>
		</div>
		<div class="field-body">
			<div class="field">
				<div class="control is-expanded">
					<div class="select is-fullwidth">
						<select [disabled]="busy" id="fd653d98_{{ instanceCount }}" name="fd653d98_{{ instanceCount }}" [(ngModel)]="address.state">
							<option [ngValue]="undefined" disabled>State</option>
							<option *ngFor="let state of auStates" [ngValue]="state">{{ state }}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="field is-horizontal">
		<div class="field-label is-normal">
			<label for="ec241ffe_{{ instanceCount }}" class="label is-normal">Postcode</label>
		</div>
		<div class="field-body">
			<div class="field">
				<div class="control is-expanded">
					<input [disabled]="busy" [readonly]="busy" id="ec241ffe_{{ instanceCount }}" name="ec241ffe_{{ instanceCount }}" class="input is-normal" type="text" placeholder="Postcode" [(ngModel)]="address.postcode" />
				</div>
			</div>
		</div>
	</div>

</fieldset>
