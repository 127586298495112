<nav class="navbar is-fixed-top">
	<div class="container">
		<div class="navbar-brand">
			<a class="navbar-item" [routerLink]="homepageLink">{{ menuTitle }}</a>
			<span [class.is-active]="menuActive" class="navbar-burger burger" data-target="navbarMenu" (click)="toggleMenu()">
				<span></span>
				<span></span>
				<span></span>
			</span>
		</div>

		<ng-container #menu></ng-container>

		<div id="navbarMenu" class="navbar-menu" *ngIf="!isLoggedIn">

			<div class="navbar-start">
			</div>

			<div class="navbar-end">
				<a *ngIf="!isLoggedIn" class="navbar-item" routerLink="login">Login</a>
			</div>

		</div>

	</div>
</nav>
