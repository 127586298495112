import { Pipe, PipeTransform } from '@angular/core';

@Pipe({"name": "truncate"})
export class TruncatePipe implements PipeTransform {

	transform(text: string = '', maxChars: number = 100, ellipsize: boolean = true ) : string {

		if (text.length <= maxChars) {
			return text;
		}

		return ellipsize ? text.substring(0, maxChars-1) + "…" : text.substring(0, maxChars);
	}
}
