<div id="gtd">
	<app-header></app-header>
	<maintenance-mode-widget *ngIf="isMaintenanceMode"></maintenance-mode-widget>
	<section class="section" [class.noscroll]="dialogVisible">
		<router-outlet></router-outlet>
		<notifications></notifications>
	</section>
	<modal></modal>
</div>
<footer id="footer" class="container has-text-right">
	&copy; Copyright {{ date | date : 'yyyy' }}.
</footer>
<version-check></version-check>
