<div class="container">
	<div class="box">
		<h1 class="title">TOTP Login</h1>
		<p>Please enter the one-time password displayed in your authenticator app.</p>
		<form class="has-text-left">

			<div class="field">
				<label for="b8f1ea98" class="label">One-Time Password</label>
				<div class="control" id="totpcontrol">
					<input #totp autocomplete="off" inputmode="numeric" name="totp" id="b8f1ea98" class="input" type="text" placeholder="123456" [(ngModel)]="totpCode" integer maxlength="6" />
				</div>
				<p class="has-text-danger help" *ngIf="errorMessageVisible">Incorrect TOTP provided, please try again.</p>
			</div>

			<div class="field">
				<div class="control">
					<button class="button is-link" (click)="confirmLogin()" [disabled]="busy">Log in</button>
				</div>
			</div>

		</form>
	</div>
</div>
