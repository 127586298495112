import { ContractStatus } from "@classes/contract"; // Add one of these for each type of "thing we are listing" that has a Status.
import { CustomerStatus } from "@classes/customer"; // Add one of these for each type of "thing we are listing" that has a Status.
import { DateUtils } from "@classes/utils";

export interface GenericListStatusCount {
	status: string;
	count: number;
}

export interface GenericListFilter {
	query: string | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	contractStatus: Set<ContractStatus> | undefined;
	contractStatuses: Set<GenericListStatusCount> | undefined; // Purely to display numbers on the relevant ...Status DDL
	customerStatus: Set<CustomerStatus> | undefined;
	customerStatuses: Set<GenericListStatusCount> | undefined; // Purely to display numbers on the relevant ...Status DDL
}

export namespace GenericListFilter {
	export function blank(): GenericListFilter {
		return {
			"query": undefined,
			"dateFrom": undefined,
			"dateTo": undefined,
			"contractStatus": undefined,
			"contractStatuses": undefined,
			"customerStatus": undefined,
			"customerStatuses": undefined
		};
	}

	export function isEmpty(model: GenericListFilter, ignoreDates: boolean = false, ignoreContractStatus: boolean = false, ignoreCustomerStatus: boolean = false): boolean {
		let tests = [
			model.query === undefined
		];

		if (!ignoreDates) {
			tests.push(model.dateFrom === undefined);
			tests.push(model.dateTo === undefined);
		}

		if (!ignoreContractStatus && model.contractStatus) {
			tests.push(model.contractStatus.size === 0);
		}

		if (!ignoreCustomerStatus && model.customerStatus) {
			tests.push(model.customerStatus.size === 0);
		}

		return tests.every( result => result === true );
	}

	export function toJSON(src: GenericListFilter): any {
		if (!src) {
			return {};
		}

		let result:any = {};
		if (src.query !== undefined) {
			result.query = src.query
		}

		if (src.dateFrom !== undefined) {
			result.dateFrom = DateUtils.toString(src.dateFrom);
		}

		if (src.dateTo !== undefined) {
			result.dateTo = DateUtils.toString(src.dateTo);
		}

		if (src.contractStatus && src.contractStatus.size > 0) {
			result["contractStatus"] = Array.from(src.contractStatus).map( ContractStatus.toPostgresEnum );
		}

		if (src.customerStatus && src.customerStatus.size > 0) {
			result["customerStatus"] = Array.from(src.customerStatus).map( CustomerStatus.toPostgresEnum );
		}

		return result;
	}
}
