import { Compiler, Injector, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { assert, ErrorUtils } from "@classes/errors";
import { TitleService } from '@services/title.service';

@Component({
	"selector": "app-header",
	"templateUrl": "./app-header.component.html",
	"styleUrls": ["./app-header.component.scss"]
})
export class AppHeaderComponent {

  @ViewChild('menu', { "read": ViewContainerRef, "static": true })
  private menuOutlet: ViewContainerRef | undefined;

	private _menuActive: boolean = false; // ⟵ Flag indicating state of the mobile menu

	private menuState: Subject<boolean> = new Subject<boolean>();
	private _menuTitle: string = "";

	private userLoaded(loaded: boolean|undefined) {
		this.menuOutlet?.clear();
		if (loaded) {
			this.loadMenu();
		}
	}

	constructor(
		private router: Router,
		private compiler: Compiler,
		private injector: Injector,
		private authService: AuthService) {

		// Close the mobile menu after a navigation event
		this.router.events.subscribe( e => {
			if (e instanceof NavigationStart) {
				this._menuActive = false;
				this.menuState.next(this._menuActive);
			}
		});

		// No need to unsubscribe from these next items as the component is persistent throughout the entire app lifecycle.
		this.authService.userLoaded$.subscribe( this.userLoaded.bind(this) );
		TitleService.menuTitle.subscribe(this.setTitle.bind(this));
	}

	private setTitle(value: string) {
		this._menuTitle = value;
	}

	public toggleMenu() {
		this._menuActive = !this._menuActive;
		this.menuState.next(this._menuActive);
	}

	public get menuActive(): boolean {
		return this._menuActive;
	}

	public get homepageLink(): string[] {
		return ["/", ...this.authService.usersHomePage.split("/")].filter( x => !!x );
	}

	public get isLoggedIn(): boolean {
		return this.authService.isLoggedIn;
	}

	private async loadMenu() {
		try {
			assert(this.menuOutlet);
			try {
				const m = await import("@modules/menu/menu.module");
				const factory = await this.compiler.compileModuleAsync(m.MainMenuModule);
				const moduleRef = factory.create(this.injector);
				const componentFactory = moduleRef.instance.resolveMenuComponent();
				this.menuOutlet.clear();
				const { instance } = this.menuOutlet.createComponent(componentFactory);
				instance.menuState = this.menuState;
			}
			catch (e) {
				ErrorUtils.logError(e, this.authService.currentUser);
			}
		}
		catch (e) {
			return;
		}
	}

	public get menuTitle(): string {
		return this._menuTitle;
	}
}
