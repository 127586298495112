import { NgControl } from "@angular/forms";
import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
	"selector": 'input[integer]'
})
export class IntegerInputDirective {
	constructor(private el: ElementRef, private control: NgControl) {}

	@HostListener('beforeinput', ['$event'])
	onEvent(event: Event) {
		if (event.type === 'beforeinput') {
			const e = <InputEvent>event;
			if (/\D/.test(e.data || "")) {
				e.preventDefault();
			}
		}
	}
}
