import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { filter, first } from "rxjs/operators";
import { Permission, Permissions } from "@classes/permissions";
import { User } from "@classes/user";
import { assertHasValue } from "@utils";

/**
* Angular route guard to prevent loading of modules unless the user is logged in, and an administrator or support coordinator.
* If the user is an administrator, they must also be granted any permissions that may be required for the route.
*
* Permissions are specified on the route's "data" property from the router module.
*/
@Injectable()
export class MustBeLoggedInToLoad implements CanLoad {

	constructor(private authService: AuthService, private router: Router) {}

	/**
	* Return an array of permissions required in order to activate the route.
	* Child classes can override this method to handle nuanced cases (eg permissions are dependant on route params)
	*/
	private getRoutePermissions(route: Route): Permission[] {
		return route.data?.loadPermissions ?? [];
	}

	private checkUserHasAllPermissions(user: User, requiredPermissions: Permission[]): boolean {

		// Return true if all of the requested permissions have been assigned to the current user
		try {
			const permissionIds = requiredPermissions.map( Permissions.getPermissionId )
			return permissionIds.every( permissionId => user.permissions.includes( permissionId ) );
		}
		catch (e) {
			return false;
		}
	}

	canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {

			// Subscribe to the "userLoaded" observable on the authService.
			// Filter out the initial value (undefined)
			// Use the "first()" operator to automatically unsubsribe once a value has been received.
			this.authService.userLoaded$.pipe(filter(x => x !== undefined), first()).subscribe( userLoaded => {

				// If you're not logged in, you can't load the module
				if (!userLoaded) {
					this.router.navigate(['/login']);
					return resolve(false);
				}

				const routePermissions = this.getRoutePermissions(route);

				// If no particular permissions are specified for this route, go ahead and activate
				if (routePermissions.length === 0) {
					return resolve(true);
				}

				// Otherwise, make sure the user has all of the permissions required
				try {
					assertHasValue(this.authService.currentUser);
					const result = this.checkUserHasAllPermissions(this.authService.currentUser, routePermissions);
					if (!result) {
						this.router.navigate(["/forbidden"]);
					}
					resolve(result);
				}
				catch (e) {
					resolve(false);
				}
			});

		});
	}
}
