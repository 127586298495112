import { Subject, Observable } from 'rxjs';
import { TemplateRef } from '@angular/core';
import { StringUtils } from "@utils";

export enum DialogMode {
	none, wait, error, success, dialog, template, panel
}

export interface ErrorMessage {
	title: string,
	message: string,
	buttonText: string
}

export interface DialogButton {
	text: string;
	handler: () => void;
}

export interface Dialog {
	title: string;
	content: string;
	buttons: DialogButton[];
}

export interface Template {
	template: TemplateRef<any>;
	context?: any;
	hideCloseButton?: boolean;
	readySignal?: Observable<boolean>;
}

export class DialogService {

	private static lastScrollPos: number = 0;

	private static defaultLoadingMessage: string = `Loading${StringUtils.ellipsis}`;
	private static defaultButtonText: string = "Close"
	private static _isVisble: boolean = false;

	private static loadingTextSource = new Subject<string>();
	private static errorMessageSource = new Subject<ErrorMessage>();
	private static dialogModeSource = new Subject<DialogMode>();
	private static templateModeSource = new Subject<Template>();
	private static dialogSource = new Subject<Dialog>();
	private static panelSource = new Subject<Template>();
	private static closeSignal = new Subject<void>();

	public static loadingText$ = DialogService.loadingTextSource.asObservable();
	public static errorMessageS = DialogService.errorMessageSource.asObservable();
	public static dialogMode$ = DialogService.dialogModeSource.asObservable();
	public static templateMode$ = DialogService.templateModeSource.asObservable();
	public static dialog$ = DialogService.dialogSource.asObservable();
	public static panel$ = DialogService.panelSource.asObservable();
	public static closeSignal$ = DialogService.closeSignal.asObservable();

	static readonly closeButton: DialogButton = {
		"text": "Close",
		"handler": DialogService.hide
	};

	static show(msg?: string): void {
		DialogService.lastScrollPos = Math.round(window.scrollY) ?? 0;
		setTimeout(this.setScrollOffset.bind(this));

		DialogService.loadingTextSource.next( msg || DialogService.defaultLoadingMessage );
		DialogService.dialogModeSource.next(DialogMode.wait);
		DialogService._isVisble = true;
	}

	static hide(): void {
		DialogService._isVisble = false;
		// DialogService.dialogModeSource.next(DialogMode.none);
		DialogService.closeSignal.next();
		setTimeout(() => {

			DialogService.setScrollOffset();
			window.scrollTo({"top": DialogService.lastScrollPos ?? 0 + 'px'});

			DialogService.lastScrollPos = 0;
		});
	}

	static showError(title: string, message: string, buttonText: string = DialogService.defaultButtonText): void {
		DialogService.lastScrollPos = Math.round(window.scrollY) ?? 0;
		setTimeout(this.setScrollOffset.bind(this));

		DialogService.errorMessageSource.next({
			"title": title,
			"message": message,
			"buttonText": buttonText
		});
		DialogService.dialogModeSource.next(DialogMode.error);
		DialogService._isVisble = true;
	}

	static showSuccess(title: string, message: string, buttonText: string = DialogService.defaultButtonText): void {
		DialogService.lastScrollPos = Math.round(window.scrollY) ?? 0;
		setTimeout(this.setScrollOffset.bind(this));

		DialogService.errorMessageSource.next({
			"title": title,
			"message": message,
			"buttonText": buttonText
		});
		DialogService.dialogModeSource.next(DialogMode.success);
		DialogService._isVisble = true;
	}

	static showDialog(title: string, content: string, buttons: DialogButton[]): void {
		DialogService.lastScrollPos = Math.round(window.scrollY) ?? 0;
		setTimeout(this.setScrollOffset.bind(this));

		DialogService.dialogSource.next({
			"title": title,
			"content": content,
			"buttons": buttons
		});
		DialogService.dialogModeSource.next(DialogMode.dialog);
		DialogService._isVisble = true;
	}

	private static setScrollOffset() {
		const value = `-${DialogService.lastScrollPos}px`;
		document.documentElement.style.setProperty('--modalScrollOffset', value);
	}

	static showTemplate(template: TemplateRef<any>, context?: any, hideCloseButton?: boolean, readySignal?: Observable<boolean>): void {
		DialogService.lastScrollPos = Math.round(window.scrollY) ?? 0;
		setTimeout(this.setScrollOffset.bind(this));

		DialogService.templateModeSource.next({
			"template": template,
			"context": context,
			"hideCloseButton": hideCloseButton,
			"readySignal": readySignal
		});
		DialogService.dialogModeSource.next(DialogMode.template);
		DialogService._isVisble = true;
	}

	static showPanel(template: TemplateRef<any>, context?: any, hideCloseButton?: boolean, readySignal?: Observable<boolean>): void {
		DialogService.lastScrollPos = Math.round(window.scrollY) ?? 0;
		setTimeout(this.setScrollOffset.bind(this));

		DialogService.panelSource.next({
			"template": template,
			"context": context,
			"hideCloseButton": hideCloseButton,
			"readySignal": readySignal
		});
		DialogService.dialogModeSource.next(DialogMode.panel);
		DialogService._isVisble = true;
	}

	static get visible(): boolean {
		return DialogService._isVisble;
	}
}

