<div class="container">
	<loading *ngIf="loading"></loading>
	<div class="box" *ngIf="!loading">
		<h1 class="title has-text-primary">Login</h1>
		<form class="has-text-left">

			<div class="field">
				<label for="a5d1456a" class="label">Username</label>
				<div class="control has-icons-left">
					<input [attr.autocomplete]="isProd ? 'off' : null" name="email" id="a5d1456a" class="input" type="email" placeholder="you@example.com" [(ngModel)]="model.email" #username />
					<span class="icon is-small is-left"><fa-icon [icon]="['fas', 'user']"></fa-icon></span>
				</div>
			</div>

			<label for="b5d1456a" class="label">Password</label>
			<div class="field has-addons" *ngIf="!showPassword">
				<div class="control has-icons-left is-expanded">
					<input name="pwdhidden" id="b5d1456a" class="input" type="password" placeholder="Top Secret" [(ngModel)]="model.password" />
					<span class="icon is-small is-left"><fa-icon [icon]="['fas', 'lock']"></fa-icon></span>
				</div>
				<div class="control">
					<a class="button" (click)="togglePasswordDisplay()"><fa-icon [icon]="['fad', 'eye']"></fa-icon></a>
				</div>
			</div>

			<div class="field has-addons" *ngIf="showPassword">
				<div class="control has-icons-left is-expanded">
					<input name="pwdplain" id="b5d1456a" class="input" type="text" placeholder="Top Secret" [(ngModel)]="model.password" autocomplete="off" />
					<span class="icon is-small is-left"><fa-icon [icon]="['fas', 'lock']"></fa-icon></span>
				</div>
				<div class="control">
					<a class="button" (click)="togglePasswordDisplay()"><fa-icon [icon]="['fad', 'eye-slash']"></fa-icon></a>
				</div>
			</div>

			<div class="field">
				<div class="control">
					<button class="button is-link" (click)="doLogin()" [disabled]="!canLogIn">Log in</button>
				</div>
			</div>

			<div>
				<a class="is-link" routerLink="/password/forgot">Forgot Password</a>
			</div>

		</form>
	</div>
</div>
