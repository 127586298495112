import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { filter, first } from "rxjs/operators";
import { Permission, Permissions } from "@classes/permissions";
import { assertHasValue } from "@utils";

/**
* Angular route guard to prevent activating a route if the site is in maintenance mode
*/
@Injectable()
export class MaintenanceModeCanActivate implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  private static readonly permission = Permission("Superuser", "Ignore maintenance mode");

	private get canBypassMaintenanceMode(): boolean {

		const permissionId = Permissions.getPermissionId(MaintenanceModeCanActivate.permission);
		// Return true if all of the requested permissions have been assigned to the current user
		try {
			const user = this.authService.currentUser;
			return (user?.permissions ?? []).includes(permissionId);
		}
		catch (e) {
			return false;
		}
	}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {

			// Subscribe to the "userLoaded" observable on the authService.
			// Filter out the initial value (undefined)
			// Use the "first()" operator to automatically unsubsribe once a value has been received.
			this.authService.userLoaded$.pipe(
				filter(x => x !== undefined),
				first()
			).subscribe( userLoaded => {

				if (!userLoaded) {
					return resolve(false);
				}

				try {
					assertHasValue(this.authService.currentUser);

					// If the site is in maintenance mode, only allow if the user has "Ignore maintenance mode" permission
					if (this.authService.maintenanceMode) {
						if (!this.canBypassMaintenanceMode) {
							this.router.navigate(["/maintenance"]);
							resolve(false);
						}
					}

					resolve(true);
				}
				catch (e) {
					resolve(false);
				}


				return resolve( !!userLoaded );
			});

		});
  }
}
