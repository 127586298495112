import { Component, Input } from '@angular/core';
import { Tab, TabManager } from "@classes/tabs";
import { User } from "@classes/user";
import { Permissions } from "@classes/permissions";
import { hasValue } from "@utils";
import { assert } from "@classes/errors";

@Component({
	"selector": "tabs[tabs]",
	"styleUrls": ["./tabs.component.scss"],
	"templateUrl": "./tabs.component.html"
})
export class TabsComponent {

	@Input('tabs')
	public tabManager!: TabManager;

	@Input()
	public user: User|undefined;

	@Input()
	public horizontal: boolean = false;

	public get currentTab(): Tab {
		return this.tabManager.currentTab;
	}

	public set currentTab(value: Tab) {
		this.tabManager.currentTab = value;
	}

	public get allTabs(): Tab[] {
		return this.tabManager.allTabs;
	}

	public displayTab(tab: Tab) {
		if (tab.permissions?.length) { // ⟵ Permissions required for this tab

			try {
				const permissionIds = tab.permissions.map( Permissions.getPermissionId );
				assert(hasValue(this.user));
				return permissionIds.every( permission => this.user?.hasPermission(permission) );
			}
			catch (e) {
				return false; // ⟵ If user is not set, cannot display this tab
			}
		}

		return true; // ⟵ If no permissions are required for the tab, always display
	}
}
