import { EnumMetadata } from "@classes/enumUtils";
import { DateUtils, assertHasValue, hasValue } from "@utils";
import { NamedEntity } from "@classes/entities";

export enum InvoiceStatus {
	draft, on_hold, paid
}

export namespace InvoiceStatus {
	const enumData: EnumMetadata<InvoiceStatus>[] = [
		[InvoiceStatus.draft, "Draft", "draft"],
		[InvoiceStatus.on_hold, "On Hold", "on_hold"],
		[InvoiceStatus.paid, "Paid", "paid"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): InvoiceStatus|undefined {
		return values.get(value);
	}

	export function toString(value: InvoiceStatus|undefined): string|undefined {
		return value !== undefined ? descriptions.get(value) : undefined;
	}

	export function toPostgresEnum(value: InvoiceStatus|undefined): string|undefined {
		return value !== undefined ? pgEnums.get(value) : undefined;
	}

	export function allValues(): InvoiceStatus[] {
		return enumData.map( item => item[0] );
	}
}


export interface InvoiceLineItem {
	id: string;
	description: string;
	quantity: number;
	total: number;
	product: NamedEntity|undefined;
}

export namespace InvoiceLineItem {
	export function parse(src: any): InvoiceLineItem {
		assertHasValue(src);
		
		return {
			"id": src.id,
			"description": src.description,
			"quantity": src.quantity,
			"total": src.total,
			"product": src.product ? NamedEntity.parse(src.product) : undefined
		};
	}

	export function empty(): InvoiceLineItem {
		return {} as InvoiceLineItem;
	}

	export function toJSON(src: InvoiceLineItem): any {
		return {
			"id": src.id,
			"description": src.description,
			"quantity": src.quantity,
			"total": src.total,
			"product": src.product ? src.product.json : undefined
		};
	}

	export function clone(src: InvoiceLineItem): InvoiceLineItem {
		//if (!hasValue(src)) {
		//	return undefined;
		//}

		return InvoiceLineItem.parse(InvoiceLineItem.toJSON(src));
	}
}


export interface Invoice {
	id?: string;
	invoiceNumber: string;
	invoiceDate: Date|undefined;
	clientReference: string;
	exGstTotal: number;
	gst: number;
	total: number;
	contract: NamedEntity|undefined;
	contractProductId: string|undefined;
	product: NamedEntity|undefined;
	customer: NamedEntity|undefined;
	surgery: NamedEntity|undefined;
	status: InvoiceStatus|undefined;
	invoiceLineItems: InvoiceLineItem[];
}

export namespace Invoice {
	export function parse(src: any): Invoice {
		assertHasValue(src);

		return {
			"id": src.id,
			"invoiceNumber": src.invoiceNumber,
			"invoiceDate": DateUtils.parse(src.invoiceDate, false),
			"clientReference": src.clientReference,
			"exGstTotal": src.exGstTotal,
			"gst": src.gst,
			"total": src.total,
			"contract": src.contract ? NamedEntity.parse(src.contract) : undefined,
			"contractProductId": src.contractProductId,
			"product": src.product ? NamedEntity.parse(src.product) : undefined,
			"customer": src.customer ? NamedEntity.parse(src.customer) : undefined,
			"surgery": src.surgery ? NamedEntity.parse(src.surgery) : undefined,
			"status": InvoiceStatus.parse(src.status),
			"invoiceLineItems": (src.invoiceLineItems || []).map(InvoiceLineItem.parse)
		};
	}

	export function empty(): Invoice {
		return {} as Invoice;
	}

	export function toJSON(src: Invoice): any {
		return {
			"id": src.id,
			"invoiceNumber": src.invoiceNumber,
			"invoiceDate": DateUtils.toString(src.invoiceDate),
			"clientReference": src.clientReference,
			"exGstTotal": src.exGstTotal,
			"gst": src.gst,
			"total": src.total,
			"contract": src.contract ? src.contract.json : undefined,
			"contractProductId": src.contractProductId,
			"product": src.product ? src.product.json : undefined,
			"customer": src.customer ? src.customer.json : undefined,
			"surgery": src.surgery ? src.surgery.json : undefined,
			"status": InvoiceStatus.toPostgresEnum(src.status),
			"invoiceLineItems": (src.invoiceLineItems || []).map(InvoiceLineItem.toJSON)
		};
	}

	export function clone(src: Invoice): Invoice {
		
		return {
			"id": src.id,
			"invoiceNumber": src.invoiceNumber,
			"invoiceDate": src.invoiceDate,
			"clientReference": src.clientReference,
			"exGstTotal": src.exGstTotal,
			"gst": src.gst,
			"total": src.total,
			"contract": src.contract ? src.contract?.clone() : undefined,
			"contractProductId": src.contractProductId,
			"product": src.product ? src.product?.clone() : undefined,
			"customer": src.customer ? src.customer?.clone() : undefined,
			"surgery": src.surgery ? src.surgery?.clone() : undefined,
			"status": src.status,
			"invoiceLineItems": (src.invoiceLineItems).map(InvoiceLineItem.clone)
		};
	}
}
