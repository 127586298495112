import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import {
	faAngleDown,
	faArrowLeft,
	faArrowRight,
	faArrowUp,
	faAsterisk,
	faBan,
	faBug,
	faCaretDown,
	faCaretRight,
	faCertificate,
	faChevronLeft,
	faCircleNotch,
	faCheck,
	faCheckCircle,
	faCloudDownloadAlt,
	faCogs,
	faComment,
	faEnvelope,
	faExclamation,
	faExclamationTriangle,
	faGripVertical,
	faHourglassHalf,
	faList,
	faLock,
	faPen,
	faPlus,
	faQuestion,
	faSearch,
	faSort,
	faSortDown,
	faSortUp,
	faSortAlphaDown,
	faSortNumericDown,
	faThLarge,
	faTimes,
	faTimesCircle,
	faTools,
	faTrashAlt,
	faUndo,
	faUser
} from "@fortawesome/free-solid-svg-icons";

import {
	faClipboard,
	faClipboardCheck,
	faClipboardListCheck,
	faCopy,
	faDigging,
	faEye,
	faEyeSlash,
	faFileCheck,
	faFileDownload,
	faFilePdf,
	faFileSpreadsheet,
	faLifeRing,
	faMinusSquare,
	faPennant,
	faPlusSquare,
	faSave,
	faSpinnerThird,
	faToggleOff,
	faToggleOn,
} from "@fortawesome/pro-duotone-svg-icons"

export function initIconLibrary(library: FaIconLibrary): void {
	const icons = [
		faAngleDown,
		faArrowLeft,
		faArrowRight,
		faArrowUp,
		faAsterisk,
		faBan,
		faBug,
		faCaretDown,
		faCertificate,
		faChevronLeft,
		faCaretRight,
		faCircleNotch,
		faCheck,
		faCheckCircle,
		faCloudDownloadAlt,
		faCogs,
		faComment,
		faEnvelope,
		faExclamation,
		faExclamationTriangle,
		faGripVertical,
		faHourglassHalf,
		faPen,
		faPlus,
		faQuestion,
		faList,
		faLock,
		faSearch,
		faSort,
		faSortAlphaDown,
		faSortNumericDown,
		faSortDown,
		faSortUp,
		faThLarge,
		faTimes,
		faTimesCircle,
		faTools,
		faTrashAlt,
		faUndo,
		faUser
	];

	const duotoneIcons = [
		faClipboard,
		faClipboardCheck,
		faClipboardListCheck,
		faCopy,
		faDigging,
		faEye,
		faEyeSlash,
		faFileCheck,
		faFileDownload,
		faFilePdf,
		faFileSpreadsheet,
		faLifeRing,
		faMinusSquare,
		faPennant,
		faPlusSquare,
		faSave,
		faSpinnerThird,
		faToggleOff,
		faToggleOn
];

	library.addIcons(...icons);
	library.addIcons(...duotoneIcons);
};

